import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const SaveIcon: FC<Props> = ({className, width = '28', height = '28', color = '#292D32'}) => {
  return (
    <svg
      fill={color}
      height={height}
      width={width}
      version='1.1'
      className={className}
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 502 502'
    >
      <g>
        <g>
          <g>
            <path
              d='M492,0H10C4.477,0,0,4.477,0,10v424c0,2.652,1.054,5.196,2.929,7.071l58,58C62.804,500.946,65.348,502,68,502h424
				c5.523,0,10-4.477,10-10V10C502,4.477,497.523,0,492,0z M86,20h330v240H86V20z M194.045,482H158.06v-67.589h35.985V482z
				 M313.239,482h-99.194v-77.589c0-5.523-4.477-10-10-10H148.06c-5.523,0-10,4.477-10,10V482h-17.925V381h193.104V482z
				 M381.866,482h-48.627V381h48.627V482z M482,482h-80.134V371c0-5.523-4.477-10-10-10h-68.627H110.134c-5.523,0-10,4.477-10,10
				v111H72.142L20,429.858V20h46v250c0,5.523,4.477,10,10,10h350c5.523,0,10-4.477,10-10V20h46V482z'
            />
            <path d='M367.5,62H345c-5.523,0-10,4.477-10,10s4.477,10,10,10h22.5c5.523,0,10-4.477,10-10S373.023,62,367.5,62z' />
            <path d='M134.5,82H299c5.523,0,10-4.477,10-10s-4.477-10-10-10H134.5c-5.523,0-10,4.477-10,10S128.977,82,134.5,82z' />
            <path d='M367.5,129h-233c-5.523,0-10,4.477-10,10s4.477,10,10,10h233c5.523,0,10-4.477,10-10S373.023,129,367.5,129z' />
            <path d='M367.5,196h-233c-5.523,0-10,4.477-10,10s4.477,10,10,10h233c5.523,0,10-4.477,10-10S373.023,196,367.5,196z' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SaveIcon
