import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const ThumbsUpIcon: FC<Props> = ({className, width = '32', height = '32', color = '#333333'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        d='M12.5 23.4375C18.5406 23.4375 23.4375 18.5406 23.4375 12.5C23.4375 6.45939 18.5406 1.5625 12.5 1.5625C6.45939 1.5625 1.5625 6.45939 1.5625 12.5C1.5625 18.5406 6.45939 23.4375 12.5 23.4375Z'
        fill='#1485BD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.6406 11.3281H17.7813C17.7031 11.3281 17.5469 11.0312 17.1563 10.6094C16.5781 9.98438 15.9375 9.1875 15.4844 8.75C14.3601 7.77424 13.4412 6.58446 12.7813 5.25C12.4063 4.45313 12.375 4.09375 11.6094 4.09375C11.307 4.13279 11.0307 4.28519 10.8364 4.52013C10.6421 4.75507 10.5442 5.05503 10.5625 5.35938C10.5625 5.57813 10.6719 6.34375 10.7188 6.60938C11.0534 7.83103 11.5809 8.99145 12.2813 10.0469H13.125H6.43752C6.24811 10.0441 6.0602 10.0809 5.88586 10.155C5.71151 10.2291 5.55457 10.3388 5.42511 10.4771C5.29564 10.6154 5.19651 10.7792 5.13407 10.958C5.07163 11.1369 5.04726 11.3268 5.06252 11.5156C5.08661 11.8817 5.25126 12.2243 5.52207 12.4718C5.79288 12.7193 6.14888 12.8526 6.51565 12.8438H6.76565C6.60558 12.8478 6.44789 12.8834 6.30157 12.9484C6.15525 13.0134 6.02318 13.1066 5.9129 13.2227C5.80262 13.3388 5.71629 13.4755 5.65885 13.6249C5.60142 13.7744 5.57399 13.9337 5.57815 14.0938C5.58086 14.4086 5.70083 14.7111 5.91463 14.9423C6.12844 15.1734 6.42072 15.3166 6.7344 15.3438C6.54346 15.4898 6.3987 15.6879 6.31746 15.9141C6.23622 16.1404 6.22196 16.3853 6.27638 16.6195C6.3308 16.8537 6.45159 17.0672 6.62428 17.2344C6.79697 17.4017 7.01423 17.5156 7.25002 17.5625C7.10031 17.8224 7.05032 18.1278 7.1094 18.4219C7.18941 18.7105 7.36355 18.9641 7.60418 19.1425C7.8448 19.3208 8.13811 19.4137 8.43752 19.4062H12.0156C12.4844 19.4062 12.9516 19.3469 13.4063 19.2344L15.625 18.5781H18.5938C20.1875 18.5156 20.6094 11.3281 18.6406 11.3281Z'
        fill='#E6F7FF'
      />
      <path
        d='M11.6095 10.047H6.42203C6.22455 10.0452 6.02898 10.0859 5.84864 10.1664C5.6683 10.2469 5.50742 10.3653 5.37695 10.5136C5.24648 10.6618 5.14948 10.8365 5.09255 11.0256C5.03562 11.2147 5.02009 11.4138 5.04703 11.6095C5.07495 11.9728 5.24127 12.3115 5.51166 12.5557C5.78206 12.8 6.1359 12.9311 6.50016 12.922H6.75016C6.59009 12.9261 6.43239 12.9616 6.28608 13.0266C6.13976 13.0916 6.00768 13.1849 5.8974 13.3009C5.78712 13.417 5.7008 13.5537 5.64336 13.7032C5.58592 13.8526 5.5585 14.0119 5.56266 14.172C5.56841 14.4875 5.689 14.7901 5.90185 15.0231C6.1147 15.2561 6.40519 15.4035 6.71891 15.4376C6.56949 15.5548 6.44836 15.7042 6.36451 15.8746C6.28067 16.045 6.23624 16.2321 6.23453 16.422C6.23531 16.7151 6.33656 16.9991 6.52138 17.2265C6.7062 17.454 6.96343 17.6112 7.25016 17.672C7.09936 17.9372 7.04942 18.2479 7.10953 18.547C7.19244 18.8327 7.36782 19.0828 7.60817 19.2582C7.84853 19.4335 8.14027 19.5241 8.43766 19.5157H12.0158C12.4845 19.5157 12.9517 19.4564 13.4064 19.3439L15.6252 18.6095H18.5939C20.1564 18.6095 20.5783 11.3595 18.5939 11.3595C18.3077 11.3753 18.0208 11.3753 17.7345 11.3595C17.6564 11.3595 17.5002 11.0626 17.1095 10.6407C16.5314 10.0157 15.8908 9.21887 15.4377 8.78137C14.3285 7.79661 13.4257 6.60169 12.7814 5.26575C12.6573 4.97092 12.4887 4.69689 12.2814 4.45325C12.0212 4.2344 11.6854 4.12651 11.3464 4.15281C11.0074 4.17912 10.6923 4.33751 10.4689 4.59387C10.307 4.769 10.175 4.9696 10.0783 5.18762C10.0151 5.3385 9.96793 5.49562 9.93766 5.65637C9.89573 6.0969 9.93277 6.54137 10.047 6.96887C10.147 7.41887 10.2892 7.85794 10.4689 8.28137C10.7627 8.83294 11.1095 9.35637 11.5002 9.84387C11.5484 9.87358 11.5865 9.91715 11.6095 9.96887'
        stroke='#004B7C'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default ThumbsUpIcon
