import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const SendIcon: FC<Props> = ({className, width = '32', height = '32', color = '#333333'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M22.0768 3.96387C21.8654 3.59236 21.4676 3.37744 21.0497 3.37744L2.95778 3.39059C2.45393 3.39063 2.01711 3.7018 1.84249 4.16546L1.84232 4.1659C1.71661 4.50114 1.7448 4.86685 1.91741 5.16891L1.91982 5.17313L1.92235 5.17728C1.98731 5.28358 2.0698 5.38371 2.16971 5.47187L9.05615 11.4104L10.7346 20.3263L10.7348 20.3269C10.8265 20.8209 11.2089 21.1888 11.7046 21.2673C12.1952 21.3458 12.6782 21.1147 12.9303 20.6885L12.9307 20.6879L22.0705 5.15101L21.8675 4.79834C21.8676 4.7982 21.8676 4.79806 21.8677 4.79792L22.0707 5.15061C22.2884 4.78141 22.2897 4.33027 22.0768 3.96387ZM21.2537 3.7317L21.0497 3.37744L21.2536 3.73167C21.2537 3.73168 21.2537 3.73169 21.2537 3.7317ZM10.091 9.68036L5.07797 5.35298H17.6742L10.091 9.68036ZM12.2907 17.8764L11.0688 11.3841L18.6668 7.0509L12.2907 17.8764Z'
        fill={color}
      />
    </svg>
  )
}

export default SendIcon
