import type {FC} from 'react'
import ExportIcon from '../../icons/workflow/ExportIcon'
import classes from './ExportCSVBtn.module.scss'

interface ExportCSVBtnProps {
  myRef: React.RefObject<HTMLDivElement>
}

const ExportCSVBtn: FC<ExportCSVBtnProps> = ({myRef}) => {
  const createCSV = () => {
    const theadRow = myRef?.current?.querySelector('tr')?.querySelectorAll('th')
    const theadCells = () => {
      if (theadRow)
        return (
          [].slice
            .call(theadRow)
            .map((cell: HTMLTableCellElement, index) => {
              if (index > 0) return `"${cell.textContent}"`
            })
            .join(',') + '\n'
        )
      return ''
    }
    const thead = theadCells()
    let rows = myRef?.current?.querySelectorAll('tr:not(:has(th))')

    const result = [].slice
      .call(rows)
      .map(function (row: HTMLTableRowElement) {
        const cells = row.querySelectorAll('td')
        return [].slice
          .call(cells)
          .splice(1, cells.length)
          .map((cell: HTMLTableCellElement) => {
            const textArea = cell.querySelector('textarea')
            const select = cell.querySelector('select')
            const div = cell.querySelector('[class*=text]')
            // const button = cell.querySelector('[class*=generateButton]')

            return `"${
              textArea?.value ? textArea.value.replace(/"/g, "'").replace(/\n/g, '') : ''
            }${select?.value ? select.value.replace(/"/g, "'").replace(/\n/g, '') : ''}${
              div?.textContent ? div.textContent.replace(/"/g, "'").replace(/\n/g, '') : ''
            }"`
          })
          .join(',')
      })
      .join('\n')

    const table = thead.concat(result)
    return table.split('').splice(1, table.length).join('')
  }

  const exportCSV = () => {
    const table = createCSV()
    const link = document.createElement('a')
    link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(table)}`)
    link.setAttribute('download', `bulk_data_${Date.now()}`)
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <button className={classes.export__btn} onClick={() => exportCSV()}>
      <ExportIcon /> Export CSV
    </button>
  )
}

export default ExportCSVBtn
