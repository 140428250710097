import {useState, type FC} from 'react'
import {ImageModel} from '../models/ImageModel'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import React from 'react'

interface ImageCardProps {
  image: ImageModel
  handleOnclickImage: Function
}

const ImageCard: FC<ImageCardProps> = ({image, handleOnclickImage}) => {
  return (
    <div>
       <img src={image.thumbnail_url} className='w-100 rounded-4' style={{cursor: 'pointer'}} alt='Generated' onClick={() => handleOnclickImage(image)} loading='lazy' />
    </div>
  )
}

export default React.memo(ImageCard);