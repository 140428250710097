import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const SalesIcon: FC<Props> = ({className, width = '24', height = '24', color = '#282828'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      className={className}
    >
      <path
        d='M22.6667 28.3333H9.33335C8.78669 28.3333 8.33335 27.88 8.33335 27.3333C8.33335 26.7867 8.78669 26.3333 9.33335 26.3333H22.6667C26.48 26.3333 28.3334 24.48 28.3334 20.6667V11.3333C28.3334 7.52 26.48 5.66667 22.6667 5.66667H9.33335C5.52002 5.66667 3.66669 7.52 3.66669 11.3333C3.66669 11.88 3.21335 12.3333 2.66669 12.3333C2.12002 12.3333 1.66669 11.88 1.66669 11.3333C1.66669 6.46667 4.46669 3.66667 9.33335 3.66667H22.6667C27.5334 3.66667 30.3334 6.46667 30.3334 11.3333V20.6667C30.3334 25.5333 27.5334 28.3333 22.6667 28.3333Z'
        fill={color}
      />
      <path
        d='M15.9997 17.16C14.8797 17.16 13.7464 16.8133 12.8797 16.1066L8.70635 12.7733C8.27968 12.4266 8.19969 11.8 8.54636 11.3733C8.89302 10.9466 9.51967 10.8666 9.94634 11.2133L14.1197 14.5466C15.133 15.36 16.853 15.36 17.8663 14.5466L22.0397 11.2133C22.4663 10.8666 23.1063 10.9333 23.4397 11.3733C23.7863 11.8 23.7197 12.44 23.2797 12.7733L19.1063 16.1066C18.253 16.8133 17.1197 17.16 15.9997 17.16Z'
        fill={color}
      />
      <path
        d='M10.6667 23H2.66669C2.12002 23 1.66669 22.5467 1.66669 22C1.66669 21.4533 2.12002 21 2.66669 21H10.6667C11.2134 21 11.6667 21.4533 11.6667 22C11.6667 22.5467 11.2134 23 10.6667 23Z'
        fill={color}
      />
      <path
        d='M6.66669 17.6667H2.66669C2.12002 17.6667 1.66669 17.2133 1.66669 16.6667C1.66669 16.12 2.12002 15.6667 2.66669 15.6667H6.66669C7.21335 15.6667 7.66669 16.12 7.66669 16.6667C7.66669 17.2133 7.21335 17.6667 6.66669 17.6667Z'
        fill={color}
      />
    </svg>
  )
}

export default SalesIcon
