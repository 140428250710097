import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const SendIcon: FC<Props> = ({className, width = '32', height = '32', color = '#707070'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 26 26'
      fill='none'
    >
      <path
        d='M21.3537 3.64379L2.42983 8.98131C2.27063 9.02621 2.12892 9.11864 2.02365 9.24623C1.91839 9.37382 1.85457 9.53051 1.84074 9.69534C1.8269 9.86018 1.86371 10.0253 1.94624 10.1687C2.02876 10.312 2.15308 10.4268 2.30257 10.4976L10.9974 14.6162C11.167 14.6965 11.3036 14.8331 11.3839 15.0027L15.5025 23.6975C15.5733 23.847 15.6881 23.9713 15.8314 24.0538C15.9748 24.1364 16.1399 24.1732 16.3047 24.1593C16.4696 24.1455 16.6263 24.0817 16.7539 23.9764C16.8814 23.8712 16.9739 23.7295 17.0188 23.5703L22.3563 4.64634C22.3955 4.50734 22.3969 4.36039 22.3605 4.22064C22.324 4.08089 22.251 3.95338 22.1488 3.85126C22.0467 3.74913 21.9192 3.67607 21.7794 3.6396C21.6397 3.60314 21.4927 3.60458 21.3537 3.64379Z'
        stroke={color}
        strokeWidth='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.2593 14.7407L15.8555 10.1445'
        stroke={color}
        strokeWidth='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default SendIcon
