import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {refreshBaseQuery} from './refreshBaseQuery'
const API_URL = process.env.REACT_APP_API_URL

export const gptServiceApi = createApi({
  reducerPath: 'gptServiceApi',
  baseQuery: refreshBaseQuery,
  endpoints: (builder) => ({
    checkLimits: builder.query({
      query: (token) => ({
        url: 'gpt/gpt-limits',
        headers: {Authorization: `Bearer ${token}`},
      }),
    }),
    updateUsage: builder.mutation({
      query: (usage) => ({
        url: 'gpt/gpt-usage',
        method: 'PUT',
        body: {usage: usage.replace(/[^0-9]/g, '')},
      }),
    }),
    checkGenerateLimit: builder.mutation({
      query: (data) => ({
        url: 'gpt/checkGenerateLimit',
        method: 'POST',
        body: {limits: {words: data.limits.words, credits: data.limits?.credits}, rows: data.rows},
        headers: {Authorization: `Bearer ${data.token}`},
      }),
    }),
  }),
})

export const {useUpdateUsageMutation, useCheckLimitsQuery, useCheckGenerateLimitMutation} =
  gptServiceApi
