import {createApi} from '@reduxjs/toolkit/query/react'
import {refreshBaseQuery} from './refreshBaseQuery'

export const companyServiceApi = createApi({
  reducerPath: 'companyServiceApi',
  baseQuery: refreshBaseQuery,
  tagTypes: ['Company'],
  endpoints: (builder) => ({
    getCompany: builder.mutation({
      query: (data) => ({
        url: `/company/limits`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Company'],
    }),
    getPanInfo: builder.query({
      query: (id) => ({
        url: `/company/plan/?user_id=${id}`,
      }),
      providesTags: ['Company'],
    }),
    getCompanyById: builder.query({
      query: ({id, page}) => ({
        url: `/company/${id}?page=${page}`,
      }),
      providesTags: ['Company'],
    }),
    updateCompanyAdmin: builder.mutation({
      query: ({id, admin_id}) => ({
        url: `/company/admin`,
        method: 'PUT',
        body: {id, admin_id},
      }),
      invalidatesTags: ['Company'],
    }),
    changeUserStatus: builder.mutation({
      query: ({id, status}) => ({
        url: `/company/user/${id}`,
        method: 'PATCH',
        body: {status},
      }),
      invalidatesTags: ['Company'],
    }),
    allocateUserLimit: builder.mutation({
      query: (data) => ({
        url: `/company/user/limit`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Company'],
    }),
  }),
})

export const {
  useGetCompanyMutation,
  useGetPanInfoQuery,
  useGetCompanyByIdQuery,
  useUpdateCompanyAdminMutation,
  useChangeUserStatusMutation,
  useAllocateUserLimitMutation,
} = companyServiceApi
