import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const DollarSquare: FC<Props> = ({className, width = '32', height = '32', color = '#333333'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      className={className}
    >
      <path
        d='M17.8669 23.2268H14.5202C12.3335 23.2268 10.5602 21.3868 10.5602 19.1201C10.5602 18.5734 11.0135 18.1201 11.5602 18.1201C12.1069 18.1201 12.5602 18.5734 12.5602 19.1201C12.5602 20.2801 13.4402 21.2268 14.5202 21.2268H17.8669C18.7335 21.2268 19.4535 20.4534 19.4535 19.5201C19.4535 18.3601 19.0402 18.1334 18.3602 17.8934L12.9869 16.0001C11.9469 15.6401 10.5469 14.8668 10.5469 12.4801C10.5469 10.4268 12.1602 8.77344 14.1335 8.77344H17.4802C19.6669 8.77344 21.4402 10.6134 21.4402 12.8801C21.4402 13.4268 20.9869 13.8801 20.4402 13.8801C19.8935 13.8801 19.4402 13.4268 19.4402 12.8801C19.4402 11.7201 18.5602 10.7734 17.4802 10.7734H14.1335C13.2669 10.7734 12.5469 11.5468 12.5469 12.4801C12.5469 13.6401 12.9602 13.8668 13.6402 14.1068L19.0135 16.0001C20.0535 16.3601 21.4535 17.1334 21.4535 19.5201C21.4402 21.5601 19.8402 23.2268 17.8669 23.2268Z'
        fill='#282828'
      />
      <path
        d='M16 25C15.4533 25 15 24.5467 15 24V8C15 7.45333 15.4533 7 16 7C16.5467 7 17 7.45333 17 8V24C17 24.5467 16.5467 25 16 25Z'
        fill='#282828'
      />
      <path
        d='M19.9998 30.3327H11.9998C4.75984 30.3327 1.6665 27.2393 1.6665 19.9993V11.9993C1.6665 4.75935 4.75984 1.66602 11.9998 1.66602H19.9998C27.2398 1.66602 30.3332 4.75935 30.3332 11.9993V19.9993C30.3332 27.2393 27.2398 30.3327 19.9998 30.3327ZM11.9998 3.66602C5.85317 3.66602 3.6665 5.85268 3.6665 11.9993V19.9993C3.6665 26.146 5.85317 28.3327 11.9998 28.3327H19.9998C26.1465 28.3327 28.3332 26.146 28.3332 19.9993V11.9993C28.3332 5.85268 26.1465 3.66602 19.9998 3.66602H11.9998Z'
        fill='#282828'
      />
    </svg>
  )
}

export default DollarSquare
