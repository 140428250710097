import type {FC} from 'react'
import {useParams} from 'react-router-dom'
import CombinedGeneration from '../../universal-components/combined-generation/CombinedGeneration'
import realEstateTemplateData from '../../../data/real-estate/realEastateTemplateData'

interface RealEstateTemplatePageProps {}

const RealEstateTemplatePage: FC<RealEstateTemplatePageProps> = () => {
  const {templateUrl} = useParams()
  const template: any = realEstateTemplateData.templates.find((item: any) =>
    item.url.includes(templateUrl)
  )

  return <CombinedGeneration templateItem={template} />
}

export default RealEstateTemplatePage
