import type {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const MarketingIcon: FC<Props> = ({className, width = '24', height = '24', color = '#616161'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      className={className}
    >
      <path
        d='M12.55 20.59C11.76 20.59 10.89 20.31 10.02 19.76L7.1 17.93C6.9 17.81 6.67 17.74 6.44 17.74H5C2.58 17.74 1.25 16.41 1.25 13.99V9.99C1.25 7.57 2.58 6.24 5 6.24H6.43C6.66 6.24 6.89 6.17 7.09 6.05L10.01 4.22C11.47 3.31 12.89 3.14 14.01 3.76C15.13 4.38 15.74 5.67 15.74 7.4V16.57C15.74 18.29 15.12 19.59 14.01 20.21C13.57 20.47 13.07 20.59 12.55 20.59ZM5 7.75C3.42 7.75 2.75 8.42 2.75 10V14C2.75 15.58 3.42 16.25 5 16.25H6.43C6.95 16.25 7.45 16.39 7.89 16.67L10.81 18.5C11.77 19.1 12.68 19.26 13.29 18.92C13.9 18.58 14.25 17.73 14.25 16.6V7.41C14.25 6.27 13.9 5.42 13.29 5.09C12.68 4.75 11.77 4.9 10.81 5.51L7.88 7.33C7.45 7.61 6.94 7.75 6.43 7.75H5Z'
        fill={color}
      />
      <path
        d='M18 16.75C17.84 16.75 17.69 16.7 17.55 16.6C17.22 16.35 17.15 15.88 17.4 15.55C18.97 13.46 18.97 10.54 17.4 8.45C17.15 8.12 17.22 7.65 17.55 7.4C17.88 7.15 18.35 7.22 18.6 7.55C20.56 10.17 20.56 13.83 18.6 16.45C18.45 16.65 18.23 16.75 18 16.75Z'
        fill={color}
      />
      <path
        d='M19.83 19.25C19.67 19.25 19.52 19.2 19.38 19.1C19.05 18.85 18.98 18.38 19.23 18.05C21.9 14.49 21.9 9.51 19.23 5.95C18.98 5.62 19.05 5.15 19.38 4.9C19.71 4.65 20.18 4.72 20.43 5.05C23.5 9.14 23.5 14.86 20.43 18.95C20.29 19.15 20.06 19.25 19.83 19.25Z'
        fill={color}
      />
    </svg>
  )
}

export default MarketingIcon
