import {Link} from 'react-router-dom'
import classes from './LimitNotification.module.scss'
import {FC, useEffect, useState} from 'react'

type LimitNotificationType = {
  errorMessage: string
}

const LimitNotification: FC<LimitNotificationType> = ({errorMessage = ''}) => {
  const [error, setError] = useState(errorMessage)

  const handleClose = () => {
    setError('')
  }
  return (
    <>
      {error.trim() !== '' ? (
        <div className={classes.limit_notification} id='limit-notification'>
          {errorMessage && (
            <>
              {errorMessage !== 'Oops!..There was an error. Try again or contact support.' &&
              errorMessage !==
                'Your allowed limit has been exceeded for this month. Please upgrade your plan.' ? (
                <>{errorMessage}. Please contact customer support!</>
              ) : (
                <>{errorMessage}</>
              )}
              <span onClick={handleClose} className={classes.limit_notification__close}>
                X
              </span>
            </>
          )}
          {!errorMessage && (
            <>
              Your usage has been used up. Upgrade{' '}
              <span className={classes.limit_notification__link}>
                <Link to={'/upgrade-plan'}>here</Link>
              </span>{' '}
              to continue usage.
            </>
          )}

          <span onClick={handleClose} className={classes.limit_notification__close}>
            X
          </span>
        </div>
      ) : null}
    </>
  )
}
export {LimitNotification}
