import {useState, type FC, useRef, useEffect} from 'react'
import imageFormData from '../../data/image-generation/imageFormData'
import {IWorkflowFormInputs} from '../../../types/types'
import classes from './ImagesForm.module.scss'
import SelectArrowIcon from '../icons/modalform/SelectArrowIcon'
import useDalleRequest from '../../hooks/useDalleRequest'
import {
  useCreateActivityMutation,
  useLazyGetActivityQuery,
} from '../../../services/activityServiceApi'
import {useAuth} from '../../../app/modules/auth'

interface ImagesFormProps {
  setImages: Function
  isLoading?: boolean
  setIsLoading?: Function
  triggerRegenerate?: string | number
  setMessage: Function
  handleMessageVisiability: Function
  setError: Function
}

const ImagesForm: FC<ImagesFormProps> = ({
  triggerRegenerate,
  setImages = () => {},
  setIsLoading = () => {},
  isLoading,
  setMessage = () => {},
  handleMessageVisiability = () => {},
  setError = () => {},
}) => {
  const {axiosURL, requiredQuestions, additionalQuestions, optionalSelect} = imageFormData
  const {currentUser} = useAuth()

  const myRef = useRef<HTMLFormElement | null>(null)
  const [isVisibleAdditionalQuestion, setIsVisibleAdditionalQuestion] = useState<boolean>(false)
  const [inputs, setInputs] = useState<IWorkflowFormInputs>({
    requiredQuestion1: '',
    additionalQuestion1: '',
    additionalQuestion2: '',
    optionalSelect1: '',
    optionalSelect2: '',
    optionalSelect3: '',
    optionalSelect4: '',
    optionalSelect5: '',
  })

  const location = window.location
  const [uuid, setUuid] = useState<string | null>(null)
  const [createActivity] = useCreateActivityMutation()
  const uuidFromUrl = new URLSearchParams(location.search).get('uuid')
  const [fetchActivity, {data: activity}] = useLazyGetActivityQuery()

  const handleSaveActivity = async (currentAnswer: string) => {
    const newActivity = {
      title: 'Image Generate',
      url: 'images',
      header: 'Image Generation',
      questions: {...inputs},
      currentAnswer,
      user_id: currentUser?.id,
      uuid,
    }
    await createActivity(newActivity)
      .unwrap()
      .then((res) => {
        setUuid(res?.data?.uuid)
      })
  }

  const {handleForm} = useDalleRequest({
    myRef,
    axiosURL,
    requiredQuestions,
    additionalQuestions,
    optionalSelect,
    inputs,
    setImages,
    setIsLoading,
    setMessage,
    handleMessageVisiability,
    setError,
    handleSaveActivity,
  })

  const handleInputs = (e: any) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    const name = e.target.name

    setInputs((prev: any) => ({...prev, [name]: value}))
  }

  const handleVisibleAdditQuest = () => {
    setIsVisibleAdditionalQuestion((prev) => !prev)
  }

  useEffect(() => {
    if (activity?.currentAnswer) {
      // setImages(JSON.parse(activity.currentAnswer));
    }

    if (activity?.questions) {
      setInputs(activity.questions)
    }
  }, [activity, setImages])

  useEffect(() => {
    if (uuid) {
      fetchActivity(uuid)
    }
  }, [fetchActivity, uuid])

  useEffect(() => {
    if (uuidFromUrl) {
      setUuid(uuidFromUrl)
    }
  }, [uuidFromUrl])

  const requiredQuestionsBlock = requiredQuestions.map((item: any, index: number) => {
    const {id, title, placeholder} = item
    const name = `requiredQuestion${index + 1}` as keyof typeof inputs
    return (
      <label key={id} className={classes.form__label}>
        <h3 className={classes.form__label__title}>
          {title} <span>*</span>
        </h3>
        <textarea
          name={name}
          value={inputs[name]}
          onChange={handleInputs}
          required={true}
          placeholder={placeholder}
        />
      </label>
    )
  })

  const additionalQuestionsBlock = additionalQuestions.map((item: any, index: number) => {
    const {id, title, placeholder} = item
    const name = `additionalQuestion${index + 1}` as keyof typeof inputs
    return (
      <label key={id} className={classes.form__label}>
        <h3 className={classes.form__label__title}>{title}</h3>
        <textarea
          name={name}
          value={inputs[name]}
          onChange={handleInputs}
          placeholder={placeholder}
        />
      </label>
    )
  })

  const optionalSelectBlock = optionalSelect.map((item: any, index: number) => {
    const {id, title, options, required} = item
    const name = `optionalSelect${index + 1}` as keyof typeof inputs
    const optionsBlock = options.map((unit: any, index: any) => (
      <option key={index} value={unit}>
        {unit}
      </option>
    ))

    return (
      <label key={id} className={classes.form__label}>
        <h3 className={classes.form__label__title}>
          {title} {required && <span>*</span>}
        </h3>
        <div className={classes.form__label__selectBlock}>
          <select name={name} value={inputs[name]} required={required} onChange={handleInputs}>
            <option value=''>Not selected</option>
            {optionsBlock}
          </select>
          <div className={classes.form__label__selectBlock__imgBlock}>
            <SelectArrowIcon className={classes.form__label__selectBlock__imgBlock__svg} />
          </div>
        </div>
      </label>
    )
  })

  useEffect(() => {
    if (triggerRegenerate) {
      handleForm(true)
    }
  }, [triggerRegenerate])

  return (
    <form ref={myRef} className={classes.form} onSubmit={handleForm}>
      {requiredQuestionsBlock}
      <div className={classes.form__optionalSelect}>{optionalSelectBlock}</div>

      {/* {additionalQuestions.length > 0 && (
        <div className={classes.form__additionalQuestions}>
          <div className={classes.form__additionalQuestions__btn} onClick={handleVisibleAdditQuest}>
            <SelectArrowIcon
              className={`${
                isVisibleAdditionalQuestion
                  ? classes.form__additionalQuestions__btn__svgActive
                  : classes.form__additionalQuestions__btn__svg
              }`}
            />
            Additional questions
          </div>
          {isVisibleAdditionalQuestion && additionalQuestionsBlock}
        </div>
      )} */}
      <button className='universalButton' disabled={isLoading}>
        Generate
      </button>
    </form>
  )
}

export default ImagesForm
