import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const CommentsIcon: FC<Props> = ({className, width = '32', height = '32', color = '#333333'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.2945 21.1593L20.2163 17.1378C20.1544 16.9124 20.1853 16.6694 20.2914 16.4573C21.2327 14.5702 21.5464 12.3164 20.9675 9.96541C20.1809 6.78356 17.6973 4.22925 14.5243 3.38518C13.6581 3.1598 12.8052 3.04932 11.9788 3.04932C6.04821 3.04932 1.41686 8.61312 3.01662 14.8045C3.75905 17.6681 6.81716 20.6334 9.69408 21.3317C10.4719 21.5217 11.2364 21.6101 11.9788 21.6101C13.5742 21.6101 15.0679 21.1991 16.3804 20.492C16.5262 20.4125 16.6897 20.3683 16.8488 20.3683C16.9283 20.3683 17.0079 20.3771 17.0874 20.3992L21.0029 21.4466C21.025 21.451 21.0471 21.4554 21.0647 21.4554C21.215 21.4554 21.3343 21.314 21.2945 21.1593ZM18.9789 17.4693L19.5887 19.7452L17.4189 19.1662C17.2333 19.1176 17.0433 19.0911 16.8488 19.0911C16.4776 19.0911 16.102 19.1883 15.7661 19.3695C14.5862 20.0059 13.3134 20.3285 11.9788 20.3285C11.3204 20.3285 10.653 20.2445 9.99458 20.0854C7.55959 19.4933 4.87712 16.8771 4.25843 14.4819C3.61322 11.9806 4.12143 9.413 5.65048 7.43761C7.17954 5.46221 9.48637 4.33089 11.9788 4.33089C12.7124 4.33089 13.4593 4.42812 14.1973 4.62698C16.9239 5.35173 19.0407 7.51716 19.7257 10.2748C20.2074 12.2192 20.0086 14.1593 19.1468 15.8872C18.8993 16.3821 18.8375 16.9434 18.9789 17.4693Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M3.36118 14.7157C1.82094 8.75443 6.27594 3.40538 11.9789 3.40538C12.7732 3.40538 13.5957 3.51152 14.4338 3.72952C17.4796 4.54008 19.8665 6.99411 20.6223 10.0511C21.1806 12.3189 20.8773 14.4861 19.9733 16.2984C19.832 16.5811 19.7862 16.9136 19.873 17.2312C19.8731 17.2316 19.8732 17.2319 19.8733 17.2323L20.897 21.0502L17.1828 20.0567C17.1822 20.0565 17.1816 20.0564 17.181 20.0562C17.0662 20.0245 16.9546 20.0128 16.8489 20.0128C16.6258 20.0128 16.4044 20.0742 16.2109 20.1795C14.9476 20.86 13.5118 21.2546 11.9789 21.2546C11.2649 21.2546 10.5287 21.1696 9.77865 20.9864L9.77812 20.9862C8.41695 20.6559 6.98747 19.7802 5.80341 18.6257C4.61925 17.4711 3.7119 16.0682 3.36118 14.7157ZM11.9789 4.68695C9.59669 4.68695 7.39369 5.76723 5.93195 7.65567C4.47277 9.54078 3.98426 11.9943 4.60302 14.3932C4.89134 15.5094 5.67235 16.708 6.69406 17.7055C7.7154 18.7027 8.94049 19.4632 10.0786 19.74C10.7116 19.8929 11.3507 19.973 11.9789 19.973C13.2542 19.973 14.469 19.6652 15.5973 19.0567C15.983 18.8486 16.4165 18.7356 16.8489 18.7356C17.0761 18.7356 17.2964 18.7667 17.5092 18.8224L17.5107 18.8228L19.0859 19.2431L18.6354 17.5619C18.4706 16.9489 18.5438 16.2983 18.8287 15.7284C19.651 14.0795 19.8427 12.2266 19.3806 10.3608C18.7265 7.72763 16.7059 5.66216 14.106 4.9711L14.1048 4.97079C13.3969 4.78002 12.6812 4.68695 11.9789 4.68695Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9789 2.69385C5.82078 2.69385 1.0131 8.47235 2.6723 14.8938C3.06406 16.4048 4.05701 17.9166 5.30669 19.1351C6.55638 20.3536 8.09419 21.3096 9.60977 21.6776C10.4152 21.8743 11.2081 21.9661 11.9789 21.9661C13.6364 21.9661 15.1878 21.5389 16.5492 20.8055L16.5509 20.8046C16.6488 20.7512 16.7542 20.7243 16.8489 20.7243C16.9018 20.7243 16.9487 20.7302 16.9924 20.7423L20.9221 21.7935L20.9332 21.7957L20.9364 21.7964C20.9526 21.7997 21.0089 21.8115 21.0649 21.8115C21.4367 21.8115 21.7396 21.4612 21.6393 21.071L20.56 17.046L20.5595 17.0439C20.523 16.911 20.5392 16.7576 20.6099 16.6164C21.5884 14.6548 21.9124 12.3143 21.313 9.88032C20.4956 6.5739 17.9158 3.91948 14.6159 3.04166L14.614 3.04117C13.7201 2.80858 12.8371 2.69385 11.9789 2.69385ZM11.9789 3.40538C6.27594 3.40538 1.82094 8.75443 3.36118 14.7157C3.7119 16.0682 4.61925 17.4711 5.80341 18.6257C6.98747 19.7802 8.41695 20.6559 9.77812 20.9862L9.77865 20.9864C10.5287 21.1696 11.2649 21.2546 11.9789 21.2546C13.5118 21.2546 14.9476 20.86 16.2109 20.1795C16.4044 20.0742 16.6258 20.0128 16.8489 20.0128C16.9546 20.0128 17.0662 20.0245 17.181 20.0562L17.1828 20.0567L20.897 21.0502L19.8733 17.2323L19.873 17.2312C19.7862 16.9136 19.832 16.5811 19.9733 16.2984C20.8773 14.4861 21.1806 12.3189 20.6223 10.0511C19.8665 6.99411 17.4796 4.54008 14.4338 3.72952C13.5957 3.51152 12.7732 3.40538 11.9789 3.40538Z'
        fill={color}
      />
    </svg>
  )
}

export default CommentsIcon
