import chroma from 'chroma-js'

export const generateColorPalette = (
  brandColor: string,
  scale: number = 5,
  includeTransparent: boolean = false
): string[] => {
  const fullPalette = chroma
    .scale([brandColor, '#000000'])
    .mode('lab')
    .colors(scale + 1)
  const palette = fullPalette
    .filter((color) => color !== '#ffffff')
    .map((color) => chroma(color).hex())
    .slice(0, scale)

  return includeTransparent
    ? [
        ...palette,
        ...Array.from({length: 5}, (_, j) =>
          chroma(brandColor)
            .alpha((j + 1) * 0.2)
            .hex()
        ),
      ]
    : palette
}
