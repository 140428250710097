import {useState} from 'react'
import {useAuth} from '../../../../../../app/modules/auth'

interface PlanOption {
  name: string // Tier name (e.g., 'Tier 1', 'Tier 2')
  value: number // Number of words or images
  label: string // Full description (e.g., '100,000 words + 1000 credits - $50')
  payment_link: string
}

interface SelectedPlans {
  words_and_credits: PlanOption
  images: PlanOption
}

const plansData = {
  words_and_credits: [
    {
      name: 'Tier 1',
      value: 100000,
      label: '100,000 words + 1000 credits - $50',
      payment_link: 'https://buy.stripe.com/test_9AQ3g9dVE5sG1J6004',
    },
    {name: 'Tier 2', value: 200000, label: '200,000 words + 2000 credits - $100', payment_link: ''},
    {name: 'Tier 3', value: 300000, label: '300,000 words + 3000 credits - $150', payment_link: ''},
    {name: 'Tier 4', value: 400000, label: '400,000 words + 4000 credits - $200', payment_link: ''},
    {name: 'Tier 5', value: 500000, label: '500,000 words + 5000 credits - $250', payment_link: ''},
    {name: 'Tier 6', value: 600000, label: '600,000 words + 6000 credits - $300', payment_link: ''},
    {name: 'Tier 7', value: 700000, label: '700,000 words + 7000 credits - $350', payment_link: ''},
    {name: 'Tier 8', value: 800000, label: '800,000 words + 8000 credits - $400', payment_link: ''},
    {name: 'Tier 9', value: 900000, label: '900,000 words + 9000 credits - $450', payment_link: ''},
    {
      name: 'Tier 10',
      value: 1000000,
      label: '1,000,000 words + 10,000 credits - $500',
      payment_link: '',
    },
  ],
  images: [
    {name: 'Tier 1 ', value: 100, label: '100 images - $30', payment_link: ''},
    {name: 'Tier 2', value: 200, label: '200 images - $60', payment_link: ''},
    {name: 'Tier 3', value: 300, label: '300 images - $90', payment_link: ''},
    {name: 'Tier 4', value: 400, label: '400 images - $120', payment_link: ''},
    {name: 'Tier 5', value: 500, label: '500 images - $150', payment_link: ''},
    {name: 'Tier 6', value: 600, label: '600 images - $180', payment_link: ''},
    {name: 'Tier 7', value: 700, label: '700 images - $210', payment_link: ''},
    {name: 'Tier 8', value: 800, label: '800 images - $240', payment_link: ''},
    {name: 'Tier 9', value: 900, label: '900 images - $270', payment_link: ''},
    {name: 'Tier 10', value: 1000, label: '1000 images - $300', payment_link: ''},
  ],
}

const TopUpComponent: React.FC = () => {
  const [selectedPlans, setSelectedPlans] = useState<SelectedPlans>({
    words_and_credits: plansData.words_and_credits[0],
    images: plansData.images[0],
  })
  const {currentUser} = useAuth()

  const handlePlanSelect = (category: keyof SelectedPlans, selectedOption: PlanOption) => {
    setSelectedPlans((prevState) => ({
      ...prevState,
      [category]: selectedOption,
    }))
  }

  const handlePayment = (url: string) => {
    if (currentUser?.email) {
      window.open(`${url}?prefilled_email=${encodeURIComponent(currentUser.email)}`, '_blank');
    } 
  };
  

  const renderCard = (category: keyof SelectedPlans, data: PlanOption[]) => {
    const categoryLabel = category === 'words_and_credits' ? 'Words & Credits' : 'Images'

    return (
      <div className='col-12 col-md-4'>
        <div className='card h-100 shadow-sm'>
          <div className='card-body'>
            <div className='d-flex align-items-center mb-3'>
              <i
                className={`bi bi-${
                  category === 'words_and_credits' ? 'file-text' : 'image'
                } fs-4 me-2`}
              />
              <h5 className='card-title mb-0'>{categoryLabel} Package</h5>
            </div>
            {/* <p className='card-text text-muted'>
              {category === 'words_and_credits'
                ? 'Purchase words and credits'
                : 'Purchase images'}
            </p> */}
            <div className='form-group'>
              <label htmlFor={`${category}Select`} className='form-label'>
                Select Amount
              </label>
              <select
                className='form-select'
                id={`${category}Select`}
                onChange={(e) =>
                  handlePlanSelect(category, data.find((item) => item.name === e.target.value)!)
                }
                value={selectedPlans[category].name}
              >
                {data.map((option) => (
                  <option key={option.name} value={option.name}>
                    {option.name} - {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='mt-3'>
              <span className='badge bg-secondary'>Selected Plan</span>
              {/* <p className="mt-2 mb-0">{selectedPlans[category].name}</p> */}
              <p className='mt-2 mb-0'>{selectedPlans[category].label}</p>{' '}
              {/* Shows the full label here */}
            </div>

            <div className='mt-3'>
              <button
                className='btn btn-primary'
                onClick={() => handlePayment(selectedPlans[category].payment_link)}
              >
                Purchase
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='row g-4'>
      {renderCard('words_and_credits', plansData.words_and_credits)}
      {renderCard('images', plansData.images)}
    </div>
  )
}

export default TopUpComponent
