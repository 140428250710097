import type { FC } from 'react';
import classes from './CombinedWorkflow.module.scss';
import { Link } from 'react-router-dom';
import { CustomTooltip } from '../tooltip/Tooltip';
import { useAuth } from '../../../../app/modules/auth';
import { Button } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import { isSuperAdmin } from '../../../../utils/userRoles';

interface CombinedWorkflowProps {
  title: string;
  buttonColor: string;
  itemsData: any;
}

const CombinedWorkflow: FC<CombinedWorkflowProps> = ({ title, buttonColor, itemsData }) => {
  const { currentUser, toggleSidebar } = useAuth();

  const disabledWorkflows = [
    'Lead Finder (Beta)',
    !isSuperAdmin(currentUser?.email) ? 'Marketplace Listing' : '',
    !isSuperAdmin(currentUser?.email) ? 'Image to Marketplace Listing' : '',

  ];

  const templatesBlock = itemsData.templates
    .slice()
    .sort((a: any, b: any) => a.id - b.id)
    .map((item: any) => {
      const { id, title, limits, description, url, Img, tooltipText, tooltipAnchor, notWorking } = item;

      if (disabledWorkflows.includes(title)) {
        return null;
      }

      if (title !== 'Generate Cold Email WITH ICE BREAKER') {
        return (
          <div className='col-12 col-sm-6 col-lg-3 mb-sm-4 mb-3' key={`template-container-${id}`}>
            <div className={classes.combined__row__item}>
              <div className={classes.combined__row__item__topBlock}>
                <h3 className={classes.combined__row__item__topBlock__title}>
                  {title}
                  {notWorking && <span className='universalComingSoon'>Currently unavailable</span>}
                  {tooltipAnchor && tooltipText && <CustomTooltip tooltipText={tooltipText} tooltipAnchor={tooltipAnchor} />}
                </h3>
                <p>{description}</p>
              </div>
              <div className={classes.combined__row__item__bottomBlock}>
                <Link to={notWorking ? '' : title === 'Lead Finder (Beta)' ? '' : url}>
                  {title === 'Lead Finder (Beta)' ? (
                    <button className='universalButton' style={{ pointerEvents: 'none' }} disabled>
                      Maintenance
                    </button>
                  ) : (
                    <button className='universalButton' style={{ background: buttonColor }}>
                      Try now
                    </button>
                  )}
                </Link>
              </div>
            </div>
          </div>
        );
      } else if (
        [ 'danny@klevere.ai', 'sarfaraz@mediusware.com'].includes(
          currentUser?.email || ''
        )
      ) {
        return (
          <div className='p-4 col-12 col-sm-6 col-lg-3' style={{ height: '240px' }} key={`template-special-${id}`}>
            <div className={classes.combined__row__item}>
              <div className={classes.combined__row__item__topBlock}>
                <h3 className={classes.combined__row__item__topBlock__title}>
                  {title}
                  {notWorking && <span className='universalComingSoon'>Currently unavailable</span>}
                  {tooltipAnchor && tooltipText && <CustomTooltip tooltipText={tooltipText} tooltipAnchor={tooltipAnchor} />}
                </h3>
                <p>{description}</p>
              </div>
              <div className={classes.combined__row__item__bottomBlock}>
                <Link to={notWorking ? '' : url}>
                  <button className='universalButton' style={{ background: buttonColor }}>
                    Try now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        );
      }

      return null;
    });

  const workflowsBlock = itemsData.workflows.map((item: any) => {
    const { id, title, description, url, Img, tooltipText, tooltipAnchor } = item;
    return (
      <div className='p-4 col-12 col-sm-6 col-lg-3' style={{ height: '240px' }} key={`workflow-${id}`}>
        <div className={classes.combined__row__item}>
          <div className={classes.combined__row__item__topBlock}>
            <h3 className={classes.combined__row__item__topBlock__title}>
              {title}
              {tooltipAnchor && tooltipText && <CustomTooltip tooltipText={tooltipText} tooltipAnchor={tooltipAnchor} />}
            </h3>
            <p>{description}</p>
          </div>
          <div className={classes.combined__row__item__bottomBlock}>
            <Link to={url}>
              <button className='mt-3' style={{ background: buttonColor }}>
                Try now
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={`universalPage align-items-start d-block ${classes.combined}`}>
      <div className='d-flex justify-content-between w-100 align-items-center mb-sm-4 mb-3'>
        <h1 className='universalTopTitle'>{title} Workflows</h1>
        <Button variant='dark' className='navbar-toggler-btn' onClick={toggleSidebar}>
          <GiHamburgerMenu />
        </Button>
      </div>
      <div className='row'>
        {templatesBlock} {workflowsBlock}
      </div>
    </div>
  );
};

export default CombinedWorkflow;
