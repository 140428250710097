import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {Button, Col, Form, InputGroup, Row, Spinner} from 'react-bootstrap'
import {ColorKeyEnum, ColorValueEnum} from '../../../../../../types/types'
import {setCssVariable} from '../../../../../../utils/setting/cssUtils'
import chroma from 'chroma-js'
import {generateColorPalette} from '../../../../../../utils/setting/colorUtils'
import {
  useGetColorQuery,
  useUpdateColorMutation,
} from '../../../../../../services/settingServiceApi'
import { toast } from 'react-toastify'

// FormState interface with string index signature
interface FormState {
  [key: string]: string // Allows any string key with string values
}

// Define the structure for each form group
interface FormGroup {
  label: {
    text: string
    style: React.CSSProperties
  }
  inputGroup: {
    colorInput: {
      type: string
      name: string
    }
    textInput: {
      type: string
      name: string
      value: string
    }
  }
}

const ColorComponent: React.FC = () => {
  // Initial form state based on JSON structure
  const initialFormState: FormState = {
    [ColorKeyEnum.APP_BACKGROUND_COLOR]: ColorValueEnum.APP_BACKGROUND_COLOR,
    [ColorKeyEnum.HEADER_BACKGROUND_COLOR]: ColorValueEnum.HEADER_BACKGROUND_COLOR,
    [ColorKeyEnum.PRIMARY_COLOR]: ColorValueEnum.PRIMARY_COLOR,
    [ColorKeyEnum.PRIMARY_FONT_COLOR]: ColorValueEnum.PRIMARY_FONT_COLOR,
    [ColorKeyEnum.SECONDARY_FONT_COLOR]: ColorValueEnum.SECONDARY_FONT_COLOR,

    [ColorKeyEnum.MARKETING_WORKFLOW_BTN_COLOR]: ColorValueEnum.MARKETING_WORKFLOW_BTN_COLOR,
    [ColorKeyEnum.SALES_WORKFLOW_BTN_COLOR]: ColorValueEnum.SALES_WORKFLOW_BTN_COLOR,
    [ColorKeyEnum.HR_WORKFLOW_BTN_COLOR]: ColorValueEnum.HR_WORKFLOW_BTN_COLOR,
    [ColorKeyEnum.FINANCE_WORKFLOW_BTN_COLOR]: ColorValueEnum.FINANCE_WORKFLOW_BTN_COLOR,
  }

  const [formState, setFormState] = useState<FormState>(initialFormState)
  const [submittedData, setSubmittedData] = useState<any>(null)
  const [updateColor, {isLoading}] = useUpdateColorMutation()
  const { data:colorData, error } = useGetColorQuery('');

  useEffect(() => {
    const fetchColors = async () => {
      try {
  
        if (error) {
          console.error('Error fetching colors:', error);
          return; 
        }
  
        const data = colorData;

        if (colorData) {
          setFormState({
            [ColorKeyEnum.APP_BACKGROUND_COLOR]: data[ColorKeyEnum.APP_BACKGROUND_COLOR],
            [ColorKeyEnum.HEADER_BACKGROUND_COLOR]: data[ColorKeyEnum.HEADER_BACKGROUND_COLOR],
            [ColorKeyEnum.PRIMARY_COLOR]: data[ColorKeyEnum.PRIMARY_COLOR],
            [ColorKeyEnum.PRIMARY_FONT_COLOR]: data[ColorKeyEnum.PRIMARY_FONT_COLOR],
            [ColorKeyEnum.SECONDARY_FONT_COLOR]: data[ColorKeyEnum.SECONDARY_FONT_COLOR],
            [ColorKeyEnum.MARKETING_WORKFLOW_BTN_COLOR]: data[ColorKeyEnum.MARKETING_WORKFLOW_BTN_COLOR],
            [ColorKeyEnum.SALES_WORKFLOW_BTN_COLOR]: data[ColorKeyEnum.SALES_WORKFLOW_BTN_COLOR],
            [ColorKeyEnum.HR_WORKFLOW_BTN_COLOR]: data[ColorKeyEnum.HR_WORKFLOW_BTN_COLOR],
            [ColorKeyEnum.FINANCE_WORKFLOW_BTN_COLOR]: data[ColorKeyEnum.FINANCE_WORKFLOW_BTN_COLOR],
          });
        }
      } catch (error) {
        console.error('Error fetching colors:', error);
      }
    };
  
    fetchColors();
  }, [colorData]); 

  // Handle input change for both color and text inputs
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target

    console.log(name, value)

    setCssVariable(name, value)
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }))

    console.log(formState)
  }

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      console.log('formState',formState)
      updateColor(formState)
        .unwrap()
        .then((data) => {
          toast.success('Color updated successfully')
          console.log(data)
        })
        .finally(() => {})
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  // Define the form data structure
  const formData: {formGroups: FormGroup[]} = {
    formGroups: [

      {
        label: {text: 'App Background', style: {color: '#000'}},
        inputGroup: {
          colorInput: {type: 'color', name: ColorKeyEnum.APP_BACKGROUND_COLOR},
          textInput: {
            type: 'text',
            name: ColorKeyEnum.APP_BACKGROUND_COLOR,
            value: formState[ColorKeyEnum.APP_BACKGROUND_COLOR], // Sync with form state
          },
        },
      },

      {
        label: {text: 'Sidebar Background', style: {color: '#000'}},
        inputGroup: {
          colorInput: {type: 'color', name: ColorKeyEnum.HEADER_BACKGROUND_COLOR},
          textInput: {
            type: 'text',
            name: ColorKeyEnum.HEADER_BACKGROUND_COLOR,
            value: formState[ColorKeyEnum.HEADER_BACKGROUND_COLOR], // Sync with form state
          },
        },
      },

      {
        label: {text: 'Primary Color', style: {color: '#000'}},
        inputGroup: {
          colorInput: {type: 'color', name: ColorKeyEnum.PRIMARY_COLOR},
          textInput: {
            type: 'text',
            name: ColorKeyEnum.PRIMARY_COLOR,
            value: formState[ColorKeyEnum.PRIMARY_COLOR], // Sync with form state
          },
        },
      },

      {
        label: {text: 'Primary Font Color', style: {color: '#000'}},
        inputGroup: {
          colorInput: {type: 'color', name: ColorKeyEnum.PRIMARY_FONT_COLOR},
          textInput: {
            type: 'text',
            name: ColorKeyEnum.PRIMARY_FONT_COLOR,
            value: formState[ColorKeyEnum.PRIMARY_FONT_COLOR], // Sync with form state
          },
        },
      },

      {
        label: {text: 'Secondary Font Color', style: {color: '#000'}},
        inputGroup: {
          colorInput: {type: 'color', name: ColorKeyEnum.SECONDARY_FONT_COLOR},
          textInput: {
            type: 'text',
            name: ColorKeyEnum.SECONDARY_FONT_COLOR,
            value: formState[ColorKeyEnum.SECONDARY_FONT_COLOR], // Sync with form state
          },
        },
      },

    

      {
        label: {text: 'Marketing Workflow button', style: {color: '#000'}},
        inputGroup: {
          colorInput: {type: 'color', name: ColorKeyEnum.MARKETING_WORKFLOW_BTN_COLOR},
          textInput: {
            type: 'text',
            name: ColorKeyEnum.MARKETING_WORKFLOW_BTN_COLOR,
            value: formState[ColorKeyEnum.MARKETING_WORKFLOW_BTN_COLOR], // Sync with form state
          },
        },
      },

      {
        label: {text: 'Sales Workflow button', style: {color: '#000'}},
        inputGroup: {
          colorInput: {type: 'color', name: ColorKeyEnum.SALES_WORKFLOW_BTN_COLOR},
          textInput: {
            type: 'text',
            name: ColorKeyEnum.SALES_WORKFLOW_BTN_COLOR,
            value: formState[ColorKeyEnum.SALES_WORKFLOW_BTN_COLOR], // Sync with form state
          },
        },
      },

      {
        label: {text: 'HR Workflow button', style: {color: '#000'}},
        inputGroup: {
          colorInput: {type: 'color', name: ColorKeyEnum.HR_WORKFLOW_BTN_COLOR},
          textInput: {
            type: 'text',
            name: ColorKeyEnum.HR_WORKFLOW_BTN_COLOR,
            value: formState[ColorKeyEnum.HR_WORKFLOW_BTN_COLOR], // Sync with form state
          },
        },
      },

      {
        label: {text: 'Finance Workflow button', style: {color: '#000'}},
        inputGroup: {
          colorInput: {type: 'color', name: ColorKeyEnum.FINANCE_WORKFLOW_BTN_COLOR},
          textInput: {
            type: 'text',
            name: ColorKeyEnum.FINANCE_WORKFLOW_BTN_COLOR,
            value: formState[ColorKeyEnum.FINANCE_WORKFLOW_BTN_COLOR], // Sync with form state
          },
        },
      },
    ],
  }

  console.log(generateColorPalette('#1c79c0', 5))
  const renderFormGroups = (formGroups: FormGroup[]) => {
    return formGroups.map((group, index) => (
      <Col md={4} key={index}>
        <Form.Group className="mb-3">
          <Form.Label style={group.label.style}>{group.label.text}</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <Form.Control
                type={group.inputGroup.colorInput.type}
                name={group.inputGroup.colorInput.name}
                value={formState[group.inputGroup.colorInput.name]} // Access using the name
                onChange={handleChange}
                style={{
                  width: 40,
                  height: 38,
                  padding: 0,
                  border: 'none',
                }}
              />
            </InputGroup.Text>
            <Form.Control
              type={group.inputGroup.textInput.type}
              name={group.inputGroup.textInput.name}
              value={formState[group.inputGroup.textInput.name]} // Access using the name
              onChange={handleChange}
            />
          </InputGroup>
        </Form.Group>
      </Col>
    ));
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row >
      {renderFormGroups(formData.formGroups)}
      </Row>
      <Button
      type="submit"
      className="mt-3"
      variant="primary"
      disabled={isLoading} // Disable button when loading
    >
      {isLoading ? (
        <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
      ) : (
        'Submit'
      )}
    </Button>
      {submittedData && <div className='alert alert-success'>Form submitted successfully!</div>}
    </form>
  )
}

export default ColorComponent
