import type {FC} from 'react'
import salesTemplatesData from '../../../data/sales/salesTemplatesData'
import {useParams} from 'react-router-dom'
import CombinedGeneration from '../../universal-components/combined-generation/CombinedGeneration'
import LeadFinderCombinedGeneration from '../../universal-components/combined-generation/LeadFinderCombinedGeneration'

interface SalesTemplatePageProps {}

const SalesTemplatePage: FC<SalesTemplatePageProps> = () => {
  const {templateUrl} = useParams()
  const template: any = salesTemplatesData.templates.find((item: any) =>
    item.url.includes(templateUrl)
  )

  if (template.url === 'professional-email-finder') {
    return <LeadFinderCombinedGeneration templateItem={template} />
  }

  return <CombinedGeneration templateItem={template} />
}

export default SalesTemplatePage
