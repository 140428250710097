import {useEffect, useState, type FC} from 'react'
import {
  Button,
  Col,
  Container,
  Dropdown,
  Figure,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Image,
} from 'react-bootstrap'
import classes from '../MembersArea.module.scss'
import {toast} from 'react-toastify'
import {FaEye, FaEyeSlash} from 'react-icons/fa6'
import {
  useAdminCreateUserMutation,
  useAdminUpdateUserMutation,
  useGetUserByIdQuery,
} from '../../../../services/userServiceApi'
import {RoleType} from '../../../../types/types'
import {isSuperAdmin} from '../../../../utils/userRoles'
import {useAuth} from '../../../../app/modules/auth'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useGetPlansQuery} from '../../../../services/settingServiceApi'
import {lowerCase, startCase, toLower} from 'lodash'

const AddUserModal = ({
  show,
  setShow,
  userId = null,
  refetch,
}: {
  show: boolean
  setShow: (show: boolean) => void
  userId?: number | null
  refetch: () => void
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {data: user} = useGetUserByIdQuery(userId, {skip: !userId})
  const [createUser] = useAdminCreateUserMutation()
  const [updateUser] = useAdminUpdateUserMutation()
  const {data: plans} = useGetPlansQuery({
    status:true
  })
  const {currentUser} = useAuth()

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .min(3, 'First name must have at least 3 characters')
      .max(100, 'First name must have at most 100 characters')
      .required('First name is required'),

    last_name: Yup.string()
      .min(3, 'Last name must have at least 3 characters')
      .max(100, 'Last name must have at most 100 characters')
      .required('Last name is required'),

    email: Yup.string()
      .email('Invalid email address')
      .min(5, 'E-mail must have at least 5 characters')
      .max(100, 'E-mail must have at most 100 characters')
      .required('Email is required'),

    company_name: Yup.string()
      .min(3, 'Company name must have at least 3 characters')
      .max(100, 'Company name must have at most 100 characters')
      .required('Company name is required'),

    password: Yup.string()
      .min(8, 'Password must have at least 8 characters')
      .max(100, 'Password must have at most 100 characters')
      .required('Password is required'),

    plan_public_id: Yup.string().required('Plan selection is required'),

    role: Yup.string()
      .oneOf(Object.values(RoleType), 'Please, choose from list')
      .required('Role is required'),
  })

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      company_name: '',
      role: '',
      password: '',
      plan_public_id: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      try {
        if (userId) {
          await updateUser({...values, id: userId}).unwrap()
          toast.success('User updated successfully')
        } else {
          await createUser(values).unwrap()
          toast.success('User added successfully')
        }
        refetch()
        setShow(false)
        formik.resetForm()
      } catch (error: any) {
        console.error(error)

        if (error.status === 400) {
          const message = Array.isArray(error.data.message)
            ? error.data.message[0]
            : error.data.message
          toast.error(message)
          return
        }

        toast.error('Something went wrong')
      } finally {
        setIsLoading(false)
      }
    },
  })

  useEffect(() => {
    if (user) {
      console.log('user', user)
      formik.setValues({
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        email: user.email || '',
        company_name: user.company_name || '',
        role: user.role || '',
        password: '', // Password field empty for security
        plan_public_id: user.package?.public_id || '',
      })
    }
  }, [user])

  const handleClose = () => {
    setShow(false)
    formik.resetForm()
  }

  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
      <Modal.Header style={{border: 'none'}}>
        <Modal.Title>{userId ? 'Edit User' : 'Add User'}</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-6'>
              <Form.Group className='mb-3' controlId='formFirstName'>
                <Form.Label>
                  First Name <sup className='text-danger'>*</sup>
                </Form.Label>
                <Form.Control
                  name='first_name'
                  type='text'
                  placeholder='John'
                  value={formik.values.first_name}
                  onChange={(e) => {
                    formik.setFieldValue('first_name', startCase(lowerCase(e.target.value)))
                  }}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.first_name && formik.touched.first_name}
                  style={{textTransform: 'capitalize'}}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.first_name}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className='mb-3' controlId='formLastName'>
                <Form.Label>
                  Last Name <sup className='text-danger'>*</sup>
                </Form.Label>
                <Form.Control
                  name='last_name'
                  type='text'
                  placeholder='Doe'
                  value={formik.values.last_name}
                  onChange={(e) => {
                    formik.setFieldValue('last_name', startCase(lowerCase(e.target.value)))
                  }}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.last_name && formik.touched.last_name}
                  style={{textTransform: 'capitalize'}}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.last_name}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className='mb-3' controlId='formEmail'>
                <Form.Label>
                  Email Address <sup className='text-danger'>*</sup>
                </Form.Label>
                <Form.Control
                  name='email'
                  type='email'
                  placeholder='name@example.com'
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.setFieldValue('email', toLower(e.target.value))
                  }}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email && formik.touched.email}
                  autoComplete='off'
                />
                <Form.Control.Feedback type='invalid'>{formik.errors.email}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className='mb-3' controlId='formCompany'>
                <Form.Label>
                  Company <sup className='text-danger'>*</sup>
                </Form.Label>
                <Form.Control
                  name='company_name'
                  type='text'
                  placeholder='Company Name'
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.company_name && formik.touched.company_name}
                  // style={{ textTransform: "capitalize" }}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.company_name}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className='mb-3' controlId='formUserPlan'>
                <Form.Label>
                  Plan{' '}
                  <sup className='text-danger'>
                    {' '}
                    <span className='text-danger'>
                      {plans && plans.length > 0 ? `(${plans.length})` : 'You have no plans'}
                    </span>{' '}
                    *{' '}
                  </sup>
                </Form.Label>

                <Form.Select
                  name='plan_public_id'
                  value={formik.values.plan_public_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.plan_public_id && formik.touched.plan_public_id}
                >
                  <option value=''>Select a Plan</option>

                  {plans?.map((plan: any) => {
                    if (!isSuperAdmin(currentUser?.email) && plan.user_id !== currentUser?.id) {
                      return null
                    }

                    return (
                      <option key={plan.public_id} value={plan.public_id}>
                        {plan.name}
                      </option>
                    )
                  })}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.plan_public_id}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className='mb-3' controlId='formUserRole'>
                <Form.Label>
                  User Role <sup className='text-danger'>*</sup>
                </Form.Label>
                <Form.Select
                  name='role'
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.role && formik.touched.role}
                >
                  <option value=''>Select a role</option>
                  {Object.values(RoleType).map((role: string) => {
                    if (!isSuperAdmin(currentUser?.email) && role !== RoleType.WHITE_LABEL_USER && role !== RoleType.WHITE_LABEL_ADMIN) {
                      return null // Skip non-USER roles for non-super admins
                    }
                    return (
                      <option key={role} value={role}>
                        {role
                          .toLowerCase()
                          .split('_')
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')}
                      </option>
                    )
                  })}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{formik.errors.role}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='col-md-6'>
              <Form.Group className='mb-3' controlId='formPassword'>
                <Form.Label>
                  Password <sup className='text-danger'>*</sup>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    name='password'
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder='Enter your password (Min: 8 characters)'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.errors.password && formik.touched.password}
                    autoComplete='off'
                  />
                  <InputGroup.Text onClick={() => setPasswordVisible(!passwordVisible)}>
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{padding: '1rem', borderTop: 'none'}}>
          <div className={classes.maping__modalSubmitOuter}>
            <button
              type='button'
              className={`mx-3 ${classes.maping__modalCloseButton}`}
              onClick={handleClose}
            >
              Close
            </button>
            <button type='submit' className={classes.maping__modalCloseButton} disabled={isLoading}>
              {isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default AddUserModal
