import type {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const CheckmarkIcon: FC<Props> = ({className, width = '24', height = '24', color = '#2468FF'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      className={className}
    >
      <path
        d='M14 2.33337C7.57171 2.33337 2.33337 7.57171 2.33337 14C2.33337 20.4284 7.57171 25.6667 14 25.6667C20.4284 25.6667 25.6667 20.4284 25.6667 14C25.6667 7.57171 20.4284 2.33337 14 2.33337ZM19.5767 11.3167L12.9617 17.9317C12.7984 18.095 12.5767 18.1884 12.3434 18.1884C12.11 18.1884 11.8884 18.095 11.725 17.9317L8.42337 14.63C8.08504 14.2917 8.08504 13.7317 8.42337 13.3934C8.76171 13.055 9.32171 13.055 9.66004 13.3934L12.3434 16.0767L18.34 10.08C18.6784 9.74171 19.2384 9.74171 19.5767 10.08C19.915 10.4184 19.915 10.9667 19.5767 11.3167Z'
        fill={color}
      />
    </svg>
  )
}

export default CheckmarkIcon
