import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const FaceIcon: FC<Props> = ({className, width = '32', height = '32', color = '#333333'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        d='M23.4375 12.5C23.4375 6.45939 18.5406 1.5625 12.5 1.5625C6.45939 1.5625 1.5625 6.45939 1.5625 12.5C1.5625 18.5406 6.45939 23.4375 12.5 23.4375C18.5406 23.4375 23.4375 18.5406 23.4375 12.5Z'
        fill='#DEB8DC'
      />
      <path
        d='M20.3125 12.5C20.3125 8.18528 16.8147 4.6875 12.5 4.6875C8.18528 4.6875 4.6875 8.18528 4.6875 12.5C4.6875 16.8147 8.18528 20.3125 12.5 20.3125C16.8147 20.3125 20.3125 16.8147 20.3125 12.5Z'
        fill='#F9F0F8'
        stroke='#523951'
      />
      <mask
        id='mask0_1366_3581'
        // style='mask-type:alpha'
        maskUnits='userSpaceOnUse'
        x='3'
        y='3'
        width='19'
        height='19'
      >
        <path
          d='M12.5 21.0938C10.2216 21.0913 8.03715 20.1851 6.42604 18.574C4.81494 16.9629 3.90873 14.7784 3.90625 12.5C3.90625 7.76094 7.7625 3.90625 12.5 3.90625C17.2375 3.90625 21.0938 7.76094 21.0938 12.5C21.0938 17.2391 17.2375 21.0938 12.5 21.0938Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_1366_3581)'>
        <path
          fillRule='evenodd'
          clip-rule='evenodd'
          d='M10.0029 9.92188C9.86956 9.92187 9.73749 9.94815 9.61427 9.99919C9.49105 10.0502 9.37908 10.125 9.28477 10.2193C9.19046 10.3137 9.11565 10.4256 9.06461 10.5488C9.01357 10.6721 8.9873 10.8041 8.9873 10.9375C8.9873 11.0709 9.01357 11.2029 9.06461 11.3262C9.11565 11.4494 9.19046 11.5613 9.28477 11.6557C9.37908 11.75 9.49105 11.8248 9.61427 11.8758C9.73749 11.9269 9.86956 11.9531 10.0029 11.9531C10.2723 11.9531 10.5306 11.8461 10.7211 11.6557C10.9116 11.4652 11.0186 11.2069 11.0186 10.9375C11.0186 10.6681 10.9116 10.4098 10.7211 10.2193C10.5306 10.0289 10.2723 9.92188 10.0029 9.92188ZM15.0529 10.8844C14.9196 10.8844 14.7875 10.9106 14.6643 10.9617C14.541 11.0127 14.4291 11.0875 14.3348 11.1818C14.2405 11.2762 14.1657 11.3881 14.1146 11.5113C14.0636 11.6346 14.0373 11.7666 14.0373 11.9C14.0373 12.0334 14.0636 12.1654 14.1146 12.2887C14.1657 12.4119 14.2405 12.5238 14.3348 12.6182C14.4291 12.7125 14.541 12.7873 14.6643 12.8383C14.7875 12.8894 14.9196 12.9156 15.0529 12.9156C15.3223 12.9156 15.5806 12.8086 15.7711 12.6182C15.9616 12.4277 16.0686 12.1694 16.0686 11.9C16.0686 11.6306 15.9616 11.3723 15.7711 11.1818C15.5806 10.9914 15.3223 10.8844 15.0529 10.8844Z'
          fill='#523951'
          stroke='#523951'
          strokeWidth='0.1'
        />
        <path
          d='M14.4373 9.08448C14.7967 8.95635 15.1951 8.95635 15.5545 9.08448C15.9264 9.17041 16.2498 9.38135 16.4654 9.67823M8.9873 7.82354C9.25918 7.56729 9.6248 7.41573 10.0092 7.39541C10.3936 7.34229 10.7826 7.42666 11.1029 7.62979L8.9873 7.82354Z'
          stroke='#523951'
          strokeWidth='0.9'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          fillRule='evenodd'
          clip-rule='evenodd'
          d='M12.6718 15.5779C12.7849 15.5572 12.9012 15.5603 13.0131 15.5869C13.125 15.6136 13.2301 15.6632 13.3218 15.7326C13.5093 15.8732 13.6343 16.0857 13.6687 16.3232C13.6972 16.5517 13.6409 16.7828 13.5104 16.9726C13.38 17.1624 13.1844 17.2977 12.9609 17.3529L10.7812 17.7357C10.9687 17.7045 11.1609 17.7529 11.3124 17.8717C11.4672 17.9917 11.5683 18.168 11.5937 18.3623C11.6252 18.5501 11.586 18.7429 11.4835 18.9034C11.381 19.0639 11.2226 19.1806 11.039 19.231C11.2421 19.3404 11.3827 19.5435 11.4202 19.7779C11.4483 19.9538 11.4166 20.134 11.3302 20.2898C11.2438 20.4456 11.1077 20.5679 10.9437 20.6373C11.0579 20.789 11.1114 20.9779 11.0937 21.167C11.078 21.3467 11.0025 21.5159 10.8793 21.6476C10.756 21.7793 10.5922 21.8659 10.414 21.8935L10.1609 21.9498C9.50461 22.0982 8.84211 22.2123 8.17492 22.2904C7.41556 22.3013 6.6569 22.2396 5.9093 22.106C5.65329 22.047 5.41707 21.9223 5.22392 21.7442C5.03076 21.5661 4.88732 21.3408 4.80773 21.0904C4.31242 20.0201 4.04211 17.9951 4.64836 17.4092C5.25617 16.8248 5.15148 16.9717 5.44211 16.2763C5.73117 15.5826 6.03586 14.7857 6.26711 14.1045C6.49836 13.4263 6.58898 13.1201 7.01867 12.9545C7.22914 12.8915 7.45563 12.9105 7.6527 13.0076C7.84977 13.1047 8.00283 13.2727 8.08117 13.4779C8.14367 13.9498 8.12336 14.431 8.01555 14.8935C7.90617 15.8373 7.90305 16.0545 8.01555 16.3763L12.6718 15.5795V15.5779Z'
          stroke='#523951'
          strokeWidth='1.152'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M7.46867 16.4842L12.6718 15.5779C12.7849 15.5572 12.9012 15.5603 13.0131 15.5869C13.125 15.6136 13.2301 15.6632 13.3218 15.7326C13.5093 15.8732 13.6343 16.0857 13.6687 16.3232C13.6972 16.5517 13.6409 16.7828 13.5104 16.9726C13.38 17.1624 13.1844 17.2977 12.9609 17.3529L10.7812 17.7357C10.9687 17.7045 11.1609 17.7529 11.3124 17.8717C11.4672 17.9917 11.5683 18.168 11.5937 18.3623C11.6252 18.5501 11.586 18.7429 11.4835 18.9034C11.381 19.0639 11.2226 19.1806 11.039 19.231C11.2421 19.3404 11.3827 19.5435 11.4202 19.7779C11.4483 19.9538 11.4166 20.134 11.3302 20.2898C11.2438 20.4456 11.1077 20.5679 10.9437 20.6373C11.0579 20.789 11.1114 20.9779 11.0937 21.167C11.078 21.3467 11.0025 21.5159 10.8793 21.6476C10.756 21.7793 10.5922 21.8659 10.414 21.8935L10.1609 21.9498C9.50461 22.0982 8.84211 22.2123 8.17492 22.2904C7.41556 22.3013 6.6569 22.2396 5.9093 22.106C5.65329 22.047 5.41707 21.9223 5.22392 21.7442C5.03076 21.5661 4.88732 21.3408 4.80773 21.0904C4.31242 20.0201 4.04211 17.9951 4.64836 17.4092C5.25617 16.8248 5.15148 16.9717 5.44211 16.2763C5.73117 15.5826 6.03586 14.7857 6.26711 14.1045C6.49836 13.4263 6.58898 13.1201 7.01867 12.9545C7.22914 12.8915 7.45563 12.9105 7.6527 13.0076C7.84977 13.1047 8.00283 13.2727 8.08117 13.4779C8.14367 13.9498 8.12336 14.431 8.01555 14.8935C7.90617 15.8373 7.90305 16.0545 8.01555 16.3763'
          fill='#F9F0F8'
        />
        <path
          fillRule='evenodd'
          clip-rule='evenodd'
          d='M12.6718 15.5779C12.7849 15.5572 12.9012 15.5603 13.0131 15.5869C13.125 15.6136 13.2301 15.6632 13.3218 15.7326C13.5093 15.8732 13.6343 16.0857 13.6687 16.3232C13.6972 16.5517 13.6409 16.7828 13.5104 16.9726C13.38 17.1624 13.1844 17.2977 12.9609 17.3529L10.7812 17.7357C10.9687 17.7045 11.1609 17.7529 11.3124 17.8717C11.4672 17.9917 11.5683 18.168 11.5937 18.3623C11.6252 18.5501 11.586 18.7429 11.4835 18.9034C11.381 19.0639 11.2226 19.1806 11.039 19.231C11.2421 19.3404 11.3827 19.5435 11.4202 19.7779C11.4483 19.9538 11.4166 20.134 11.3302 20.2898C11.2438 20.4456 11.1077 20.5679 10.9437 20.6373C11.0579 20.789 11.1114 20.9779 11.0937 21.167C11.078 21.3467 11.0025 21.5159 10.8793 21.6476C10.756 21.7793 10.5922 21.8659 10.414 21.8935L10.1609 21.9498C9.50461 22.0982 8.84211 22.2123 8.17492 22.2904C7.41556 22.3013 6.6569 22.2396 5.9093 22.106C5.65329 22.047 5.41707 21.9223 5.22392 21.7442C5.03076 21.5661 4.88732 21.3408 4.80773 21.0904C4.31242 20.0201 4.04211 17.9951 4.64836 17.4092C5.25617 16.8248 5.15148 16.9717 5.44211 16.2763C5.73117 15.5826 6.03586 14.7857 6.26711 14.1045C6.49836 13.4263 6.58898 13.1201 7.01867 12.9545C7.22914 12.8915 7.45563 12.9105 7.6527 13.0076C7.84977 13.1047 8.00283 13.2727 8.08117 13.4779C8.14367 13.9498 8.12336 14.431 8.01555 14.8935C7.90617 15.8373 7.90305 16.0545 8.01555 16.3763L12.6718 15.5795V15.5779Z'
          stroke='#523951'
          strokeWidth='0.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
    </svg>
  )
}

export default FaceIcon
