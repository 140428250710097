const langsData = [
  'English',
  'Arabic',
  'Danish',
  'Dutch',
  'French',
  'German',
  'Greek',
  'Hindi',
  'Indonesian',
  'Italian',
  'Malay',
  'Norwegian',
  'Portuguese',
  'Russian',
  'Spanish',
  'Swedish',
  'Ukrainian',
]

export default langsData
