import {SubscriptionPlansEnum, SubscriptionCreditsLimitEnum} from '../types/types'

export const getCreditsLimitByPlanName = (name: string) => {
  switch (name) {
    case SubscriptionPlansEnum.STARTER_MONTHLY:
      return SubscriptionCreditsLimitEnum.STARTER
    case SubscriptionPlansEnum.PREMIUM_MONTHLY:
      return SubscriptionCreditsLimitEnum.PREMIUM
    case SubscriptionPlansEnum.STARTER_YEARLY:
      return SubscriptionCreditsLimitEnum.STARTER
    case SubscriptionPlansEnum.PREMIUM_YEARLY:
      return SubscriptionCreditsLimitEnum.PREMIUM
    case SubscriptionPlansEnum.FREE:
      return SubscriptionCreditsLimitEnum.FREE
    case SubscriptionPlansEnum.APPSUMOTIER1:
      return SubscriptionCreditsLimitEnum.APPSUMOTIER1
    case SubscriptionPlansEnum.APPSUMOTIER2:
      return SubscriptionCreditsLimitEnum.APPSUMOTIER2
    case SubscriptionPlansEnum.APPSUMOTIER3:
      return SubscriptionCreditsLimitEnum.APPSUMOTIER3

    default:
      return SubscriptionCreditsLimitEnum.FREE
  }
}
