import {useState, type FC} from 'react'
import {Button, Table} from 'react-bootstrap'
import {GiHamburgerMenu} from 'react-icons/gi'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useAuth} from '../../../app/modules/auth'
import {
  useDeleteActivityMutation,
  useGetActivitiesQuery,
} from '../../../services/activityServiceApi'
import DelIcon from '../icons/homepage/DelIcon'
import EditIcon from '../icons/homepage/EditIcon'
import ConfirmationModal from '../modal-page/ConfirmationModal'
import classes from './RecentActivity.module.scss'
import {GlobalErrorMessagesEnum} from '../../../types/types'

interface RecentActivityAreaProps {}

const RecentActivityPage: FC<RecentActivityAreaProps> = () => {
  const {currentUser, toggleSidebar} = useAuth()
  const [page, setPage] = useState(1)
  const {data: {activities = [], hasMore = false} = {}, isLoading} = useGetActivitiesQuery({
    user_id: currentUser?.id,
    page,
  })
  const [deleteActivityId, setDeleteActivityId] = useState<string>('')
  const [deleteActivity] = useDeleteActivityMutation()
  if (isLoading) {
    return <div>Loading...</div>
  }

  const handleDelete = (uuid: string) => {
    deleteActivity(uuid)
      .unwrap()
      .then(() => {
        toast.success('Activity successfully deleted')
      })
      .catch(() => {
        toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
      })
  }
  const handleCancel = () => {
    setDeleteActivityId('')
  }
  const handleConfirm = () => {
    handleDelete(deleteActivityId)
    setDeleteActivityId('')
  }
  const rtf1 = new Intl.RelativeTimeFormat('en', {style: 'short'})
  const formatDate = (date: string) => {
    const dateToFormat = new Date(date)
    const daysAgo = rtf1.format(Math.floor((Date.now() - dateToFormat.getTime()) / 86400000), 'day')
    const hoursAgo = rtf1.format(
      Math.floor((Date.now() - dateToFormat.getTime()) / 3600000),
      'hour'
    )
    const minutesAgo = rtf1.format(
      Math.floor((Date.now() - dateToFormat.getTime()) / 60000),
      'minute'
    )
    const monthsAgo = rtf1.format(
      Math.floor((Date.now() - dateToFormat.getTime()) / 2628000000),
      'month'
    )
    const yearsAgo = rtf1.format(
      Math.floor((Date.now() - dateToFormat.getTime()) / 31536000000),
      'year'
    )
    const f1 =
      yearsAgo === 'in 0 yr.'
        ? monthsAgo === 'in 0 mo.'
          ? daysAgo === 'in 0 days'
            ? hoursAgo === 'in 0 hr.'
              ? minutesAgo
              : hoursAgo
            : daysAgo
          : monthsAgo
        : yearsAgo
    const f2 = f1.replace('in ', '').replace('.', '') + ' ago'
    const timeNum = parseInt(f2.split(' ')[0])
    const mf = timeNum > 1 ? 'minutes' : 'minute'
    const hf = timeNum > 1 ? 'hours' : 'hour'
    const monthf = timeNum > 1 ? 'months' : 'month'
    const yearf = timeNum > 1 ? 'years' : 'year'
    const f3 = f2.replace('hr', hf).replace('min', mf).replace('mo', monthf).replace('yr', yearf)
    return f3 === '0 minute ago' ? 'Just now' : f3
  }
  const tableRows = activities.map(
    (item: {
      id: number
      header: string
      title: string
      uuid: string
      updatedAt: string
      url: string
      questions: {
        requiredQuestion1: string
      }
    }) => {
      return (
        <tr
          key={item.id}
          style={{
            borderBottom: '1px solid #f1f1f1',
            height: '50px',
          }}
        >
          <td
            style={{
              width: '250px',
            }}
          >
            {item.questions.requiredQuestion1.slice(0, 20)}{' '}
            {item.questions.requiredQuestion1.length > 20 ? '...' : ''}
          </td>
          <td
            style={{
              width: '350px',
              textAlign: 'start',
            }}
          >
            {item.title}
          </td>
          <td>{item.header}</td>
          <td
            style={{
              width: '250px',
              textAlign: 'start',
            }}
          >
            {formatDate(item.updatedAt)}
          </td>
          <td
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '22px',
            }}
          >
            {item.header === 'Image Generation' ? (
              <Link to={`/${item.url}/?uuid=${item.uuid}`} title='Go to your activity'>
                <EditIcon className={classes.svg} />
              </Link>
            ) : (
              <Link
                to={`/${item.header.replace(' ', '-').toLowerCase()}/${item.url}/?uuid=${
                  item.uuid
                }`}
                title='Delete your activity'
              >
                <EditIcon className={classes.svg} />
              </Link>
            )}

            <p
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setDeleteActivityId(item.uuid)}
            >
              <DelIcon className={classes.svg} />
            </p>
          </td>
        </tr>
      )
    }
  )
  const handlePagination = (action: string) => {
    if (action === 'next') {
      setPage((prev) => prev + 1)
    } else {
      setPage((prev) => prev - 1)
    }
  }

  return (
    <div className={`${classes.recent} universalPage  container-fluid`}>
      <div className='d-flex justify-content-between align-items-center w-100 mb-4'>
        <div>
          <h1 className={classes.recent__title}>Recent Activity</h1>
          <p className={classes.recent__subHeader}>Show All Activity here</p>
        </div>
        {/* <p className={classes.subHeader}>Create a project for bulk run</p> */}
        <Button variant='dark' className={'navbar-toggler-btn '} onClick={() => toggleSidebar()}>
          <GiHamburgerMenu />
        </Button>
      </div>
      <div className='w-100'>
        <Table
          responsive
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0',
          }}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Workflow</th>
              <th>Category</th>
              <th>Last Opened</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
        <tfoot
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '22px',
            width: '100%',
          }}
          className='mt-3 mt-md-0'
        >
          {(hasMore || page > 1) && (
            <tr>
              <td colSpan={7}>
                <nav
                  className='w-100 bg-light  d-flex justify-content-center p-3'
                  style={{
                    borderRadius: '1rem',
                  }}
                >
                  <ul className='pagination pagination-lg'>
                    <li className='page-item' onClick={() => handlePagination('prev')}>
                      <button className='page-link' disabled={page === 1}>
                        <span aria-hidden='true'>&laquo;</span>
                      </button>
                    </li>
                    <li className='page-item'>
                      <button className='page-link'>{page}</button>
                    </li>
                    <li className='page-item' onClick={() => handlePagination('next')}>
                      <button className='page-link' disabled={!hasMore}>
                        <span aria-hidden='true'>&raquo;</span>
                      </button>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
          )}
        </tfoot>
        <ConfirmationModal
          header='Warning'
          message='Are you sure you want to delete this activity?'
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          show={deleteActivityId !== '' ? true : false}
        />
      </div>
    </div>
  )
}

export default RecentActivityPage
