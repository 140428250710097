import {useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {useGoogleLogin} from '@react-oauth/google'
import {googleAuthentication, emailToken} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {
  useConfirmEmailMutation,
  useLazyGetUserByTokenQuery,
  useLoginMutation,
} from '../../../../services/authServiceApi'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const {saveAuth, setCurrentUser} = useAuth()
  const location = useLocation()
  const [login, {isLoading, isError}] = useLoginMutation()
  const [getUserByToken] = useLazyGetUserByTokenQuery()
  const [confirmEmail] = useConfirmEmailMutation()
  const from = ((location.state as any)?.from.pathname as string) || '/profile'
  useEffect(() => {
    const emailAlert = document.querySelector('#kt_alert_verify_email')
    if (emailToken && emailToken !== null) {
      confirmEmail(emailToken)
    }
    if (localStorage.getItem('verify_email')) {
      emailAlert?.classList.remove('d-none')
      emailAlert?.classList.add('show')
    }
    localStorage.removeItem('verify_email')
    return () => {
      emailAlert?.classList.add('d-none')
    }
  }, [])
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        const payload = await login({email: values.email, password: values.password}).unwrap()
        saveAuth(payload)
        const user = await getUserByToken(payload.access_token).unwrap()
        setCurrentUser(user)
      } catch (err: any) {
        console.log(err.response.status)
        setStatus('The login details are incorrect')
        setSubmitting(false)
      }
    },
  })
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) =>
      googleAuthentication(codeResponse).then(async (res) => {
        if (codeResponse.access_token) {
          saveAuth(res?.data)
          const user = await getUserByToken(res?.data.access_token).unwrap()
          setCurrentUser(user)
        }
      }),
    onError: (error) => console.log('Login from Google Failed', error),
  })
  return (
    <div>
      <form className='' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
        <div className='text-center mb-5'>
          <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
          {/* <div className='text-gray-500 fw-semibold fs-6'>
            Start your 7-day free trial. Cancel anytime.
          </div> */}
        </div>

        <div className='row g-3 mb-5'>
          <div className='col-md-12'>
            <div
              className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
              onClick={(e) => {
                googleLogin()
              }}
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                className='h-15px me-3'
              />
              <span className='link-card'>Continue with Google</span>
            </div>
          </div>
        </div>
        <div className='separator separator-content mb-5'>
          <span className='w-125px text-gray-500 fw-semibold fs-7'>Or</span>
        </div>
        <div>
          {isError ? (
            <div style={{color: 'red'}}>The login details are incorrect! Please try again</div>
          ) : null}
        </div>

        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            id='email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <input
            placeholder='Password'
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          <Link to='/auth/forgot-password' className='link-card'>
            Forgot Password ?
          </Link>
        </div>

        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn bg-2468ff'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {/* {!isLoading && <span className='indicator-label text-white'>Continue</span>}
            {isLoading && (
              <span className='indicator-progress text-white' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )} */}
            {isLoading ? (
              <span className='indicator-progress text-white' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span className='indicator-label text-white'>Continue</span>
            )}
          </button>
        </div>

        <div className='text-gray-500 text-center fw-semibold fs-6'>
          Not a Member yet?{' '}
          <Link to='/auth/registration' className='link-card'>
            Sign up
          </Link>
        </div>
      </form>
    </div>
  )
}
