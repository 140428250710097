import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const SeparatorIcon: FC<Props> = ({className, width = '32', height = '32', color = '#565C65'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 3 3'
      fill='none'
    >
      <path
        d='M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5Z'
        fill={color}
      />
    </svg>
  )
}

export default SeparatorIcon
