import {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {requestPassword} from '../../../app/modules/auth/core/_requests'
import classes from './AuthStyles.module.scss'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {GlobalErrorMessagesEnum} from '../../../types/types'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please enter a valid email address'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [result, setResult] = useState<string | undefined>(undefined)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setError(undefined)
      setResult(undefined)
      requestPassword(values.email)
        .then(({data: {result}}) => {
          setError('')
          toast.success(
            'We have sent you an email to confirm you password reset. Please check your inbox.'
          )
          setLoading(false)
          setResult('true')
        })
        .catch((err) => {
          setLoading(false)
          setSubmitting(false)
          if (err.response.status === 400) {
            toast.error(err.response.data.message)
            return
          }
          toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
          // setStatus(err.response.data.message)
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  return (
    <div className='appAuth'>
      <div className={classes.auth}>
        <div className={classes.auth__topBlock}>
          <h1 className={classes.auth__topBlock__title}>Forgot password</h1>
          <span className={classes.auth__topBlock__subTitle}>
            Enter your email to reset your password.
          </span>
        </div>
        {/* {result && result.trim() !== '' ? (
          <span className={classes.messageSuccess}>
            We have sent you an email to confirm you password reset. Please check your inbox
          </span>
        ) : null}
        {error ? (
          <span className={classes.messageError}>
            {error && error.trim() !== ''
              ? error
              : 'Sorry, looks like there are some errors detected, please try again.'}
          </span>
        ) : null} */}

        <form className={classes.auth__form} onSubmit={formik.handleSubmit} noValidate>
          <label className={classes.auth__form__label}>
            <h4 className={classes.auth__form__label__title}>Email</h4>
            <input
              type='Email'
              placeholder='my-email@mail.com'
              required={true}
              {...formik.getFieldProps('email')}
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <span className={classes.messageError} role='alert'>
                {formik.errors.email}
              </span>
            )}
          </label>
          <div className={classes.auth__form__linkBlock}>
            <Link to='/auth'>Back to Login</Link>
          </div>
          <button type='submit' className='universalButton' disabled={loading || !formik.isValid}>
            {loading ? <>Please wait...</> : <>Continue</>}
          </button>
        </form>
      </div>
    </div>
  )
}
