import {useState} from 'react'
import {useAuth} from '../../../app/modules/auth'
import {useSetCompanyMutation, useUpdateUserMutation} from '../../../services/userServiceApi'
import {useLazyGetUserByTokenQuery} from '../../../services/authServiceApi'
import classes from './CompanyName.module.scss'

const CompanyName: React.FC = () => {
  const {currentUser, setCurrentUser, auth} = useAuth()
  const [updateUser] = useUpdateUserMutation()
  const [setCompanyMutation] = useSetCompanyMutation()
  const [getUserByToken] = useLazyGetUserByTokenQuery()

  const [company, setCompany] = useState('')

  const handleInput = (e: any) => {
    const value = e.target.value
    setCompany(value)
  }

  const handleForm = async (e: any) => {
    e.preventDefault()
    if (company.trim() !== '') {
      await setCompanyMutation({id: currentUser?.id, company_name: company}).unwrap()
      const user = await getUserByToken(auth?.access_token).unwrap()
      setCurrentUser(user)
    }
  }

  return (
    <div className={`${classes.company}`}>
      <form className={classes.company__form} onSubmit={handleForm}>
        <h1 className={classes.company__form__title}>
          Please, specify the company name to proceed.
        </h1>
        <label className={classes.company__form__label}>
          <input type='text' placeholder='Company name' value={company} onChange={handleInput} />
        </label>
        <button className={classes.company__form__btn}>Send</button>
      </form>
    </div>
  )
}
export {CompanyName}
