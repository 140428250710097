import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const Cube3dIcon: FC<Props> = ({className, width = '24', height = '24', color = '#292D32'}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 12.2501C11.56 12.2501 11.12 12.1401 10.72 11.9301L4.21 8.4201C3.62 8.1001 3.25 7.4701 3.25 6.7701C3.25 6.0701 3.62 5.4401 4.21 5.1201L10.72 1.6101C11.52 1.1801 12.48 1.1801 13.27 1.6101L19.78 5.1201C20.37 5.4401 20.74 6.0701 20.74 6.7701C20.74 7.4701 20.37 8.1001 19.78 8.4201L13.27 11.9301C12.88 12.1501 12.44 12.2501 12 12.2501ZM12 2.7801C11.81 2.7801 11.61 2.8301 11.43 2.9301L4.93 6.4301C4.76 6.5201 4.75 6.7101 4.75 6.7701C4.75 6.8301 4.76 7.0201 4.93 7.1001L11.44 10.6101C11.8 10.8001 12.21 10.8001 12.57 10.6101L19.08 7.1001C19.24 7.0101 19.26 6.8201 19.26 6.7701C19.26 6.7101 19.25 6.5201 19.08 6.4401L12.57 2.9301C12.39 2.8301 12.19 2.7801 12 2.7801Z'
        fill={color}
      />
      <path
        d='M9.78 22.7198C9.5 22.7198 9.21 22.6498 8.95 22.5198L2.89 19.4998C1.88 18.9898 1.25 17.9698 1.25 16.8398V11.1198C1.25 10.4698 1.58 9.87977 2.13 9.53977C2.69 9.19977 3.36 9.16977 3.94 9.45977L10 12.4798C11.01 12.9898 11.64 13.9998 11.64 15.1398V20.8598C11.64 21.5098 11.31 22.0998 10.76 22.4398C10.46 22.6298 10.12 22.7198 9.78 22.7198ZM3.11 10.7598C3.03 10.7598 2.96 10.7898 2.92 10.8098C2.86 10.8498 2.75 10.9398 2.75 11.1198V16.8398C2.75 17.3998 3.06 17.9098 3.56 18.1598L9.61 21.1898C9.77 21.2698 9.9 21.2098 9.96 21.1798C10.02 21.1398 10.13 21.0498 10.13 20.8698V15.1498C10.13 14.5898 9.82 14.0798 9.32 13.8298L3.27 10.7998C3.21 10.7698 3.16 10.7598 3.11 10.7598Z'
        fill={color}
      />
      <path
        d='M14.2201 22.72C13.8801 22.72 13.5401 22.63 13.2401 22.44C12.6901 22.1 12.3601 21.51 12.3601 20.86V15.14C12.3601 14.01 12.9901 12.99 14.0001 12.48L20.0501 9.45C20.6301 9.16 21.3101 9.19 21.8601 9.53C22.4101 9.87 22.7401 10.46 22.7401 11.11V16.83C22.7401 17.96 22.1101 18.98 21.1001 19.49L15.0501 22.52C14.7901 22.66 14.5001 22.72 14.2201 22.72ZM20.8901 10.76C20.8401 10.76 20.7901 10.77 20.7301 10.8L14.6801 13.83C14.1801 14.08 13.8701 14.58 13.8701 15.15V20.87C13.8701 21.05 13.9801 21.14 14.0401 21.18C14.1001 21.22 14.2301 21.27 14.3901 21.19L20.4401 18.16C20.9401 17.91 21.2501 17.4 21.2501 16.84V11.12C21.2501 10.94 21.1401 10.85 21.0801 10.81C21.0401 10.79 20.9701 10.76 20.8901 10.76Z'
        fill={color}
      />
    </svg>
  )
}

export default Cube3dIcon
