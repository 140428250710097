import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const LeftArrowIcon: FC<Props> = ({className, width = '32', height = '32', color = '#292D32'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      className={className}
    >
      <path
        d='M16.4267 11.5067C16.68 11.5067 16.9333 11.6001 17.1333 11.8001C17.52 12.1867 17.52 12.8267 17.1333 13.2134L14.4267 15.9201L17.1333 18.6267C17.52 19.0134 17.52 19.6534 17.1333 20.0401C16.7467 20.4267 16.1067 20.4267 15.72 20.0401L12.3067 16.6267C11.92 16.2401 11.92 15.6001 12.3067 15.2134L15.72 11.8001C15.9067 11.6001 16.1733 11.5067 16.4267 11.5067Z'
        fill={color}
      />
      <path
        d='M13.1067 14.9199L26.6667 14.9199C27.2134 14.9199 27.6667 15.3733 27.6667 15.9199C27.6667 16.4666 27.2134 16.9199 26.6667 16.9199L13.1067 16.9199C12.5601 16.9199 12.1067 16.4666 12.1067 15.9199C12.1067 15.3733 12.5601 14.9199 13.1067 14.9199Z'
        fill={color}
      />
      <path
        d='M16 4.33341C16.5467 4.33341 17 4.78675 17 5.33341C17 5.88008 16.5467 6.33341 16 6.33341C10.3067 6.33341 6.33333 10.3067 6.33333 16.0001C6.33333 21.6934 10.3067 25.6667 16 25.6667C16.5467 25.6667 17 26.1201 17 26.6667C17 27.2134 16.5467 27.6667 16 27.6667C9.13333 27.6667 4.33333 22.8667 4.33333 16.0001C4.33333 9.13341 9.13334 4.33341 16 4.33341Z'
        fill={color}
      />
    </svg>
  )
}

export default LeftArrowIcon
