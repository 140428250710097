import {useState, type FC} from 'react'
import classes from './TwitterPreview.module.scss'
import {useAuth} from '../../../../app/modules/auth'
import SeparatorIcon from '../../icons/linkedin/SeparatorIcon'
import SelectArrowIcon from '../../icons/modalform/SelectArrowIcon'
import CommentIcon from '../../icons/twitter/CommentIcon'
import RetweetIcon from '../../icons/twitter/RetweetIcon'
import HeartIcon from '../../icons/instagram/HeartIcon'
import ShareIcon from '../../icons/twitter/ShareIcon'

interface TwitterPreviewProps {
  images?: string[]
  text?: string
}

const TwitterPreview: FC<TwitterPreviewProps> = ({images = [], text = ''}) => {
  const {currentUser} = useAuth()
  const [sliderPosition, setSliderPosition] = useState(0)
  const shortedText = text.length <= 250 ? text : text.split(' ').slice(0, 50).join(' ') + ' [...]'

  const handleSlider = (toLeft = true) => {
    const direction = toLeft ? -100 : 100
    setSliderPosition((prev) => prev + direction)
  }

  const imagesBlock = images.map((item, index) => (
    <div key={index} className={classes.twitter__rightBlock__slider__central__stripe__imgBlock}>
      <img src={item} alt='generated images' />
    </div>
  ))

  return (
    <div className={classes.twitter}>
      <div className={classes.twitter__leftBlock}>
        <div className={classes.twitter__leftBlock__imgBlock}>
          <img src={`${currentUser?.avatar || '/new-design/image/avatar.png'}`} alt='user' />
        </div>
      </div>

      <div className={classes.twitter__rightBlock}>
        <div className={classes.twitter__rightBlock__info}>
          <h3 className={classes.twitter__rightBlock__info__title}>
            {currentUser?.first_name} {currentUser?.last_name}
          </h3>
          <span className={classes.twitter__rightBlock__info__nickname}>
            @{currentUser?.first_name}{' '}
            <SeparatorIcon className={classes.twitter__rightBlock__info__nickname__separatorSvg} />{' '}
            23s
          </span>
        </div>

        <div className={classes.twitter__rightBlock__text}>
          <p>{shortedText}</p>
        </div>

        <div className={classes.twitter__rightBlock__slider}>
          <div className={classes.twitter__rightBlock__slider__central}>
            <button
              className={classes.twitter__rightBlock__slider__central__leftBtn}
              onClick={() => handleSlider(false)}
              disabled={sliderPosition >= 0}
            >
              <SelectArrowIcon className={classes.twitter__rightBlock__slider__leftBtn__svg} />
            </button>
            <button
              className={classes.twitter__rightBlock__slider__central__rightBtn}
              onClick={() => handleSlider()}
              disabled={sliderPosition <= -100 * (images.length - 1)}
            >
              <SelectArrowIcon className={classes.twitter__rightBlock__slider__rightBtn__svg} />
            </button>
            <div
              className={classes.twitter__rightBlock__slider__central__stripe}
              style={{left: `${sliderPosition}%`, width: 100 * images.length + '%'}}
            >
              {imagesBlock}
            </div>
          </div>
        </div>
        <div className={classes.twitter__rightBlock__bottom}>
          <span className={classes.twitter__rightBlock__bottom__item}>
            <CommentIcon className={classes.twitter__rightBlock__bottom__item__svg} /> 61
          </span>
          <span className={classes.twitter__rightBlock__bottom__item}>
            <RetweetIcon className={classes.twitter__rightBlock__bottom__item__svg} /> 12
          </span>
          <span className={classes.twitter__rightBlock__bottom__item}>
            <HeartIcon className={classes.twitter__rightBlock__bottom__item__svg} /> 6.2K
          </span>
          <span className={classes.twitter__rightBlock__bottom__item}>
            <ShareIcon className={classes.twitter__rightBlock__bottom__item__svg} /> 61
          </span>
        </div>
        <span className={classes.twitter__rightBlock__showThread}>Show this thread</span>
      </div>
    </div>
  )
}

export default TwitterPreview
