import {useState} from 'react'
import {Tooltip} from 'react-tooltip'
import classes from './Tooltip.module.scss'

type tooltipProps = {
  tooltipText: string
  tooltipAnchor: string
}
const CustomTooltip: React.FC<tooltipProps> = ({tooltipText, tooltipAnchor}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div
        className={classes.tooltip_icon}
        id={tooltipAnchor}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        ?
      </div>
      <Tooltip
        place='right-end'
        anchorSelect={`#${tooltipAnchor}`}
        className={`${isOpen ? classes.tooltip_active : classes.tooltip_hidden} ${
          classes.tooltip
        } `}
      >
        <div className='w-200px'>{tooltipText}</div>
      </Tooltip>
    </>
  )
}

export {CustomTooltip}
