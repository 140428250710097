import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const FlagIcon: FC<Props> = ({className, width = '32', height = '32', color = '#333333'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M19.4227 2.43066H4.53554C3.95068 2.43066 3.47217 2.86165 3.47217 3.39434V22.0576C3.47217 22.5903 3.81776 22.7259 4.24843 22.353L11.5484 16.048C11.7186 15.9027 12.0004 15.9027 12.1705 16.0431L19.7045 22.3676C20.1351 22.7307 20.4861 22.5903 20.4861 22.0576V3.39434C20.4861 2.86165 20.0129 2.43066 19.4227 2.43066ZM18.891 19.7138L13.2498 14.9778C12.8564 14.6485 12.3619 14.4838 11.8621 14.4838C11.3517 14.4838 10.8466 14.6533 10.4532 14.9923L5.06722 19.646V3.88344H18.891V19.7138Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.125 3.38424C3.125 2.63014 3.78962 2.0835 4.52178 2.0835H19.4366C20.1752 2.0835 20.8333 2.63116 20.8333 3.38424V22.0676C20.8333 22.386 20.7261 22.7396 20.3931 22.8719C20.0793 22.9966 19.753 22.8435 19.5072 22.6364C19.5071 22.6364 19.5073 22.6365 19.5072 22.6364L11.9618 16.3073C11.9615 16.3071 11.9613 16.307 11.9611 16.3068C11.9126 16.2676 11.8089 16.2684 11.7616 16.3082C11.7614 16.3083 11.7613 16.3085 11.7611 16.3086L4.44928 22.6189C4.20693 22.8286 3.88204 22.9909 3.56613 22.8697C3.22892 22.7402 3.125 22.382 3.125 22.0676V3.38424ZM4.52178 2.75556C4.08206 2.75556 3.78788 3.07183 3.78788 3.38424V22.0676C3.78788 22.1555 3.80082 22.2082 3.81138 22.2351C3.81555 22.234 3.82038 22.2326 3.82591 22.2307C3.86751 22.2167 3.93337 22.1818 4.019 22.1077L11.3329 15.7956L11.3343 15.7944C11.6278 15.5439 12.0876 15.5447 12.3801 15.786L12.3822 15.7877L19.9307 22.1194C20.0154 22.1908 20.0806 22.2239 20.122 22.237C20.1308 22.2398 20.1379 22.2415 20.1434 22.2425C20.1548 22.2177 20.1705 22.1634 20.1705 22.0676V3.38424C20.1705 3.0708 19.8805 2.75556 19.4366 2.75556H4.52178ZM20.1593 22.2439C20.1593 22.2439 20.159 22.244 20.1584 22.244L20.1593 22.2439ZM3.79008 22.2384C3.79009 22.2383 3.79039 22.2384 3.79098 22.2384L3.79008 22.2384ZM4.72301 3.53783H19.2353V20.4355L13.0413 15.2394C13.0413 15.2393 13.0414 15.2394 13.0413 15.2394C12.7111 14.9633 12.2917 14.8217 11.862 14.8217C11.4214 14.8217 10.9935 14.9679 10.6649 15.2508L4.72301 20.3809V3.53783ZM5.38589 4.2099V18.9259L10.2359 14.7385C10.6956 14.3427 11.2798 14.1496 11.862 14.1496C12.4335 14.1496 13.0051 14.3378 13.4632 14.7209L13.4635 14.7212L18.5724 19.0071V4.2099H5.38589Z'
        fill={color}
      />
    </svg>
  )
}

export default FlagIcon
