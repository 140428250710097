import {useState, type FC} from 'react'
import classes from './Resellers.module.scss'

import Sidebar from './components/Sidebar'
import ColorComponent from './components/ColorComponent'
import LogoComponent from './components/LogoComponent'
import InformationComponent from './components/InformationComponent'
import {useGetSettingQuery} from '../../../../../services/settingServiceApi'
import PlanComponent from './components/PlanComponent'
import {PlanTableComponent} from './components/PlanTableComponent'
import PaymentComponent from './components/TopUpComponent'
import TopUpComponent from './components/TopUpComponent'

const Resellers = () => {
  const DEFAULT_CELL = 'Information'
  const [activeCell, setActiveCell] = useState<string>(DEFAULT_CELL)
  const {data:response, isLoading, refetch} = useGetSettingQuery(undefined)
  

  const components: any = {
    Information: <InformationComponent data={response?.data} refetch={refetch} />,
    Color: <ColorComponent />,
    Logo: <LogoComponent  data={response?.data} refetch={refetch} />,
    Create: <PlanComponent />,
    List: <PlanTableComponent setActiveCell={setActiveCell} />,
    TopUp: <TopUpComponent />,
  }

  const componentInfo: any = {
    Information: {title: 'Information', description: 'Manage information for your app'},
    Logo: {title: 'Logo', description: 'Manage logo & favicon for your app'},
    Color: {title: 'Color Settings', description: 'Manage color scheme for your app'},
    Create: {title: 'Plan', description: 'Manage your subscription plan'},
    List: {title: 'Plan', description: 'List of your subscription plan'},
    TopUp: {title: 'TopUp', description: 'Purchase words, credits and images'},
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '3rem',
      }}
      className={classes.referral}
    >
      <div className='container-fluid'>
        <div className='row'>
          <Sidebar activeCell={activeCell} setActiveCell={setActiveCell} />
          <div className='col-md-10 p-4'>
            <h2 className={`mb-2 ${classes.white_label__title} `}>
              {componentInfo[activeCell].title}
            </h2>
            <p className='mb-5'>{componentInfo[activeCell].description}</p>

            {components[activeCell]}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resellers
