import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const InfoIcon: FC<Props> = ({className, width = '24', height = '24', color = '#616161'}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.0001 1.66666C5.40841 1.66666 1.66675 5.40832 1.66675 9.99999C1.66675 14.5917 5.40841 18.3333 10.0001 18.3333C14.5917 18.3333 18.3334 14.5917 18.3334 9.99999C18.3334 5.40832 14.5917 1.66666 10.0001 1.66666ZM9.37508 6.66666C9.37508 6.32499 9.65841 6.04166 10.0001 6.04166C10.3417 6.04166 10.6251 6.32499 10.6251 6.66666V10.8333C10.6251 11.175 10.3417 11.4583 10.0001 11.4583C9.65841 11.4583 9.37508 11.175 9.37508 10.8333V6.66666ZM10.7667 13.65C10.7251 13.7583 10.6667 13.8417 10.5917 13.925C10.5084 14 10.4167 14.0583 10.3167 14.1C10.2167 14.1417 10.1084 14.1667 10.0001 14.1667C9.89175 14.1667 9.78341 14.1417 9.68341 14.1C9.58341 14.0583 9.49175 14 9.40841 13.925C9.33341 13.8417 9.27508 13.7583 9.23341 13.65C9.19175 13.55 9.16675 13.4417 9.16675 13.3333C9.16675 13.225 9.19175 13.1167 9.23341 13.0167C9.27508 12.9167 9.33341 12.825 9.40841 12.7417C9.49175 12.6667 9.58341 12.6083 9.68341 12.5667C9.88341 12.4833 10.1167 12.4833 10.3167 12.5667C10.4167 12.6083 10.5084 12.6667 10.5917 12.7417C10.6667 12.825 10.7251 12.9167 10.7667 13.0167C10.8084 13.1167 10.8334 13.225 10.8334 13.3333C10.8334 13.4417 10.8084 13.55 10.7667 13.65Z'
        fill={color}
      />
    </svg>
  )
}

export default InfoIcon
