export const currencyOptions = [
    { code: 'USD', name: 'United States Dollar' },
    // { code: 'GBP', name: 'British Pound Sterling' },
    // { code: 'EUR', name: 'Euro' },
    // { code: 'JPY', name: 'Japanese Yen' },
    // { code: 'AUD', name: 'Australian Dollar' },
    // { code: 'CAD', name: 'Canadian Dollar' },
    // { code: 'CHF', name: 'Swiss Franc' },
    // { code: 'CNY', name: 'Chinese Yuan' },
    // { code: 'SEK', name: 'Swedish Krona' },
    // { code: 'NZD', name: 'New Zealand Dollar' },
    // { code: 'MXN', name: 'Mexican Peso' },
    // { code: 'SGD', name: 'Singapore Dollar' },
    // { code: 'HKD', name: 'Hong Kong Dollar' },
    // { code: 'NOK', name: 'Norwegian Krone' },
    // { code: 'KRW', name: 'South Korean Won' },
    // { code: 'RUB', name: 'Russian Ruble' },
    // { code: 'INR', name: 'Indian Rupee' },
    // { code: 'BDT', name: 'Bangladeshi Taka' },
    // { code: 'THB', name: 'Thai Baht' },
    // { code: 'ZAR', name: 'South African Rand' },
    // { code: 'PLN', name: 'Polish Zloty' },
    // { code: 'AED', name: 'United Arab Emirates Dirham' },
    // { code: 'MYR', name: 'Malaysian Ringgit' },
    // { code: 'KWD', name: 'Kuwaiti Dinar' },
    // { code: 'BHD', name: 'Bahraini Dinar' },
    // { code: 'OMR', name: 'Omani Rial' },
    // { code: 'QAR', name: 'Qatari Rial' },
];