import type {FC} from 'react'
import templatesData from '../../../data/real-estate/realEastateTemplateData'
import {useParams} from 'react-router-dom'
import BulkRun from '../../universal-components/bulk-run/BulkRun'

interface RealEstateBulkRunPageProps {}

const RealEstateBulkRunPage: FC<RealEstateBulkRunPageProps> = () => {
  const {templateUrl} = useParams()
  const templateItem: any = templatesData.templates.find((item: any) =>
    item.url.includes(templateUrl)
  )
  const title = templateItem.title

  return <BulkRun title={title} templateItem={templateItem} />
}

export default RealEstateBulkRunPage
