import type {FC} from 'react'
import {ApexOptions} from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import classes from './GraphicsArea.module.scss'

interface WordsUsedBlockProps {
  currentUsage?: number
  chartParentHeight: number
}

const WordsUsedBlock: FC<WordsUsedBlockProps> = ({currentUsage = 23, chartParentHeight}) => {
  const totalPercentage = 100

  const options: ApexOptions = {
    chart: {
      width: 200,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: -30,
        endAngle: 330,
      },
    },
    stroke: {
      show: true,
      width: 3,
      colors: ['#C6FF00'],
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
    },
    legend: {
      show: false,
      formatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
      },
    },
    title: {
      text: '',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],

    colors: ['#2468FF', '#282828'],
  }

  const series = [currentUsage, totalPercentage - currentUsage]

  return (
    <div
      id='chart'
      style={{
        marginTop: 12,
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type='donut'
        width={'100%'}
        height={chartParentHeight}
      />
    </div>
  )
}

export default WordsUsedBlock
