import type {FC} from 'react'
import marketingTemplatesData from '../../../data/marketing/marketingTemplatesData'
import {useParams} from 'react-router-dom'
import CombinedGeneration from '../../universal-components/combined-generation/CombinedGeneration'

interface MarketingTemplatePageProps {}

const MarketingTemplatePage: FC<MarketingTemplatePageProps> = () => {
  const {templateUrl} = useParams()
  const templateItem: any = marketingTemplatesData.templates.find((item: any) =>
    item.url.includes(templateUrl)
  )

  return <CombinedGeneration templateItem={templateItem} />
}

export default MarketingTemplatePage
