import {AuthModel} from './_models'
import {refresh} from './_requests'
import {toast} from 'react-toastify'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = async (auth: AuthModel): Promise<void> => {
  return new Promise(async (resolve) => {
    if (!localStorage) {
      resolve()
      return
    }

    try {
      const lsValue = JSON.stringify(auth)
      await new Promise((innerResolve) => {
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
        innerResolve(true)
      })

      resolve()
    } catch (error) {
      console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
      resolve()
    }
  })
}

const removeAuth = async () => {
  if (!localStorage) {
    return
  }

  try {
    await new Promise((resolve, reject) => {
      localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
      resolve(true) // or reject() if an error occurs, but in this case, we're resolving immediately after removing the item
    })
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

let MAX_ATTEMPT = 3
let CURRENT_ATTEMPT = 0
let isToastDisplayed = false

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'

  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.access_token) {
        config.headers = {
          ...config.headers,
        }
        if (!config.headers.Authorization && config.headers.Authorization !== '') {
          config.headers = {
            Authorization: `Bearer ${auth.access_token}`,
          }
        }
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const config = error?.config

      if (error?.response?.status === 401 && !config?.sent) {
        /* prevent infinite loops of network requests */
        if (CURRENT_ATTEMPT >= MAX_ATTEMPT) {
          const currentUrl = config?.url

          if (currentUrl.endsWith('/refresh')) {
            if (!isToastDisplayed) {
              toast.error('You session is expired, Please login again!')
            }
            isToastDisplayed = true
            await new Promise((resolve) => setTimeout(resolve, 3000))
            window.location.reload()
          }
        }
        CURRENT_ATTEMPT++

        config.sent = true
        const auth = await Promise.resolve(getAuth())

        /**
         * Addressing 401 Unauthorized events in 'api/*' entails a token refresh strategy.
         * when a 'refresh' operation itself returns a 401 status it arise potential infinite loops of network requests.
         * In cases of refresh token expiration, user logout is automatically initiated.
         */

        // await removeAuth().then((e)=>{
        //   console.log("Auth Removed");
        // })

        if (auth)
          await refresh(auth?.refresh_token).then(async (tokens: any) => {
            await setAuth(tokens.data)

            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${tokens.data?.access_token}`,
            }
          })
        return axios(config)
      }
      return Promise.reject(error)
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
