import {IImageFormData} from '../../../types/types'

const imageFormData: IImageFormData | any = {
  id: 1,
  axiosURL: 'generateImage',
  axiosHeader: '',
  requiredQuestions: [
    {
      id: 11,
      title: 'Describe the Image',
      type: 'input',
      placeholder: 'Description...',
      axiosParamName: 'prompt',
      tooltipText:
        'Describe the desired result of generation as detailed as possible for best experience',
      tooltipAnchor: 'prompt_mark',
    },
  ],
  additionalQuestions: [
    {
      id: 13,
      title: 'What is the purpose or goal of your blog?',
      type: 'input',
      placeholder: 'Enter...',
      axiosParamName: 'additionalQuest1',
      tooltipAnchor: 'goal_mark',
      tooltipText:
        'Are you aiming to educate your audience, showcase your expertise, drive website traffic, generate leads, or promote a specific product or service?',
    },
    {
      id: 14,
      title: 'Who is your target audience?',
      type: 'input',
      placeholder: 'Enter...',
      axiosParamName: 'additionalQuest2',
      tooltipAnchor: 'audience_mark',
      tooltipText:
        'Who are you trying to reach? What are their demographics, interests, and pain points?',
    },
  ],
  optionalSelect: [
    {
      id: 15,
      title: 'Type',
      options: ['photograph', 'digital art', 'artwork', '3d render'],
      axiosParamName: 'type',
      required: true,
    },
    {
      id: 16,
      title: 'Aesthetic',
      options: [
        'steampunk',
        'magical',
        'cyberpunk',
        'vintage',
        'kawaii',
        'fantasy',
        'cybernetic',
        'gothic',
        'vaporwave',
      ],
      axiosParamName: 'aesthetic',
      required: false,
    },
    // {
    //   id: 17,
    //   title: 'Artist',
    //   options: [
    //     'by Salvador Dali',
    //     'by Van Gogh',
    //     'by Picasso',
    //     'by da Vinci',
    //     'by Disney',
    //     'by Pixar',
    //     'by Artgerm',
    //     'by Ghibli',
    //   ],
    //   axiosParamName: 'artist',
    // required: false
    // },
    {
      id: 18,
      title: 'Mood and Lighting',
      options: [
        'light',
        'calm',
        'elegant',
        'serene',
        'dark',
        'stormy',
        'golden hour',
        'dramatic backlighting',
        'colorful lighting',
        'cinematic lighting',
      ],
      axiosParamName: 'mood',
      required: false,
    },

    {
      id: 19,
      title: 'Style',
      options: [
        'stencil art',
        'pencil drawing',
        'charcoal sketch',
        'political cartoon',
        'acrylic on canvas',
        'watercolor',
        'oil painting',
        'anime',
        'digital painting',
        'airbrush',
        'blueprint',
      ],
      axiosParamName: 'style',
      required: false,
    },
  ],
}

export default imageFormData
