import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import * as authHelper from '../app/modules/auth/core/AuthHelpers'
import {refreshBaseQuery} from './refreshBaseQuery'

const API_URL = process.env.REACT_APP_API_URL

export const activityServiceApi = createApi({
  reducerPath: 'activityServiceApi',
  baseQuery: refreshBaseQuery,
  tagTypes: ['Activities', 'Activity'],
  endpoints: (builder) => ({
    getActivities: builder.query({
      query: ({user_id, page}) => ({
        url: `/activity/all/${user_id}/?page=${page}`,
      }),
      providesTags: ['Activities'],
    }),

    createActivity: builder.mutation({
      query: (activity) => ({
        url: `/activity/`,
        method: 'POST',
        body: activity,
      }),
      invalidatesTags: ['Activities'],
    }),
    getActivity: builder.query({
      query: (uuid) => ({
        url: `/activity/${uuid}`,
      }),
      providesTags: ['Activity'],
    }),
    deleteActivity: builder.mutation({
      query: (uuid) => ({
        url: `/activity/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Activities'],
    }),
  }),
})

export const {
  useCreateActivityMutation,
  useGetActivitiesQuery,
  useLazyGetActivityQuery,
  useDeleteActivityMutation,
} = activityServiceApi
