import {useState, type FC} from 'react'
import classes from './InstagramPreview.module.scss'
import {useAuth} from '../../../../app/modules/auth'
import MenuIcon from '../../icons/navbar/MenuIcon'
import SelectArrowIcon from '../../icons/modalform/SelectArrowIcon'
import HeartIcon from '../../icons/instagram/HeartIcon'
import SendIcon from '../../icons/instagram/SendIcon'
import CommentsIcon from '../../icons/instagram/CommentsIcon'
import FlagIcon from '../../icons/instagram/FlagIcon'

interface InstagramPreviewProps {
  images?: string[]
  text?: string
}

const InstagramPreview: FC<InstagramPreviewProps> = ({images = [], text = ''}) => {
  const {currentUser} = useAuth()
  const [sliderPosition, setSliderPosition] = useState(0)
  const shortedText = text.length <= 250 ? text : text.split(' ').slice(0, 100).join(' ') + ' [...]'

  const handleSlider = (toLeft = true) => {
    const direction = toLeft ? -100 : 100
    setSliderPosition((prev) => prev + direction)
  }

  const imagesBlock = images.map((item, index) => (
    <div key={index} className={classes.instagram__slider__central__stripe__imgBlock}>
      <img src={item} alt='generated images' />
    </div>
  ))

  const activeSlidesBlock = images.map((item, index) => (
    <div
      key={index}
      className={`${classes.instagram__slider__bottom__centralBlock__item} ${
        index === sliderPosition / -100 ? classes.active : undefined
      }`}
    ></div>
  ))

  return (
    <div className={classes.instagram}>
      <div className={classes.instagram__top}>
        <div className={classes.instagram__top__leftBlock}>
          <div className={classes.instagram__top__leftBlock__imgBlock}>
            <img src={`${currentUser?.avatar || '/new-design/image/avatar.png'}`} alt='user' />
          </div>
          <div className={classes.instagram__top__leftBlock__info}>
            <h3 className={classes.instagram__top__leftBlock__info__title}>
              {currentUser?.first_name} {currentUser?.last_name}
            </h3>
            <p>Current location</p>
          </div>
        </div>
        <div className={classes.instagram__top__rightBlock}>
          <MenuIcon className={classes.instagram__top__rightBlock__svg} />
        </div>
      </div>

      <div className={classes.instagram__slider}>
        <div className={classes.instagram__slider__central}>
          <button
            className={classes.instagram__slider__central__leftBtn}
            onClick={() => handleSlider(false)}
            disabled={sliderPosition >= 0}
          >
            <SelectArrowIcon className={classes.instagram__slider__leftBtn__svg} />
          </button>
          <button
            className={classes.instagram__slider__central__rightBtn}
            onClick={() => handleSlider()}
            disabled={sliderPosition <= -100 * (images.length - 1)}
          >
            <SelectArrowIcon className={classes.instagram__slider__rightBtn__svg} />
          </button>
          <div
            className={classes.instagram__slider__central__stripe}
            style={{left: `${sliderPosition}%`, width: 100 * images.length + '%'}}
          >
            {imagesBlock}
          </div>
        </div>
        <div className={classes.instagram__slider__bottom}>
          <div className={classes.instagram__slider__bottom__leftBlock}>
            <HeartIcon className={classes.instagram__slider__bottom__leftBlock__svg} />
            <CommentsIcon className={classes.instagram__slider__bottom__leftBlock__svg} />
            <SendIcon className={classes.instagram__slider__bottom__leftBlock__svg} />
          </div>
          <div className={classes.instagram__slider__bottom__centralBlock}>{activeSlidesBlock}</div>
          <div className={classes.instagram__slider__bottom__rightBlock}>
            <FlagIcon className={classes.instagram__slider__bottom__rightBlock__svg} />
          </div>
        </div>
        <div className={classes.instagram__bottom}>
          <h3 className={classes.instagram__bottom__title}>
            Liked by <b>Your Fan</b> and <b>219 others</b>
          </h3>
          <p>{shortedText}</p>
          <span className={classes.instagram__bottom__viewComments}>View all 10 comments</span>
        </div>
      </div>
    </div>
  )
}

export default InstagramPreview
