import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const CloseIcon: FC<Props> = ({className, width = '24', height = '24', color = '#292D32'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      className={className}
    >
      <path
        d='M19.3306 20.5686L7.43109 8.66914C7.09285 8.33091 7.09285 7.76994 7.43109 7.43171C7.76932 7.09347 8.33029 7.09347 8.66852 7.43171L20.568 19.3312C20.9063 19.6694 20.9063 20.2304 20.568 20.5686C20.2298 20.9069 19.6688 20.9069 19.3306 20.5686Z'
        fill={color}
      />
      <path
        d='M7.43198 20.5686C7.09375 20.2304 7.09375 19.6694 7.43198 19.3312L19.3315 7.43171C19.6697 7.09347 20.2307 7.09347 20.5689 7.43171C20.9071 7.76994 20.9071 8.33091 20.5689 8.66914L8.66942 20.5686C8.33118 20.9069 7.77021 20.9069 7.43198 20.5686Z'
        fill={color}
      />
    </svg>
  )
}

export default CloseIcon
