import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const DownloadIcon: FC<Props> = ({className, width = '32', height = '32', color = '#FFF'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 28 28'
      fill='none'
    >
      <path
        d='M17.8033 25.9583H10.1966C4.46831 25.9583 2.01831 23.5083 2.01831 17.78V17.6283C2.01831 12.4483 4.05998 9.95166 8.63331 9.51999C9.09998 9.48499 9.54331 9.83499 9.58998 10.3133C9.63664 10.7917 9.28664 11.2233 8.79664 11.27C5.13331 11.6083 3.76831 13.335 3.76831 17.64V17.7917C3.76831 22.54 5.44831 24.22 10.1966 24.22H17.8033C22.5516 24.22 24.2316 22.54 24.2316 17.7917V17.64C24.2316 13.3117 22.8433 11.585 19.11 11.27C18.6316 11.2233 18.27 10.8033 18.3166 10.325C18.3633 9.84666 18.7716 9.48499 19.2616 9.53166C23.905 9.92832 25.9816 12.4367 25.9816 17.6517V17.8033C25.9816 23.5083 23.5316 25.9583 17.8033 25.9583Z'
        fill='white'
      />
      <path
        d='M14 18.2352C13.5217 18.2352 13.125 17.8385 13.125 17.3602V2.3335C13.125 1.85516 13.5217 1.4585 14 1.4585C14.4783 1.4585 14.875 1.85516 14.875 2.3335V17.3602C14.875 17.8502 14.4783 18.2352 14 18.2352Z'
        fill='white'
      />
      <path
        d='M13.9999 19.5416C13.7782 19.5416 13.5566 19.46 13.3816 19.285L9.47323 15.3766C9.1349 15.0383 9.1349 14.4783 9.47323 14.14C9.81157 13.8016 10.3716 13.8016 10.7099 14.14L13.9999 17.43L17.2899 14.14C17.6282 13.8016 18.1882 13.8016 18.5266 14.14C18.8649 14.4783 18.8649 15.0383 18.5266 15.3766L14.6182 19.285C14.4432 19.46 14.2216 19.5416 13.9999 19.5416Z'
        fill='white'
      />
    </svg>
  )
}

export default DownloadIcon
