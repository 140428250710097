import {useState, type FC} from 'react'
import classes from './BulkRunEditor.module.scss'
import MyEditor from '../my-editor/MyEditor'
import {AnswerToEdit} from '../../../../types/types'
import CopyIcon from '../../icons/chat/CopyIcon'
import CheckmarkIcon from '../../icons/modalform/CheckmarkIcon'
import SaveIcon from '../../icons/chat/SaveIcon'
import {toast} from 'react-toastify'

interface BulkRunEditorProps {
  answerToEdit: AnswerToEdit
  setCurrentAnswer?: Function
}

const BulkRunEditor: FC<BulkRunEditorProps> = ({answerToEdit, setCurrentAnswer = () => {}}) => {
  const [triggerCopyEditor, setTriggerCopyEditor] = useState<number | string>('')
  const [triggerSaveEditing, setTriggerSaveEditing] = useState<number | string>('')

  const handleTriggerCopyEditor = () => {
    if (answerToEdit.text === '....') {
      toast.warning('No content to copy!')
      return
    }

    toast.success('Content copied successfully')
    setTriggerCopyEditor(Date.now())
  }

  const handleTriggerSaveEditing = () => {
    toast.success('Changes successfully saved in the table')
    setTriggerSaveEditing(Date.now())
  }

  return (
    <div className={classes.editor}>
      <div className={classes.editor__topBlock}>
        <h3 className={classes.editor__topBlock__title}>Editor</h3>
        <div className={classes.editor__topBlock__btnsBlock}>
          <button onClick={handleTriggerSaveEditing} title='Save changes'>
            <SaveIcon className={classes.editor__topBlock__btnsBlock__svg} />
          </button>
          <button onClick={handleTriggerCopyEditor} title='Copy to clipboard'>
            <CopyIcon className={classes.editor__topBlock__btnsBlock__svg} />
          </button>
        </div>
      </div>
      <MyEditor
        answerToEdit={answerToEdit}
        triggerCopy={triggerCopyEditor}
        triggerSave={triggerSaveEditing}
        setCurrentAnswer={setCurrentAnswer}
        isReplacePrevAnswers={true}
      />
    </div>
  )
}

export default BulkRunEditor
