const departamentsData = [
  {
    id: 1,
    name: 'marketing',
    title: 'marketing',
  },
  {
    id: 2,
    name: 'sales',
    title: 'sales',
  },
  {
    id: 3,
    name: 'hr',
    title: 'human resource',
  },
  {
    id: 4,
    name: 'finance',
    title: 'finance',
  },
]

export default departamentsData
