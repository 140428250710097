import {Dropdown, Table} from 'react-bootstrap'
import {useGetPlanQuery, useGetPlansQuery} from '../../../../../../services/settingServiceApi'
import {BsPencil, BsThreeDotsVertical} from 'react-icons/bs'
import {Link, useLocation, useNavigate} from 'react-router-dom'

interface SidebarProps {
  setActiveCell: (title: string) => void
}

export const PlanTableComponent: React.FC<SidebarProps> = ({setActiveCell}) => {
  const navigate = useNavigate()
  const {data} = useGetPlansQuery({})

  // Create a separate constant for rendering table body
  const renderTableBody = () => {
    if (!data || data.length === 0) {
      return (
        <tr>
          <td style={{ textAlign: 'center' }} colSpan={11}>No plans available</td> {/* You can customize the message */}
        </tr>
      );
    }

    return (
      <>
        {data.map((plan: any) => (
          <tr key={plan.id}>
            <td>{plan.name}</td>
            <td>${(plan.amount / 100).toFixed(2)}</td> {/* Assuming amount is in cents */}
            <td>{plan.currency.toUpperCase()}</td>
            <td>{plan.interval}</td>
            <td>{plan.words}</td>
            <td>{plan.images}</td>
            <td>{plan.credits}</td>
            <td>{plan.users}</td>
            <td>{plan.trial_days}</td>
            <td>{plan.active ? 'Active' : 'Inactive'}</td>
            <td style={{ display: 'flex', justifyContent: 'center' }}>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  <BsThreeDotsVertical />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setActiveCell('Create');
                      navigate(`#plan?public_id=${plan.public_id}`);
                    }}
                  >
                    <BsPencil /> Edit
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className='w-100'>
      {/* <button>+ Add</button>
<button>Export</button> */}

<Table
  responsive
  style={{
    borderCollapse: 'collapse',
    borderSpacing: '0',
  }}
  striped
  bordered
>
  <thead>
    <tr>
      <th style={{ fontWeight: 'normal',textAlign: 'center' }}>Name</th>
      <th style={{ fontWeight: 'normal',textAlign: 'center' }}>Amount</th>
      <th style={{ fontWeight: 'normal',textAlign: 'center' }}>Currency</th>
      <th style={{ fontWeight: 'normal',textAlign: 'center' }}>Interval</th>
      <th style={{ fontWeight: 'normal',textAlign: 'center' }}>Words</th>
      <th style={{ fontWeight: 'normal',textAlign: 'center' }}>Images</th>
      <th style={{ fontWeight: 'normal',textAlign: 'center' }}>Credits</th>
      <th style={{ fontWeight: 'normal',textAlign: 'center' }}>Users</th>
      <th style={{ fontWeight: 'normal',textAlign: 'center' }}>Trial Days</th>
      <th style={{ fontWeight: 'normal',textAlign: 'center' }}>Status</th>
      <th style={{ fontWeight: 'normal',textAlign: 'center' }}>Action</th>
    </tr>
  </thead>
  <tbody>{renderTableBody()}</tbody>
</Table>

    </div>
  )
}
