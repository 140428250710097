import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const LikeIcon: FC<Props> = ({className, width = '32', height = '32', color = '#65676B'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 16 17'
      fill='none'
    >
      <path
        d='M11.7583 6.23827C11.2523 6.24191 10.776 6.28134 10.3784 6.32902C10.429 6.19615 10.4785 6.06051 10.5192 5.93409C10.5684 5.78092 10.6332 5.55905 10.6332 5.35662C10.6332 4.93444 10.5611 4.17611 10.3916 3.46672C10.3068 3.11134 10.1912 2.74117 10.0334 2.42212C9.88519 2.12251 9.651 1.76707 9.27553 1.58018C8.98156 1.43346 8.69964 1.35006 8.31074 1.35006C7.74473 1.35006 7.29115 1.5587 7.04878 1.80359C6.9017 1.94999 6.85288 2.12152 6.83251 2.22735C6.81194 2.33421 6.80626 2.45258 6.80513 2.5709C6.80282 2.81447 6.8203 3.20265 6.85994 3.82674L6.8606 3.83709L6.86158 3.84742L6.99183 5.2094C6.98529 5.32297 6.93185 5.49558 6.78503 5.75939C6.65836 5.98698 6.4979 6.22284 6.31103 6.49753C6.26783 6.56102 6.22323 6.62659 6.1773 6.69461C5.77394 7.292 5.26022 8.08039 5.15773 8.98225C5.11135 8.69348 4.97926 8.42179 4.77305 8.20717C4.51121 7.93464 4.1504 7.77646 3.76842 7.77646H2.75789C2.37592 7.77646 2.01511 7.93464 1.75327 8.20717C1.49227 8.47881 1.35 8.84189 1.35 9.21529V14.2112C1.35 14.5846 1.49227 14.9477 1.75326 15.2193C2.01511 15.4918 2.37592 15.65 2.75789 15.65H3.76842C4.1504 15.65 4.51121 15.4918 4.77305 15.2193C5.03404 14.9477 5.17632 14.5846 5.17632 14.2112V12.7134C5.37707 13.6342 6.37817 14.3353 7.68421 14.3353H11.3474C11.8082 14.3353 12.2026 14.3019 12.5285 14.1575C12.9012 13.9923 13.0971 13.7237 13.2405 13.4857L13.2406 13.4857L13.2439 13.48C13.3899 13.2321 13.4663 12.9644 13.4486 12.6817C13.4478 12.6701 13.447 12.6586 13.4459 12.6471C13.7748 12.3936 14.0505 12.0203 14.1487 11.5667C14.1926 11.3676 14.1989 11.1632 14.1687 10.9644C14.2269 10.9089 14.2835 10.8466 14.3364 10.7763C14.5439 10.5001 14.65 10.1592 14.65 9.75695C14.65 9.39693 14.5845 9.08907 14.4264 8.82717C14.4175 8.81246 14.4085 8.79811 14.3993 8.78411C14.501 8.59633 14.6222 8.31763 14.6222 7.99524C14.6222 7.66529 14.5364 7.35274 14.3459 7.08381C14.1591 6.82015 13.9032 6.64263 13.6336 6.52312C13.1164 6.29384 12.4474 6.23421 11.7591 6.23826L11.7583 6.23827Z'
        stroke={color}
        strokeWidth='1.3'
      />
    </svg>
  )
}

export default LikeIcon
