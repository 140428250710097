import type {FC} from 'react'
import templatesData from '../../data/finance/financeTemplatesData'
import CombinedWorkflow from '../universal-components/combined-workflow/CombinedWorkflow'

interface FinancePageProps {}

const FinancePage: FC<FinancePageProps> = () => {
  const title = 'Finance'
  const buttonColor = 'var(--finance-workflow-btn-color)'

  return <CombinedWorkflow title={title} buttonColor={buttonColor} itemsData={templatesData} />
}

export default FinancePage
