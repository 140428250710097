import {type FC, useEffect, useRef, useState} from 'react'
import classes from './BulkRun.module.scss'
import RunIcon from '../../icons/workflow/RunIcon'
import BulkRunForm from './BulkRunForm'
import ExportCSVBtn from './ExportCSVBtn'
import ImportCSVBtn from './ImportCSVBtn'
import {useCheckGenerateLimitMutation} from '../../../../services/gptServiceApi'
import {useAuth} from '../../../../app/modules/auth'
import {LimitNotification} from '../limit-notification/LimitNotification'
import {UniversalNotification} from '../universal-notification/UniversalNotification'
import {toast} from 'react-toastify'
import ImportCSVHuman from '../../hr-page/bulk-run-page/ImportCSVHuman'
import {Button} from 'react-bootstrap'

interface BulkRunProps {
  title: string
  templateItem: any
  uploadCvs?: boolean
}

const BulkRun: FC<BulkRunProps> = ({title = '', templateItem, uploadCvs}) => {
  const {auth} = useAuth()
  const [rowsCount, setRowsCount] = useState<any[]>([0])
  const [inputsNames, setInputsNames] = useState<string[]>([])
  const [columnsOrder, setColumnsOrder] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const myRef = useRef<HTMLDivElement | null>(null)
  const [triggerRegenerateText, setTriggerRegenerateText] = useState<number | string>('')
  const [triggerRegenerateImg, setTriggerRegenerateImg] = useState<number | string>('')
  const [checkGenerateLimit] = useCheckGenerateLimitMutation()
  const [error, setError] = useState('')
  const [notification, setNotification] = useState('')
  const [currentWorkflowRowsLimit, setCurrentWorkflowRowsLimit] = useState(0)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [modalShow, setModalShow] = useState(false)
  const addFormRow = () => {
    const maximumAllowedRows = rowsCount.length + 1
    const isLimitExceeded = currentWorkflowRowsLimit < maximumAllowedRows

    if (isLimitExceeded) {
      toast.warning(maximumRowsToastWarning)
      return
    }
    setRowsCount((prev: any) => [...prev, Date.now()])
  }

  const addFileCSV = (fileData: any[]) => {
    if (!fileData || fileData.length === 0) return
    setRowsCount((prev: any[]) => {
      return [...prev, ...fileData]
    })
  }
  const deleteLast = () => {
    if (rowsCount.length > 1) {
      const newRow = rowsCount.filter((item, index) => index !== rowsCount.length - 1)
      setRowsCount(newRow)
    }
  }

  const maximumRowsToastWarning = () => (
    <div>
      <p>Maximum rows for this workflow: {currentWorkflowRowsLimit}</p>
      <a className='text-primary' href='/upgrade-plan'>
        Click here to upgrade!
      </a>
    </div>
  )

  const checkLimits = async () => {
    const checkRows: any = await checkGenerateLimit({
      limits: {
        words: templateItem.limits.words,
        credits: templateItem.limits.credits ? templateItem.limits.credits : 0,
      },
      rows: 99999,
      token: auth?.access_token,
    })

    const maximumRows = checkRows.error.data.message.replace(/[^0-9]/g, '')
    setCurrentWorkflowRowsLimit(maximumRows)

    // setNotification(
    //   `Maximum rows for this workflow: ${maximumRows}` ||
    //     'An error occured'
    // )
  }

  const getInputsNames = () => {
    const newInputsNames: string[] = []
    let rows = myRef?.current?.querySelectorAll('tr:not(:has(th))')
    const result = [].slice.call(rows).map(function (row: HTMLTableRowElement) {
      const cells = row.querySelectorAll('td')
      return [].slice.call(cells).map((cell: HTMLTableCellElement) => {
        const textArea = cell.querySelector('textarea')
        const select = cell.querySelector('select')
        // const div = cell.querySelector('[class*=text]')
        textArea && newInputsNames.push(textArea.name)
        select && newInputsNames.push(select.name)
      })
    })
    setInputsNames(newInputsNames)
  }

  const handleRunAll = async () => {
    try {
      const checkRows: any = await checkGenerateLimit({
        limits: {
          words: templateItem.limits.words,
          credits: templateItem.limits.credits ? templateItem.limits.credits : 0,
        },
        rows: rowsCount.length,
        token: auth?.access_token,
      })
      if (!checkRows.error) {
        // Resume from where it was left off, without starting from the beginning.
        if (activeIndex != null && activeIndex !== 0) {
          if (activeIndex === rowsCount.length) {
            setActiveIndex(0)
          } else {
            setActiveIndex(activeIndex + 1)
          }
        } else {
          setActiveIndex(0)
        }

        // setActiveIndex(0);
        //setTriggerRegenerateText(Date.now())
        return
      }
      toast.warning(checkRows.error.data.message || 'An error occured')
      //setError(checkRows.error.data.message || 'An error occured')
    } catch (err: any) {}
  }

  const tablesBlock = rowsCount.map((item: number | [], index: number) => {
    return (
      <div key={index} className={classes.bulk__table__item}>
        {
          <BulkRunForm
            templateItem={templateItem}
            triggerRegenerateText={triggerRegenerateText}
            triggerRegenerateImg={triggerRegenerateImg}
            csvInputs={item}
            columnsOrder={columnsOrder}
            inputsNames={inputsNames}
            index={index}
            setActiveIndex={setActiveIndex}
            activeIndex={activeIndex}
          />
        }
      </div>
    )
  })

  /* Disable restricted routes */
  const disableImportCSVBtnForNonCSVWorkflows = () => {
    const restrictedRoutes = [
      'pdf-comparer',
      'media-caption',
      'visual-prod-description',
      'screen-custom',
    ]

    return restrictedRoutes.some((route) => window.location.pathname.includes(route))
  }

  useEffect(() => {
    checkLimits()
  }, [currentWorkflowRowsLimit])

  useEffect(() => {
    getInputsNames()
  }, [])

  return (
    <div className={`universalPage ${classes.bulk}`}>
      <div className='universalTopBlock'>
        <h1 className='universalTopTitle'>{title}</h1>
        <div className='universalBreadcrumbs'>
          <span>Bulk Run</span>
          <span className='universalBreadcrumbs__lastChild'>(beta)</span>
        </div>
      </div>
      {error.trim() !== '' ? <LimitNotification errorMessage={error} /> : null}
      {notification.trim() !== '' ? (
        <UniversalNotification notificatonMessage={notification} upgrade={true} />
      ) : null}

      <div className={classes.bulk__btnsBlock}>
        {!disableImportCSVBtnForNonCSVWorkflows() && (
          <ImportCSVBtn
            currentWorkflowRowsLimit={currentWorkflowRowsLimit}
            myRef={myRef}
            setRowsCount={setRowsCount}
            setColumnsOrder={setColumnsOrder}
          />
        )}

        <ExportCSVBtn myRef={myRef} />
        <button className={classes.bulk__btnsBlock__runAll} onClick={handleRunAll}>
          <RunIcon color='white' /> Run all
        </button>
      </div>

      <div ref={myRef} className={classes.bulk__table}>
        <div className={classes.bulk__table__cont}>{tablesBlock}</div>
      </div>

      <div className={classes.bulk__addBtn}>
        <button
          onClick={() => {
            addFormRow()
          }}
        >
          + Add new row
        </button>
      </div>
      <div className={classes.bulk__addBtn}>
        <button
          onClick={() => {
            deleteLast()
          }}
        >
          Delete last row
        </button>
      </div>
    </div>
  )
}

export default BulkRun
