// apiSlice.js
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {refreshBaseQuery} from './refreshBaseQuery'

const API_URL = process.env.REACT_APP_API_URL

export const apiKeyService = createApi({
  reducerPath: 'api-key',
  baseQuery: refreshBaseQuery,
  tagTypes: ['ApiKey'],
  endpoints: (builder) => ({
    getApiKey: builder.query({
      query: (id) => ({
        url: `v1/api-key/${id}`,
      }),
      providesTags: ['ApiKey'],
      transformResponse: (response: any) => response.data,
    }),
    createApiKey: builder.mutation({
      query: (id) => ({
        url: '/v1/api-key',
        method: 'POST',
        body: {id},
      }),
      invalidatesTags: ['ApiKey'],
    }),
  }),
})

export const {useGetApiKeyQuery, useCreateApiKeyMutation} = apiKeyService
