const professions: string[] = [
  'CEO',
  'Founder',
  'Marketing Professional',
  'Sales Professional',
  'HR Professional',
  'Other',
]

export default professions
