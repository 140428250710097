import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Bar, Line} from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

interface ChartAreaProps {
  reports: any
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
}

const LineChart: React.FC<ChartAreaProps> = ({reports}) => {
  if (!reports.labels || !reports.datasets) {
    return null
  }

  return <Line data={reports} options={options} />
}

export default LineChart
