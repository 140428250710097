import OneLineIcon from '../../components/icons/workflow/OneLineIcon'
import ThreeLinesIcon from '../../components/icons/workflow/ThreeLinesIcon'
import toneData from '../universal-data/toneData'

const realEstateTemplateData = {
  workflows: [],
  templates: [
    {
      id: 1,
      header: 'Real estate',
      title: 'Image to Property Description',
      description: 'Create unique and compelling descriptions through property visuals',
      Img: ThreeLinesIcon,
      url: 'property-description',
      axiosURL: 'generatePropertyDescription',
      axiosHeader: 'propertyDescription',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 275,
        credits: 4,
      },
      requiredQuestions: [
        {
          id: 134,
          title: 'Upload Image',
          type: 'upload',
          placeholder: '+ Upload Image',
          axiosParamName: 'url',
        },

        {
          id: 135,
          title: 'Keywords',
          type: 'input',
          placeholder: 'Enter Keywords',
          axiosParamName: 'key_words',
          tooltipAnchor: 'url_mark',
          tooltipText: 'Enter keywords to be included in result',
          optional: true,
        },
      ],
      additionalQuestions: [],
      optionalSelect: [],
    },
    {
      id: 2,
      header: 'Real estate',
      title: 'Property Listing Generator',
      description: 'Create a world-class property listing that improves buyer/renter interest',
      Img: ThreeLinesIcon,
      url: 'property-listing-generator',
      axiosURL: 'propertyListingGenerator',
      axiosHeader: 'propertyDescription',
      relevanceRequest: false,
      easyRequest: true,
      imgGenerate: false,
      formType: 'regular',
      limits: {
        words: 369,
        credits: 14,
      },
      requiredQuestions: [
        {
          id: 95,
          title: 'Property address',
          type: 'input',
          placeholder: 'Property address',
          axiosParamName: 'address',
          tooltipAnchor: 'address',
          tooltipText: 'Enter the full property address including post code',
          formColumn: 6,
        },
        {
          id: 96,
          title: 'Number of Square feet',
          type: 'input',
          placeholder: 'Number of Square feet ',
          axiosParamName: 'sq_feet',
          tooltipAnchor: 'sq_feet',
          tooltipText: 'Enter the number of Square feet',
          formColumn: 6,
        },

        {
          id: 97,
          title: 'Additional property details',
          type: 'input',
          placeholder: 'Additional property details',
          axiosParamName: 'additional_details',
          tooltipAnchor: 'additional_details',
          tooltipText: 'Enter the additional property details',
          formColumn: 12,
        },
      ],
      additionalQuestions: [],
      optionalSelect: [
        {
          id: 14,
          title: 'Property type',
          options: ['Townhouse', 'House', 'Apartment', 'Unit', 'Vacant Land'],
          axiosParamName: 'property_type',
          formColumn: 6,
        },
        {
          id: 14,
          title: 'Transaction type',
          options: ['For rent', 'For sale'],
          axiosParamName: 'transaction_type',
          formColumn: 6,
        },
        {
          id: 14,
          title: 'Number of bedrooms',
          options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          axiosParamName: 'bedrooms',
          formColumn: 6,
        },
        {
          id: 14,
          title: 'Number of bathrooms',
          options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          axiosParamName: 'bathrooms',
          formColumn: 6,
        },
        {
          id: 14,
          title: 'Number of parking',
          options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          axiosParamName: 'parking',
          formColumn: 6,
        },
      ],
    },
  ],
}

export default realEstateTemplateData
