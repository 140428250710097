import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const CommentIcon: FC<Props> = ({className, width = '32', height = '32', color = '#707070'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 26 26'
      fill='none'
    >
      <path
        d='M4.61386 17.9765C3.40249 15.9344 2.97825 13.5204 3.42082 11.1876C3.86339 8.85489 5.14232 6.76396 7.01744 5.30745C8.89257 3.85094 11.2349 3.12905 13.6046 3.27734C15.9743 3.42563 18.2084 4.4339 19.8873 6.11281C21.5662 7.79172 22.5745 10.0258 22.7228 12.3955C22.8711 14.7652 22.1492 17.1076 20.6927 18.9827C19.2362 20.8578 17.1453 22.1368 14.8126 22.5793C12.4798 23.0219 10.0658 22.5977 8.02369 21.3863L8.02372 21.3862L4.65625 22.3484C4.51693 22.3882 4.3695 22.39 4.22923 22.3536C4.08896 22.3173 3.96097 22.2441 3.85851 22.1416C3.75605 22.0392 3.68286 21.9112 3.64651 21.7709C3.61017 21.6307 3.61199 21.4832 3.6518 21.3439L4.61393 17.9764L4.61386 17.9765Z'
        stroke={color}
        strokeWidth='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.75 11.375H16.25'
        stroke={color}
        strokeWidth='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.75 14.625H16.25'
        stroke={color}
        strokeWidth='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default CommentIcon
