import classes from './VerifyEmail.module.scss'

const VerifyEmail: React.FC = () => {
  return (
    <div className={classes.verify}>
      <p>To start exploring Klevere, please confirm your email address.</p>
      <p>A verification link has been sent to your inbox.</p>
    </div>
  )
}
export {VerifyEmail}
