import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const LikeIcon: FC<Props> = ({className, width = '32', height = '32', color = '#707070'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 26 26'
      fill='none'
    >
      <path
        d='M22.7505 10.5625H17.8755V21.125H22.7505C22.966 21.125 23.1726 21.0394 23.325 20.887C23.4774 20.7347 23.563 20.528 23.563 20.3125V11.375C23.563 11.1595 23.4774 10.9528 23.325 10.8005C23.1726 10.6481 22.966 10.5625 22.7505 10.5625V10.5625Z'
        stroke={color}
        strokeWidth='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.8755 10.5625L13.813 2.4375C13.3862 2.4375 12.9636 2.52156 12.5693 2.68489C12.175 2.84822 11.8167 3.08761 11.5149 3.3894C11.2131 3.69119 10.9737 4.04947 10.8104 4.44378C10.6471 4.83809 10.563 5.2607 10.563 5.6875V8.125H4.27876C4.04834 8.125 3.82056 8.174 3.61053 8.26875C3.4005 8.3635 3.21302 8.50183 3.06053 8.67457C2.90804 8.84731 2.79403 9.0505 2.72607 9.27066C2.6581 9.49082 2.63773 9.72292 2.66631 9.95156L3.88506 19.7016C3.93419 20.0946 4.12516 20.4561 4.42208 20.7182C4.71901 20.9803 5.10144 21.125 5.49751 21.125H17.8755'
        stroke={color}
        strokeWidth='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default LikeIcon
