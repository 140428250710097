import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const DocumentText: FC<Props> = ({className, width = '32', height = '32', color = '#333333'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      className={className}
    >
      <path
        d='M21.3333 30.3327H10.6667C5.8 30.3327 3 27.5327 3 22.666V9.33268C3 4.46602 5.8 1.66602 10.6667 1.66602H21.3333C26.2 1.66602 29 4.46602 29 9.33268V22.666C29 27.5327 26.2 30.3327 21.3333 30.3327ZM10.6667 3.66602C6.85333 3.66602 5 5.51935 5 9.33268V22.666C5 26.4793 6.85333 28.3327 10.6667 28.3327H21.3333C25.1467 28.3327 27 26.4793 27 22.666V9.33268C27 5.51935 25.1467 3.66602 21.3333 3.66602H10.6667Z'
        fill='#282828'
      />
      <path
        d='M24.6666 12.3333H21.9999C19.9733 12.3333 18.3333 10.6933 18.3333 8.66667V6C18.3333 5.45333 18.7866 5 19.3333 5C19.8799 5 20.3333 5.45333 20.3333 6V8.66667C20.3333 9.58667 21.0799 10.3333 21.9999 10.3333H24.6666C25.2133 10.3333 25.6666 10.7867 25.6666 11.3333C25.6666 11.88 25.2133 12.3333 24.6666 12.3333Z'
        fill='#282828'
      />
      <path
        d='M16.3333 18H11C10.4533 18 10 17.5467 10 17C10 16.4533 10.4533 16 11 16H16.3333C16.88 16 17.3333 16.4533 17.3333 17C17.3333 17.5467 16.88 18 16.3333 18Z'
        fill='#282828'
      />
      <path
        d='M21.3334 23.666H10.6667C10.1201 23.666 9.66675 23.2127 9.66675 22.666C9.66675 22.1193 10.1201 21.666 10.6667 21.666H21.3334C21.8801 21.666 22.3334 22.1193 22.3334 22.666C22.3334 23.2127 21.8801 23.666 21.3334 23.666Z'
        fill='#282828'
      />
    </svg>
  )
}

export default DocumentText
