import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const DelIcon: FC<Props> = ({className, width = '24', height = '24', color = '#292D32'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      className={className}
    >
      <path
        d='M17.5001 5.60833C17.4834 5.60833 17.4584 5.60833 17.4334 5.60833C13.0251 5.16667 8.62505 5 4.26672 5.44167L2.56672 5.60833C2.21672 5.64167 1.90839 5.39167 1.87505 5.04167C1.84172 4.69167 2.09172 4.39167 2.43339 4.35833L4.13339 4.19167C8.56672 3.74167 13.0584 3.91667 17.5584 4.35833C17.9001 4.39167 18.1501 4.7 18.1167 5.04167C18.0917 5.36667 17.8167 5.60833 17.5001 5.60833Z'
        fill={color}
      />
      <path
        d='M7.08338 4.76666C7.05005 4.76666 7.01672 4.76666 6.97505 4.75832C6.64172 4.69999 6.40838 4.37499 6.46672 4.04166L6.65005 2.94999C6.78338 2.14999 6.96671 1.04166 8.90838 1.04166H11.0917C13.0417 1.04166 13.225 2.19166 13.35 2.95832L13.5334 4.04166C13.5917 4.38332 13.3584 4.70832 13.025 4.75832C12.6834 4.81666 12.3584 4.58332 12.3084 4.24999L12.125 3.16666C12.0084 2.44166 11.9834 2.29999 11.1 2.29999H8.91672C8.03338 2.29999 8.01672 2.41666 7.89172 3.15832L7.70005 4.24166C7.65005 4.54999 7.38338 4.76666 7.08338 4.76666Z'
        fill={color}
      />
      <path
        d='M12.675 18.9583H7.325C4.41666 18.9583 4.3 17.35 4.20833 16.05L3.66666 7.65833C3.64166 7.31667 3.90833 7.01667 4.25 6.99167C4.6 6.975 4.89166 7.23334 4.91666 7.575L5.45833 15.9667C5.55 17.2333 5.58333 17.7083 7.325 17.7083H12.675C14.425 17.7083 14.4583 17.2333 14.5417 15.9667L15.0833 7.575C15.1083 7.23334 15.4083 6.975 15.75 6.99167C16.0917 7.01667 16.3583 7.30833 16.3333 7.65833L15.7917 16.05C15.7 17.35 15.5833 18.9583 12.675 18.9583Z'
        fill={color}
      />
      <path
        d='M11.3833 14.375H8.60828C8.26661 14.375 7.98328 14.0917 7.98328 13.75C7.98328 13.4083 8.26661 13.125 8.60828 13.125H11.3833C11.7249 13.125 12.0083 13.4083 12.0083 13.75C12.0083 14.0917 11.7249 14.375 11.3833 14.375Z'
        fill={color}
      />
      <path
        d='M12.0833 11.0417H7.91663C7.57496 11.0417 7.29163 10.7583 7.29163 10.4167C7.29163 10.075 7.57496 9.79166 7.91663 9.79166H12.0833C12.425 9.79166 12.7083 10.075 12.7083 10.4167C12.7083 10.7583 12.425 11.0417 12.0833 11.0417Z'
        fill={color}
      />
    </svg>
  )
}

export default DelIcon
