import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const BrushIcon: FC<Props> = ({className, width = '24', height = '24', color = '#292D32'}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.4299 18.3699C10.2799 18.3699 10.1399 18.3299 10.0099 18.2399C9.79991 18.0999 9.67991 17.8699 9.67991 17.6199C9.67991 17.4699 9.66991 17.3099 9.64991 17.1499C9.55991 16.4399 9.23991 15.8199 8.69991 15.2799C8.15991 14.7399 7.48991 14.3999 6.76991 14.3099C6.64991 14.2999 6.47991 14.2899 6.31991 14.2999C6.05991 14.3199 5.81991 14.2099 5.66991 13.9999C5.51991 13.7999 5.47991 13.5299 5.55991 13.2899C5.70991 12.8799 5.92991 12.5099 6.18991 12.2099L7.72991 10.2699C10.3799 6.95992 15.7499 2.97992 19.6799 1.39992C20.5099 1.07992 21.3999 1.26992 22.0099 1.86992C22.6399 2.49992 22.8299 3.39992 22.4999 4.21992C20.9199 8.15992 16.9499 13.5199 13.6399 16.1699L11.6699 17.7499C11.2999 18.0199 10.9999 18.1899 10.6999 18.3099C10.6199 18.3499 10.5199 18.3699 10.4299 18.3699ZM7.53991 12.9399C8.37991 13.1599 9.12991 13.5999 9.75991 14.2299C10.3899 14.8499 10.8099 15.5699 11.0199 16.3699L12.7099 15.0099C15.8499 12.4999 19.6199 7.40992 21.1099 3.66992C21.2599 3.29992 21.0499 3.03992 20.9499 2.94992C20.8799 2.87992 20.6199 2.65992 20.2199 2.80992C16.4999 4.30992 11.4099 8.07992 8.88991 11.2199L7.53991 12.9399Z'
        fill={color}
      />
      <path
        d='M4.08011 22.7501C3.33011 22.7501 2.61011 22.4501 2.07011 21.9101C1.45011 21.2901 1.15011 20.4301 1.25011 19.5501L1.52011 17.0901C1.78011 14.6501 3.78011 12.8401 6.26011 12.7901C6.45011 12.7801 6.70011 12.7901 6.93011 12.8101C8.02011 12.9501 8.99011 13.4401 9.77011 14.2201C10.5401 14.9901 11.0001 15.9101 11.1401 16.9401C11.1701 17.1601 11.1901 17.4001 11.1901 17.6101C11.1901 18.9301 10.6801 20.1601 9.76011 21.0901C8.99011 21.8501 8.00011 22.3201 6.88011 22.4601L4.41011 22.7301C4.30011 22.7401 4.19011 22.7501 4.08011 22.7501ZM6.45011 14.3001C6.41011 14.3001 6.36011 14.3001 6.32011 14.3001C4.81011 14.3301 3.21011 15.3601 3.01011 17.2601L2.74011 19.7201C2.69011 20.1401 2.84011 20.5501 3.13011 20.8501C3.42011 21.1401 3.83011 21.2901 4.24011 21.2401L6.70011 20.9701C7.47011 20.8701 8.16011 20.5501 8.68011 20.0301C9.32011 19.3901 9.68011 18.5301 9.68011 17.6101C9.68011 17.4601 9.67011 17.3001 9.65011 17.1401C9.56011 16.4301 9.24011 15.8101 8.70011 15.2701C8.16011 14.7301 7.49011 14.3901 6.77011 14.3001C6.69011 14.3001 6.57011 14.3001 6.45011 14.3001Z'
        fill={color}
      />
      <path
        d='M14.24 15.2202C13.83 15.2202 13.49 14.8802 13.49 14.4702C13.49 12.2702 11.7 10.4902 9.51001 10.4902C9.10001 10.4902 8.76001 10.1502 8.76001 9.74023C8.76001 9.33023 9.09001 8.99023 9.50001 8.99023C12.52 8.99023 14.98 11.4502 14.98 14.4702C14.99 14.8902 14.65 15.2202 14.24 15.2202Z'
        fill={color}
      />
    </svg>
  )
}

export default BrushIcon
