import type {FC} from 'react'
import classes from './BulkRunTableHeader.module.scss'

interface BulkRunTableHeaderProps {
  templateItem: any
}

const BulkRunTableHeader: FC<BulkRunTableHeaderProps> = ({templateItem}) => {
  const {requiredQuestions, additionalQuestions, optionalSelect, imgGenerate, formType} =
    templateItem

  const requiredQuestionsHeaders = requiredQuestions.map((item: any, index: number) => (
    <th key={index} className={classes.thead__questionHeader}>
      {item.title} <span className={classes.required}>*</span>
    </th>
  ))

  const additionalQuestionsHeaders = additionalQuestions.map((item: any, index: number) => (
    <th key={index} className={classes.thead__questionHeader}>
      {item.title}
    </th>
  ))

  const optionalSelectHeaders = optionalSelect.map((item: any, index: number) => (
    <th key={index} className={classes.thead__selectHeader}>
      {item.title}
    </th>
  ))

  return (
    <thead className={classes.thead}>
      <tr>
        <th className={classes.thead__buttonHeader}>Run</th>
        {requiredQuestionsHeaders}
        {optionalSelectHeaders}
        <th className={classes.thead__selectHeader}>Output language</th>
        {formType === 'social' && (
          <>
            <th className={classes.thead__selectHeader}>Number of variations</th>
            <th className={classes.thead__selectHeader}>
              Media selector<span className={classes.required}>*</span>
            </th>
          </>
        )}
        {/* {imgGenerate && <th className={classes.thead__checkboxHeader}>Include images</th>} */}
        {additionalQuestionsHeaders}
        <th>Result</th>
        {/* {imgGenerate && <th className={classes.thead__imagesHeader}>Images</th>} */}
      </tr>
    </thead>
  )
}

export default BulkRunTableHeader
