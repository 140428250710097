import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const ShareIcon: FC<Props> = ({className, width = '32', height = '32', color = '#65676B'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        d='M9.97149 5.6185L9.97149 2L13.8106 5.93537L16.8887 9.09068L13.7559 11.7669L9.97149 15L9.97149 11.4593C3.32753 10.9459 0.999784 14.8871 0.999784 14.8871C0.999784 12.4316 1.19716 9.88327 3.0804 7.95363C5.2695 5.70879 8.43977 5.54994 9.97149 5.6185Z'
        stroke={color}
        strokeWidth='1.3'
      />
    </svg>
  )
}

export default ShareIcon
