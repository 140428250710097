const registrationFormData = [
  {
    id: 1,
    title: 'Company name',
    inputType: 'text',
    name: 'company_name',
    placeholder: 'Company name',
    autocomlete: 'off',
    required: true,
  },

  {
    id: 2,
    title: 'First name',
    inputType: 'text',
    name: 'firstName',
    placeholder: 'First name',
    autocomlete: 'off',
    required: true,
  },

  {
    id: 3,
    title: 'Last name',
    inputType: 'text',
    name: 'lastName',
    placeholder: 'Last name',
    autocomlete: 'off',
    required: true,
  },

  {
    id: 4,
    title: 'Email',
    inputType: 'email',
    name: 'email',
    placeholder: 'Email',
    autocomlete: 'off',
    required: true,
  },

  {
    id: 5,
    title: 'Password',
    inputType: 'password',
    name: 'password',
    placeholder: 'Password',
    autocomlete: 'off',
    required: true,
  },

  {
    id: 6,
    title: 'Confirm Password',
    inputType: 'password',
    name: 'changepassword',
    placeholder: 'Password confirmation',
    autocomlete: 'off',
    required: true,
  },

  {
    id: 7,
    title: 'I Accept the ',
    inputType: 'checkbox',
    name: 'acceptTerms',
    placeholder: '',
    autocomlete: 'off',
    required: true,
  },
]

export default registrationFormData
