import {useState} from 'react'
import LeftArrowIcon from '../../icons/chat/LeftArrowIcon'
import ImageGenerated from '../../universal-components/image-generation/ImageGenerated'
import classes from './ChatContainer.module.scss'
import DownloadIcon from '../../icons/images/DownloadIcon'
import PieChart from './chart/PieChart'
import BarChart from './chart/BarChart'
import LineChart from './chart/LineChart'

interface ChartAreaProps {
  reports: any
  chartType: string
}

const ChartContainer: React.FC<ChartAreaProps> = ({reports, chartType}) => {
  const [currentImage, setCurrentImage] = useState<string>('/new-design/image/6.jpg')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCurrentImage = (img: string = '') => {
    setCurrentImage(img)
  }

  const ChartHeader = () => {
    return (
      <>
        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            listStyleType: 'none',
            padding: '1rem',
            margin: 0,
            width: '100%',
          }}
        >
          {reports.labels &&
          reports.labels.length > 0 &&
          reports.datasets &&
          reports.datasets[0] &&
          reports.datasets[0].backgroundColor ? (
            reports.labels.map((label: any, index: any) => (
              <li key={index}>
                <span
                  style={{
                    backgroundColor:
                      reports.datasets[0].backgroundColor[
                        index % reports.datasets[0].backgroundColor.length
                      ],
                    height: '15px',
                    width: '15px',
                    display: 'inline-block',
                    marginRight: '5px',
                    marginLeft: '5px',
                    borderRadius: '10%',
                  }}
                ></span>

                <span style={{fontSize: '16px'}}> {label}</span>
              </li>
            ))
          ) : (
            <>
              <li>
                <span
                  style={{
                    backgroundColor: 'rgba(125,125,125,255)',
                    height: '15px',
                    width: '15px',
                    display: 'inline-block',
                    marginRight: '5px',
                    marginLeft: '5px',
                    borderRadius: '10%',
                  }}
                ></span>

                <span style={{fontSize: '16px'}}>Amount ($) by </span>
              </li>

              <li>
                {' '}
                <span
                  style={{
                    backgroundColor: 'rgba(125,125,125,255)',
                    height: '15px',
                    width: '15px',
                    display: 'inline-block',
                    marginRight: '5px',
                    marginLeft: '5px',
                    borderRadius: '10%',
                  }}
                ></span>
                <span style={{fontSize: '16px'}}>Month for</span>
              </li>

              <li>
                {' '}
                <span
                  style={{
                    backgroundColor: 'rgba(173,216,230,255)',
                    height: '15px',
                    width: '15px',
                    display: 'inline-block',
                    marginRight: '5px',
                    marginLeft: '5px',
                    borderRadius: '10%',
                  }}
                ></span>
                <span style={{fontSize: '16px'}}> Revenue,</span>
              </li>

              <li>
                {' '}
                <span
                  style={{
                    backgroundColor: 'rgba(255,255,0,255)',
                    height: '15px',
                    width: '15px',
                    display: 'inline-block',
                    marginRight: '5px',
                    marginLeft: '5px',
                    borderRadius: '10%',
                  }}
                ></span>
                <span style={{fontSize: '16px'}}>Expenses, and </span>
              </li>

              <li>
                {' '}
                <span
                  style={{
                    backgroundColor: 'rgba(0,0,0,255)',
                    height: '15px',
                    width: '15px',
                    display: 'inline-block',
                    marginRight: '5px',
                    marginLeft: '5px',
                    borderRadius: '10%',
                  }}
                ></span>
                <span style={{fontSize: '16px'}}>Profit</span>
              </li>
            </>
          )}
        </ul>
      </>
    )
  }

  return (
    <>
      <ChartHeader />
      {reports && reports.labels && reports.labels.length > 0 ? (
        <div className={classes.container} style={{height: '600px'}}>
          {chartType === 'BAR_CHART' && <BarChart reports={reports} />}
          {chartType === 'LINE_CHART' && <LineChart reports={reports} />}
          {chartType === 'PIE_CHART' && <PieChart reports={reports} />}
        </div>
      ) : (
        <img src={currentImage} className={classes.imageWidthFull} alt='' />
      )}
    </>
  )
}

export default ChartContainer
