import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const FinanceIcon: FC<Props> = ({className, width = '24', height = '24', color = '#616161'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      className={className}
    >
      <path
        d='M13.4 17.42H10.89C9.25 17.42 7.92 16.04 7.92 14.34C7.92 13.93 8.26 13.59 8.67 13.59C9.08 13.59 9.42 13.93 9.42 14.34C9.42 15.21 10.08 15.92 10.89 15.92H13.4C14.05 15.92 14.59 15.34 14.59 14.64C14.59 13.77 14.28 13.6 13.77 13.42L9.74 12C8.96 11.73 7.91 11.15 7.91 9.36C7.91 7.82 9.12 6.58 10.6 6.58H13.11C14.75 6.58 16.08 7.96 16.08 9.66C16.08 10.07 15.74 10.41 15.33 10.41C14.92 10.41 14.58 10.07 14.58 9.66C14.58 8.79 13.92 8.08 13.11 8.08H10.6C9.95 8.08 9.41 8.66 9.41 9.36C9.41 10.23 9.72 10.4 10.23 10.58L14.26 12C15.04 12.27 16.09 12.85 16.09 14.64C16.08 16.17 14.88 17.42 13.4 17.42Z'
        fill={color}
      />
      <path
        d='M12 18.75C11.59 18.75 11.25 18.41 11.25 18V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V18C12.75 18.41 12.41 18.75 12 18.75Z'
        fill={color}
      />
      <path
        d='M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z'
        fill={color}
      />
    </svg>
  )
}

export default FinanceIcon
