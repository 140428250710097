import React from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'

interface SidebarProps {
  activeCell: string
  setActiveCell: (title: string) => void
}

const sidebarConfig = [
  {
    section: 'ACCOUNT',
    items: [{label: 'Information'}, {label: 'Logo'}, {label: 'Color'}],
  },
  {
    section: 'Package',
    items: [{label: 'Create'}, {label: 'List'}],
  },
  {
    section: 'Payment',
    items: [{label: 'TopUp'}],
  },
]

const Sidebar: React.FC<SidebarProps> = ({activeCell, setActiveCell}) => {
  const navigate = useNavigate()

  const handleClick = (label: string) => {
    setActiveCell(label)
    navigate(`#${label}`)
  }

  return (
    <div
      className='col-md-2 px-0'
      style={{
        minHeight: '100vh',
        backgroundColor: 'white',
        borderRight: '1px solid #edf2f7',
        padding: '1rem 0',
        fontSize: 14,
      }}
    >
      <nav className='nav flex-column'>
        {sidebarConfig.map((section) => (
          <div key={section.section}>
            <div
              style={{
                color: '#94a3b8',
                fontSize: 12,
                fontWeight: 600,
                padding: '1.25rem 1.5rem 0.5rem',
                letterSpacing: '0.05em',
              }}
            >
              {section.section}
            </div>
            {section.items.map((item) => (
              <Link
                to='#'
                key={item.label}
                className={`nav-link ${activeCell === item.label ? 'active' : ''}`}
                style={{
                  color:
                    activeCell === item.label ? 'var(--primary-color)' : 'var(--secondary-color)',
                  padding: '0.5rem 1.5rem',
                  transition: 'all 0.2s',
                  fontWeight: activeCell === item.label ? 600 : 500,
                }}
                onClick={(e) => {
                  e.preventDefault()
                  handleClick(item.label)
                }}
              >
                {item.label}
              </Link>
            ))}
          </div>
        ))}
      </nav>
    </div>
  )
}

export default Sidebar
