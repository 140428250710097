import {type FC} from 'react'
import {useAuth} from '../../../app/modules/auth'
import {useCreateApiKeyMutation, useGetApiKeyQuery} from '../../../services/apiKeyService'
import classes from './ApiGenerate.module.scss'

import {toast} from 'react-toastify'
import CopyIcon from '../icons/chat/CopyIcon'
import RefreshIcon from '../icons/chat/RefreshIcon'
import {GlobalErrorMessagesEnum} from '../../../types/types'
import copy from 'copy-to-clipboard'

interface ApiGenerateProps {}

const ApiGenerate: FC<ApiGenerateProps> = () => {
  const {currentUser} = useAuth()

  const {data, isLoading: getApiLoading} = useGetApiKeyQuery(currentUser?.id)
  const [createApiKey, {isLoading}] = useCreateApiKeyMutation()

  const generateApiKey = async () => {
    await createApiKey(currentUser?.id)
      .unwrap()
      .then((res) => {
        toast.success('API key successfully regenerated', {toastId: 'generateApiKey'})
      })
      .catch((e) => {
        if (e.status === 429) {
          toast.warning(GlobalErrorMessagesEnum.THROTTLER_EXCEPTION)
          return
        }
        toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
      })
  }

  const copyApiKey = (data: string) => {
    if (!data) {
      toast.error('The API key is not available for copying.')
      return
    }

    copy(data, {
      debug: false,
      message: 'Press #{key} to copy',
    })

    toast.success('API key copied successfully.', {toastId: 'copyApiKey'})
  }

  return (
    <div className={classes.apikey}>
      <div className={classes.apikey__container}>
        <div className={classes.apikey__container__topBlock}>
          <h2 className={classes.apikey__container__topBlock__title}>API key</h2>
          <h6>
            Keep your API key private. Avoid sharing it with others or exposing it in client-side
            code or the browser.
          </h6>
        </div>
        <div className={classes.info}>
          <input
            type={'text'}
            name={'api_key'}
            value={data?.api_key}
            placeholder={'Generate your API key...'}
            disabled
          />
          <div className={classes.info__btnBlock}>
            <button
              onClick={() => {
                copyApiKey(data?.api_key)
              }}
            >
              {' '}
              <CopyIcon className={classes.info__svg} />{' '}
            </button>
            <button onClick={generateApiKey}>
              {
                <div className={(isLoading || getApiLoading) && classes.info__icon}>
                  <RefreshIcon className={classes.info__svg} />
                </div>
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApiGenerate
