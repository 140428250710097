import type {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const MarketingIcon: FC<Props> = ({className, width = '24', height = '24', color = '#282828'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      className={className}
    >
      <path
        d='M28 7H18.6667C18.12 7 17.6667 6.54667 17.6667 6C17.6667 5.45333 18.12 5 18.6667 5H28C28.5467 5 29 5.45333 29 6C29 6.54667 28.5467 7 28 7Z'
        fill={color}
      />
      <path
        d='M28 13.6667H18.6667C18.12 13.6667 17.6667 13.2133 17.6667 12.6667C17.6667 12.12 18.12 11.6667 18.6667 11.6667H28C28.5467 11.6667 29 12.12 29 12.6667C29 13.2133 28.5467 13.6667 28 13.6667Z'
        fill={color}
      />
      <path
        d='M28 20.3333H4C3.45333 20.3333 3 19.88 3 19.3333C3 18.7867 3.45333 18.3333 4 18.3333H28C28.5467 18.3333 29 18.7867 29 19.3333C29 19.88 28.5467 20.3333 28 20.3333Z'
        fill={color}
      />
      <path
        d='M28 27H4C3.45333 27 3 26.5467 3 26C3 25.4533 3.45333 25 4 25H28C28.5467 25 29 25.4533 29 26C29 26.5467 28.5467 27 28 27Z'
        fill={color}
      />
      <path
        d='M10.56 14.3333H6.77332C4.71999 14.3333 3.66666 13.2933 3.66666 11.2267V7.43999C3.66666 5.38666 4.70666 4.33333 6.77332 4.33333H10.5733C12.6267 4.33333 13.68 5.37333 13.68 7.43999V11.24C13.6667 13.2933 12.6267 14.3333 10.56 14.3333ZM6.77332 6.33333C5.82666 6.33333 5.66666 6.49333 5.66666 7.43999V11.24C5.66666 12.1867 5.82666 12.3467 6.77332 12.3467H10.5733C11.52 12.3467 11.68 12.1867 11.68 11.24V7.43999C11.68 6.49333 11.52 6.33333 10.5733 6.33333H6.77332Z'
        fill={color}
      />
    </svg>
  )
}

export default MarketingIcon
