import {useGoogleLogin} from '@react-oauth/google'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useAuth} from '../../../app/modules/auth/core/Auth'
import {googleAuthentication, queryParameters} from '../../../app/modules/auth/core/_requests'
import {useLazyGetUserByTokenQuery, useRegisterMutation} from '../../../services/authServiceApi'
import registrationFormData from '../../data/auth/registrationFormData'
import classes from './AuthStyles.module.scss'
import {FiEye, FiEyeOff} from 'react-icons/fi'
import axios from 'axios'
import {toast} from 'react-toastify'

const inviteToken = queryParameters.get('invite_token')
const inviteCompanyName = queryParameters.get('companyName')

const initialValues = {
  company_name: `${inviteToken || inviteCompanyName || ''}`,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  company_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company name is required'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .min(3, 'Minimum 5 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please enter a valid email address'),
  firstName: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  lastName: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  access_token: Yup.string(),
})

export function Registration() {
  const {saveAuth, setCurrentUser} = useAuth()
  const [register, {isLoading}] = useRegisterMutation()
  const [getUserByToken] = useLazyGetUserByTokenQuery()
  const navigate = useNavigate()
  const location = useLocation()
  const appsumocode = sessionStorage.getItem('appsumocode') || null
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const queryParams = new URLSearchParams(location.search)
  const code = queryParams.get('code')
  const referralCode = queryParams.get('referral_code')
  const inviteToken = queryParams.get('invite_token')
  const [inviteType, setInviteType] = useState<string | null>(null)

  useEffect(() => {
    if (code) {
      sessionStorage.setItem('appsumocode', code)
      navigate('/auth/registration', {replace: true})
    }

    if (inviteToken) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/members/get-invited-email/${inviteToken}`)
        .then((res) => {
          initialValues.email = res.data.email ? res.data.email : ''
          setInviteType(res.data.invite_type)
        })
        .catch((e) => {
          initialValues.company_name = ''
          toast.error(e.response.data.message)
          navigate('/auth/registration')
        })
    }
  }, [code, navigate])

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        await register({
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          company_name: values.company_name,
          password: values.password,
          code: appsumocode,
          referral_code: referralCode,
          invite_token: inviteToken,
        }).unwrap()
        localStorage.setItem('verify_email', 'true')
        navigate('/login', {replace: true})
      } catch (error: any) {
        console.error(error)
        saveAuth(undefined)
        setStatus(
          `${error.data.message ? error.data.message : 'The registration details are incorrect'}`
        )
        setSubmitting(false)
      }
      sessionStorage.removeItem('appsumocode')
    },
  })

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        await googleAuthentication(codeResponse, appsumocode, referralCode).then(
          async (res: any) => {
            if (codeResponse.access_token) {
              saveAuth(res.data)
              const user = await getUserByToken(res.data).unwrap()
              setCurrentUser(user)
            }
          }
        )
      } catch (err) {}
      sessionStorage.removeItem('appsumocode')
    },
    onError: (error) => console.log('Login from Google Failed', error),
  })

  const inputsBlock = registrationFormData.map((item) => {
    const toggleNewPasswordVisibility = () => {
      setShowNewPassword((prevState) => !prevState)
    }

    const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword((prevState) => !prevState)
    }

    const {id, title, inputType, name, placeholder, autocomlete, required} = item
    return (
      <label
        key={id}
        className={`${classes.auth__form__label} ${
          inputType === 'checkbox' ? classes.auth__form__labelCheckbox : undefined
        }`}
      >
        <h4
          className={classes.auth__form__label__title}
          style={{
            display:
              title === 'Company name' && (inviteToken !== null || inviteCompanyName !== null)
                ? 'none'
                : 'block',
          }}
        >
          {title} {inputType === 'checkbox' && <a href='#'>Terms</a>}
        </h4>

        <input
          type={
            (name === 'password' && showNewPassword) ||
            (name === 'changepassword' && showConfirmPassword)
              ? 'text'
              : inputType
          }
          placeholder={placeholder}
          required={required}
          {...formik.getFieldProps(name)}
          autoComplete={autocomlete}
          disabled={
            (title === 'Company name' || title === 'Email') &&
            inviteToken !== null &&
            inviteType === 'email'
          }
          style={{
            display:
              title === 'Company name' && (inviteToken !== null || inviteCompanyName !== null)
                ? 'none'
                : 'block',
          }}
        />

        {name === 'password' && (
          <span
            style={{
              position: 'absolute',
              top: '70%',
              right: '8px',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
            }}
            onClick={toggleNewPasswordVisibility}
          >
            {showNewPassword ? <FiEyeOff size={16} /> : <FiEye size={16} />}
          </span>
        )}

        {name === 'changepassword' && (
          <span
            style={{
              position: 'absolute',
              top: '70%',
              right: '8px',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
            }}
            onClick={toggleConfirmPasswordVisibility}
          >
            {showConfirmPassword ? <FiEyeOff size={16} /> : <FiEye size={16} />}
          </span>
        )}

        {(inviteToken !== null || inviteCompanyName !== null) && title === 'Company name' && (
          <span
            className={classes.messageSuccess}
            style={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            Sign up using the invitation link
          </span>
        )}
        {formik.touched[name as keyof typeof formik.touched] &&
          formik.errors[name as keyof typeof formik.errors] && (
            <span
              style={{
                position: 'absolute',
                top: '100%',
                marginTop: '10px',
              }}
              className={classes.messageError}
              role='alert'
            >
              {formik.errors[name as keyof typeof formik.errors]}
            </span>
          )}

        {/* {name === 'company_name' &&
          inviteCompanyName &&
          initialValues.company_name !== formik.values.company_name && (
            <span className={classes.messageSuccess} role='alert'>
              You were invited to {initialValues.company_name}. If you change this field - you will
              use Klevere.ai with another organization
            </span>
          )} */}
      </label>
    )
  })

  return (
    <div className='appAuth'>
      <div className={classes.auth}>
        <div className={classes.auth__topBlock}>
          <h1 className={classes.auth__topBlock__title}>Sign Up</h1>
          {/* <button className={classes.auth__topBlock__googleBtn} onClick={() => googleLogin()}>
            <GoogleIcon className={classes.auth__topBlock__googleBtn__svg} />
            Continue with Google
          </button>
          <div className={classes.auth__topBlock__separator}>
            <span>or</span>
          </div>{' '} */}
        </div>
        {formik.status && <span className={classes.messageError}>{formik.status}</span>}

        <form className={classes.auth__form} onSubmit={formik.handleSubmit} noValidate>
          {inputsBlock}

          <button
            type='submit'
            className='universalButton'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {isLoading ? <>Please wait...</> : <>Create account</>}
          </button>
        </form>
        <div className={classes.auth__footer}>
          <span className={classes.auth__signUp}>
            Already have an account?
            <Link to='/auth'>Login</Link>
          </span>
          <p>
            Email <span className={classes.auth__footer__email}>support@klevere.ai</span> or chat
            with us to report any issues
          </p>
        </div>
      </div>
    </div>
  )
}
