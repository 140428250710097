import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const ImportIcon: FC<Props> = ({className, width = '24', height = '24', color = 'white'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      className={className}
    >
      <path
        d='M11.4999 9.1252C11.3415 9.1252 11.1832 9.06686 11.0582 8.94186C10.8165 8.7002 10.8165 8.3002 11.0582 8.05853L17.8915 1.2252C18.1332 0.983529 18.5332 0.983529 18.7749 1.2252C19.0165 1.46686 19.0165 1.86686 18.7749 2.10853L11.9415 8.94186C11.8249 9.05853 11.6665 9.1252 11.4999 9.1252Z'
        fill={color}
      />
      <path
        d='M14.8585 9.7916H10.8335C10.4918 9.7916 10.2085 9.50827 10.2085 9.1666V5.1416C10.2085 4.79993 10.4918 4.5166 10.8335 4.5166C11.1752 4.5166 11.4585 4.79993 11.4585 5.1416V8.5416H14.8585C15.2002 8.5416 15.4835 8.82493 15.4835 9.1666C15.4835 9.50827 15.2002 9.7916 14.8585 9.7916Z'
        fill={color}
      />
      <path
        d='M12.4998 18.9582H7.49984C2.97484 18.9582 1.0415 17.0248 1.0415 12.4998V7.49984C1.0415 2.97484 2.97484 1.0415 7.49984 1.0415H9.1665C9.50817 1.0415 9.7915 1.32484 9.7915 1.6665C9.7915 2.00817 9.50817 2.2915 9.1665 2.2915H7.49984C3.65817 2.2915 2.2915 3.65817 2.2915 7.49984V12.4998C2.2915 16.3415 3.65817 17.7082 7.49984 17.7082H12.4998C16.3415 17.7082 17.7082 16.3415 17.7082 12.4998V10.8332C17.7082 10.4915 17.9915 10.2082 18.3332 10.2082C18.6748 10.2082 18.9582 10.4915 18.9582 10.8332V12.4998C18.9582 17.0248 17.0248 18.9582 12.4998 18.9582Z'
        fill={color}
      />
    </svg>
  )
}

export default ImportIcon
