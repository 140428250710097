import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
export const GET_USER_BY_ACCESS_TOKEN_URL = `${API_URL}/auth/verify_token`
export const REFRESH_USER_REFRESH_TOKEN_URL = `${API_URL}/auth/refresh`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/signup`
export const CONFIRM_EMAIL = `${API_URL}/auth/confirm_email`

export const LOGOUT_URL = `${API_URL}/auth/logout`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const SEND_INVITATION_EMAIL = `${API_URL}/members/send-invitation`
export const REQUEST_INVITATION_URL = `${API_URL}/members/request_link`

export const GPT_USAGE = `${API_URL}/gpt/update_usage`
export const GPT_LIMITS = `${API_URL}/gpt/check_limits`

export const queryParameters = new URLSearchParams(window.location.search)
export const sessionId = queryParameters.get('checkout_session_id')
export const emailToken = queryParameters.get('token')
if (sessionId) {
  localStorage.setItem('session_id', sessionId)
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstName: string,
  lastName: string,
  company_name: string,
  password: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstName,
    last_name: lastName,
    company_name,
    password,
  })
}

export function confirmEmail(token: string) {
  return axios.post(CONFIRM_EMAIL, {
    token,
  })
}

export function serverLogout(token?: string) {
  return axios.get<UserModel>(LOGOUT_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export async function googleAuthentication(
  googleResponse: any,
  code?: string | null,
  referralCode?: string | null
) {
  if (googleResponse.access_token) {
    try {
      const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          Authorization: `Bearer ${googleResponse.access_token}`,
        },
      })
      return await axios.post<any>(`${API_URL}/auth/google-authentication`, {
        email: userInfoResponse.data.email,
        first_name: userInfoResponse.data.given_name,
        last_name: userInfoResponse.data.family_name,
        avatar: userInfoResponse.data.picture,
        code,
        referral_code: referralCode,
      })
    } catch (error) {
      console.log(error)
    }
  }
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}
export function resetPassword(token: string, password: string) {
  return axios.post(RESET_PASSWORD_URL, {token, password})
}
export function getUserByToken(token?: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESS_TOKEN_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
export function checkLimits(token?: string) {
  return axios.get(GPT_LIMITS, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function updateUsage({
  words,
  images,
  credits,
}: {
  words: string
  images?: number
  credits?: number
}) {
  return axios.put(GPT_USAGE, {
    words: words ? words.replace(/[^0-9]/g, '') : 0,
    images: images ? images : 0,
    credits: credits ? credits : 0,
  })
}

export function sendInvitationByEmail(email: string, company_name: string | undefined) {
  return axios.post(SEND_INVITATION_EMAIL, {
    email,
    company_name,
  })
}

export function getInvitationLink(company_name: string | undefined) {
  return axios.post(REQUEST_INVITATION_URL, {
    company_name,
  })
}
export async function refresh(token?: string) {
  return axios.get(REFRESH_USER_REFRESH_TOKEN_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
