import {useCallback, useEffect, useMemo, useRef, useState, type FC} from 'react'
import ReactApexChart from 'react-apexcharts'
import {toast} from 'react-toastify'
import {getCreditsLimitByPlanName} from '../../../utils/getCreditsLimitByPlanName'
import {getImagesLimitByPlanName} from '../../../utils/getImagesLimitByPlanName'
import {getMonthName} from '../../../utils/getMonthName'
import {getTodayDate} from '../../../utils/getTodayDate'
import {getWordsLimitByPlanName} from '../../../utils/getWordsLimitByPlanName'
import UniversalHiddenScreen from '../universal-components/hidden-screen/UniversalHiddenScreen'
import classes from './GraphicsArea.module.scss'
import InviteColleague from './InviteColleague'
import {getUsersLimit} from '../../../utils/getUsersLimit'
import {IUsageRateData, SubscriptionPlansEnum} from '../../../types/types'
import {bool} from 'aws-sdk/clients/signer'
import {useAuth} from '../../../app/modules/auth'
import WordsUsedBlock from './WordsUsedBlock'
import {isFreemiumUser as getIsFreemiumUser, isTrialUser} from '../../../utils/userRoles'

interface GraphicsAreaProps {
  currentUser: any
  company: any
}

const GraphicsArea: FC<GraphicsAreaProps> = ({
  currentUser,
  company = {
    id: 0,
    name: '',
    company_generated_amount: {},
    users: [],
    freemiumLimit: {
      words: 2500,
      images: 10,
      credits: 100,
    },
    subscription: [],
  },
}) => {
  const {month, year} = useMemo(() => getTodayDate(), [])
  const wordsLimit = useMemo(
    () => getWordsLimitByPlanName(currentUser?.subscription.name),
    [currentUser?.subscription.name]
  )
  const imagesLimit = useMemo(
    () => getImagesLimitByPlanName(currentUser?.subscription.name),
    [currentUser?.subscription.name]
  )
  const creditsLimit = useMemo(
    () => getCreditsLimitByPlanName(currentUser?.subscription.name),
    [currentUser?.subscription.name]
  )

  const [visibleBackground, setVisibleBackground] = useState(false)
  const [usersLimit, setUsersLimit] = useState<number>(10)
  const [usageRateData, setUsageRateData] = useState<IUsageRateData>({
    words: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
    credits: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
    images: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
    months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun'],
  })

  const isAdmin = useMemo(() => currentUser?.role === 'ADMIN', [currentUser?.role])
  const isFreemiumUser = getIsFreemiumUser(currentUser)

  const wordsUsagePercentage = useMemo(() => {
    // Freemium users.
    if (isFreemiumUser) {
      const words =
        (currentUser?.user_generated_amount?.[year]?.[getMonthName(month)]?.words /
          company.freemiumLimit.words) *
        100

      const usagePercentage = words > 100 ? 100 : parseFloat(words.toFixed(2))
      return usagePercentage
    }

    const words = isAdmin
      ? Math.round(
          (company?.company_generated_amount?.[year]?.[getMonthName(month)].words / wordsLimit) *
            100
        )
      : (currentUser?.user_generated_amount?.[year]?.[getMonthName(month)].words / wordsLimit) * 100

    const usagePercentage = words > 100 ? 100 : parseFloat(words.toFixed(2))
    return usagePercentage
  }, [isAdmin, month, year, wordsLimit, currentUser, company])

  const getUsageRateData = useCallback(
    (companyData: any) => {
      let words: number[] = []
      let credits: number[] = []
      let images: number[] = []
      let months: string[] = []

      const generatdAmount = isAdmin
        ? companyData?.company_generated_amount
        : currentUser?.user_generated_amount

      const updateArrays = (obj: any, monthIndex: number) => {
        const monthName = getMonthName(monthIndex)

        const _wordsLimit = isFreemiumUser ? company.freemiumLimit.words : wordsLimit
        const _creditsLimit = isFreemiumUser ? company.freemiumLimit.credits : creditsLimit
        const _imagesLimit = isFreemiumUser ? company.freemiumLimit.images : imagesLimit

        const wordsUsage = Math.round(
          ((obj?.[monthName].words ? obj[monthName].words : 0) / _wordsLimit) * 100
        )
        const wordsPercentage = wordsUsage > 100 ? 100 : wordsUsage

        const creditsUsage = Math.round(
          ((obj?.[monthName].credits ? obj[monthName].credits : 0) / _creditsLimit) * 100
        )
        const creditsPercentage = creditsUsage > 100 ? 100 : creditsUsage

        const imagesUsage = Math.round(
          ((obj?.[monthName].images ? obj[monthName].images : 0) / _imagesLimit) * 100
        )

        const imagesPercentage = imagesUsage > 100 ? 100 : imagesUsage

        words.unshift(wordsPercentage)
        credits.unshift(creditsPercentage)
        images.unshift(imagesPercentage)

        months.unshift(monthName.slice(0, 3))
      }

      for (let i = month; i >= month - 5 && i >= 0; i--) {
        let obj = generatdAmount[year]
        updateArrays(obj, i)
      }
      if (words.length < 6) {
        let obj = generatdAmount[year - 1]
        for (let i = 11; words.length < 6; i--) {
          updateArrays(obj, i)
        }
      }
      setUsageRateData((prev) => {
        return {...prev, words, credits, images, months}
      })
    },
    [
      isAdmin,
      currentUser?.user_generated_amount,
      wordsLimit,
      creditsLimit,
      imagesLimit,
      month,
      year,
    ]
  )

  const handleVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const handleAdminOnlyAction = () => {
    toast.warning('Only admin can invite members to this company')
  }

  const mockedLayer = usageRateData.months.map((item, index) => {
    const emptyMonth =
      !usageRateData.words[index] && !usageRateData.credits[index] && !usageRateData.images[index]
    return (
      <div key={index} className={classes.graphics__usage__mocked__month}>
        <div
          className={classes.graphics__usage__mocked__month__item}
          style={{height: emptyMonth ? Math.round(Math.random() * 100) + '%' : 0}}
          title='No words data for this period'
        ></div>
        <div
          className={classes.graphics__usage__mocked__month__item}
          style={{height: emptyMonth ? Math.round(Math.random() * 100) + '%' : 0}}
          title='No credits data for this period'
        ></div>
        <div
          className={classes.graphics__usage__mocked__month__item}
          style={{height: emptyMonth ? Math.round(Math.random() * 100) + '%' : 0}}
          title='No images data for this period'
        ></div>
      </div>
    )
  })

  useEffect(() => {
    if (currentUser && currentUser.company_name) {
      setUsersLimit(getUsersLimit(currentUser.subscription.name))
    }
  }, [])
  useEffect(() => {
    getUsageRateData(company)
  }, [company])
  const options = {
    chart: {
      // height: 200,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      events: {
        mounted: (chart: any) => {
          chart.windowResizeHandler()
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: usageRateData.months.map((item) => item.toUpperCase()),
      labels: {
        style: {
          fontSize: '12px',
          textTransform: 'uppercase',
        },
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 1,
          },
        },
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 0.8,
    },
    tooltip: {
      custom: ({
        series,
        seriesIndex,
        dataPointIndex,
        w,
      }: {
        series: number[][]
        seriesIndex: number
        dataPointIndex: number
        w: any
      }) => {
        const dataPoint = w.config.series[seriesIndex].data[dataPointIndex] as any
        if (dataPoint.isDummy && dataPoint.categoryType === 'WORDS') {
          return 'No words data for this period '
        }

        if (dataPoint.isDummy && dataPoint.categoryType === 'CREDITS') {
          return 'No credits data for this period '
        }

        if (dataPoint.isDummy && dataPoint.categoryType === 'IMAGES') {
          return 'No images data for this period '
        }

        const colors = ['#282828', '#2468ff', '#e4ed96']
        const categoryColor =
          dataPoint.categoryType === 'WORDS'
            ? colors[0]
            : dataPoint.categoryType === 'CREDITS'
            ? colors[1]
            : colors[2]

        return `
  <div style="
    background: white;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 180px;
  ">
    <div style="
      background-color:#eceff1;
      padding: 10px 10px 10px 5px;
    ">${dataPoint.x}</div>
    <div style="
      color: #333;
      padding:15px;
      text-align:center;
      display: flex;
      align-items: center;
      justify-content: center;
    ">
      <span style="display: inline-block; width: 15px; height: 15px; background-color: ${categoryColor}; border-radius: 50%; margin-right: 5px;"></span>
      ${w.globals.seriesNames[seriesIndex]} usage: <b>  ${dataPoint.y}%</b>
    </div>
  </div>`
      },
    },
    colors: [
      function ({
        series,
        seriesIndex,
        dataPointIndex,
        w,
      }: {
        series: number[][]
        seriesIndex: number
        dataPointIndex: number
        w: any
      }) {
        const dataPoint = w.config.series[seriesIndex].data[dataPointIndex] as any

        if (dataPoint.isDummy) {
          if (dataPoint.categoryType === 'WORDS') {
            return '#939393'
          } else if (dataPoint.categoryType === 'CREDITS') {
            return '#91b3ff'
          } else if (dataPoint.categoryType === 'IMAGES') {
            return '#f1f6ca'
          }
        } else {
          if (dataPoint.categoryType === 'WORDS') {
            return '#000'
          } else if (dataPoint.categoryType === 'CREDITS') {
            return '#246cfc'
          } else if (dataPoint.categoryType === 'IMAGES') {
            return '#e6e996'
          }
        }
      },
    ],
  }

  const generateSeriesData = (data: number[], dummyOffset: number, categoryType: string) => {
    return data.map((item, index) => {
      const isDummy = item === 0
      const randomValue = (dummyOffset * (index + 1)) % 101
      const value = item === 0 ? randomValue : item

      return {
        x: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][
          index
        ],
        y: value,
        isDummy: isDummy,
        categoryType: categoryType,
      }
    })
  }

  const series = [
    {
      name: 'Words',
      data: generateSeriesData(usageRateData.words, 20, 'WORDS'),
    },
    {
      name: 'Credits',
      data: generateSeriesData(usageRateData.credits, 40, 'CREDITS'),
    },
    {
      name: 'Images',
      data: generateSeriesData(usageRateData.images, 30, 'IMAGES'),
    },
  ]
  const charPrentRef = useRef<HTMLDivElement>(null)
  const [chartParentWidth, setChartParentWidth] = useState(0)
  const [chartParentHeight, setChartParentHeight] = useState(0)
  useEffect(() => {
    if (charPrentRef.current) {
      setChartParentWidth(charPrentRef.current.offsetWidth - 20)
      setChartParentHeight(charPrentRef.current.offsetHeight - 20)
    }
  }, [])

  return (
    <div className={classes.graphics}>
      <UniversalHiddenScreen
        visible={visibleBackground}
        handleVisibility={handleVisibility}
        contentBlock={<InviteColleague handleVisibility={handleVisibility} />}
      />
      <div
        className={`${classes.graphics__progress} `}
        style={{
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        }}
      >
        <h4 className={classes.graphics__progress__title}>
          {isAdmin ? 'You have ' : 'Your company has '}
          {typeof usersLimit === 'number' && typeof company?.activeCompanyUsers === 'number'
            ? Math.max(usersLimit - (company?.activeCompanyUsers ?? 0), 0)
            : 0}{' '}
          spots left in team.
        </h4>
        <div className={classes.graphics__progress__bar}>
          <div
            className={classes.graphics__progress__bar__line}
            style={{width: `${(company?.activeCompanyUsers / usersLimit) * 100}%`}}
          ></div>
        </div>

        {isFreemiumUser || isTrialUser(currentUser) ? (
          <button
            className={classes.graphics__progress__btn}
            onClick={() => {
              toast.error('Upgrade your plan to invite team members')
            }}
            title={
              usersLimit - (company?.activeCompanyUsers || 0) - 1 <= 0
                ? 'Upgrade your plan to invite team members'
                : ''
            }
          >
            Invite Team Members
          </button>
        ) : isAdmin ? (
          <button
            className={classes.graphics__progress__btn}
            onClick={handleVisibility}
            disabled={usersLimit - company?.activeCompanyUsers > 0 ? false : true}
            title={
              usersLimit - (company?.activeCompanyUsers || 0) - 1 <= 0
                ? 'Upgrade your plan to invite team members'
                : ''
            }
          >
            Invite Team Members
          </button>
        ) : (
          <button
            className={classes.graphics__progress__btn}
            title='You are not the admin of this company'
            onClick={handleAdminOnlyAction}
          >
            Invite Team Members
          </button>
        )}
      </div>

      <div className={classes.graphics__usage} ref={charPrentRef}>
        <h4
          style={{
            position: 'absolute',
            top: '7%',
            left: '3%',
          }}
        >
          Usage Rate
        </h4>
        <div className={classes.graphics__usage__mocked}>{mockedLayer}</div>
        <ReactApexChart
          options={options}
          series={series}
          type='bar'
          height={chartParentHeight < 100 ? 120 : chartParentHeight}
          width={chartParentWidth < 100 ? 200 : chartParentWidth}
        />
      </div>

      <div
        className={`${classes.graphics__wordsUsage}`}
        style={{
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.15)',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h4
          style={{
            position: 'absolute',
            top: '7%',
            left: '7%',
          }}
        >
          Words Used
        </h4>
        <h5
          style={{
            position: 'absolute',
            fontSize: '1.8rem',
            top: '48%',
          }}
        >
          {isNaN(wordsUsagePercentage)
            ? 0
            : wordsUsagePercentage
            ? `${wordsUsagePercentage}%`
            : '0%'}
        </h5>
        <WordsUsedBlock currentUsage={wordsUsagePercentage} chartParentHeight={chartParentHeight} />
      </div>
    </div>
  )
}

export default GraphicsArea
