import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const RightArrowIcon: FC<Props> = ({className, width = '24', height = '24', color = '#292D32'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      className={className}
    >
      <path
        d='M11.6801 15.3699C11.4901 15.3699 11.3001 15.2999 11.1501 15.1499C10.8601 14.8599 10.8601 14.3799 11.1501 14.0899L13.1801 12.0599L11.1501 10.0299C10.8601 9.73994 10.8601 9.25994 11.1501 8.96994C11.4401 8.67994 11.9201 8.67994 12.2101 8.96994L14.7701 11.5299C15.0601 11.8199 15.0601 12.2999 14.7701 12.5899L12.2101 15.1499C12.0701 15.2999 11.8701 15.3699 11.6801 15.3699Z'
        fill={color}
      />
      <path
        d='M14.17 12.8101H4C3.59 12.8101 3.25 12.4701 3.25 12.0601C3.25 11.6501 3.59 11.3101 4 11.3101H14.17C14.58 11.3101 14.92 11.6501 14.92 12.0601C14.92 12.4701 14.58 12.8101 14.17 12.8101Z'
        fill={color}
      />
      <path
        d='M12 20.75C11.59 20.75 11.25 20.41 11.25 20C11.25 19.59 11.59 19.25 12 19.25C16.27 19.25 19.25 16.27 19.25 12C19.25 7.73 16.27 4.75 12 4.75C11.59 4.75 11.25 4.41 11.25 4C11.25 3.59 11.59 3.25 12 3.25C17.15 3.25 20.75 6.85 20.75 12C20.75 17.15 17.15 20.75 12 20.75Z'
        fill={color}
      />
    </svg>
  )
}

export default RightArrowIcon
