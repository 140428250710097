import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const LogoutIcon: FC<Props> = ({className, width = '24', height = '24', color = '#616161'}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 1.66669H11.8333C9.16667 1.66669 7.5 3.33335 7.5 6.00002V9.37502H12.7083C13.05 9.37502 13.3333 9.65835 13.3333 10C13.3333 10.3417 13.05 10.625 12.7083 10.625H7.5V14C7.5 16.6667 9.16667 18.3334 11.8333 18.3334H13.9917C16.6583 18.3334 18.325 16.6667 18.325 14V6.00002C18.3333 3.33335 16.6667 1.66669 14 1.66669Z'
        fill={color}
      />
      <path
        d='M3.79995 9.37497L5.52495 7.64997C5.64995 7.52497 5.70828 7.36664 5.70828 7.2083C5.70828 7.04997 5.64995 6.8833 5.52495 6.76664C5.28328 6.52497 4.88328 6.52497 4.64162 6.76664L1.84995 9.55831C1.60828 9.79997 1.60828 10.2 1.84995 10.4416L4.64162 13.2333C4.88328 13.475 5.28328 13.475 5.52495 13.2333C5.76662 12.9916 5.76662 12.5916 5.52495 12.35L3.79995 10.625H7.49995V9.37497H3.79995Z'
        fill={color}
      />
    </svg>
  )
}

export default LogoutIcon
