import {useState, type FC} from 'react'
import classes from './ConfirmationModal.module.scss'
import {Link} from 'react-router-dom'

interface MapingModalProps {
  message: string
  type: string
  handleUserConfirmation?: Function
}

const ConfirmationModal: FC<MapingModalProps> = ({
  message = '',
  type = 'warning',
  handleUserConfirmation = () => {},
}) => {
  const cancelModal = () => {
    handleUserConfirmation('CANCEL')
  }
  const handleForm = (e: React.FormEvent) => {
    e.preventDefault()
    handleUserConfirmation('SUBMIT')
  }

  return (
    <div className={classes.maping}>
      <h1 className={classes.maping__title}>
        <b>{type}</b>
      </h1>

      <form className={classes.maping__form} onSubmit={handleForm}>
        <h5 dangerouslySetInnerHTML={{__html: message}} />

        <div style={{display: 'flex', justifyContent: 'end', gap: '1rem'}}>
          {type === 'WARNING' ? (
            <>
              <div className={classes.maping__modalSubmitOuter}>
                <button type='submit' className={classes.maping__modalSubmitButton}>
                  Proceed
                </button>
              </div>

              <div className={classes.maping__modalSubmitOuter}>
                <Link to='/upgrade-plan' className={classes.maping__modalCloseButton}>
                  Upgrade Limit
                </Link>
              </div>
            </>
          ) : type === 'DEACTIVE' ? (
            <>
              <div className={classes.maping__modalSubmitOuter}>
                <button type='submit' className={classes.maping__modalSubmitButton}>
                  Proceed
                </button>
              </div>

              <div className={classes.maping__modalSubmitOuter}>
                <button
                  onClick={cancelModal}
                  type='button'
                  className={classes.maping__modalCloseButton}
                >
                  Close
                </button>
              </div>
            </>
          ) : (
            <div className={classes.maping__modalSubmitOuter}>
              <button
                onClick={cancelModal}
                type='button'
                className={classes.maping__modalCloseButton}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

export default ConfirmationModal
