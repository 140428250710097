import type {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const ClockIcon: FC<Props> = ({className, width = '24', height = '24', color = '#616161'}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z'
        fill={color}
      />
      <path
        d='M15.7099 15.93C15.5799 15.93 15.4499 15.9 15.3299 15.82L12.2299 13.97C11.4599 13.51 10.8899 12.5 10.8899 11.61V7.51001C10.8899 7.10001 11.2299 6.76001 11.6399 6.76001C12.0499 6.76001 12.3899 7.10001 12.3899 7.51001V11.61C12.3899 11.97 12.6899 12.5 12.9999 12.68L16.0999 14.53C16.4599 14.74 16.5699 15.2 16.3599 15.56C16.2099 15.8 15.9599 15.93 15.7099 15.93Z'
        fill={color}
      />
    </svg>
  )
}

export default ClockIcon
