import {Modal} from 'react-bootstrap'
import classes from '../profile-page/MembersArea.module.scss'

interface ConfirmationModalProps {
  header: string
  message: string
  handleCancel: () => void
  handleConfirm: () => void
  show: boolean
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  header,
  message,
  handleCancel,
  handleConfirm,
  show,
}) => {
  return (
    //@ts-ignore
    <Modal show={show} centered size='md'  onHide={handleCancel}>
      <Modal.Header style={{border: 'none'}} closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer
        style={{
          padding: '1rem',
          borderTop: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <div className={classes.maping__modalSubmitOuter}>
          <button
            type='button'
            className={`btn btn-white border px-4 h-100 py-2`}
            onClick={handleCancel}
          >
            Close
          </button>
        </div>
        <div className={classes.maping__modalSubmitOuter}>
          <button
            type='button'
            onClick={handleConfirm}
            className={`btn btn-primary px-4 h-100 py-2`}
          >
            Submit
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default ConfirmationModal
