import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const CommentIcon: FC<Props> = ({className, width = '32', height = '32', color = '#65676B'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 17 16'
      fill='none'
    >
      <path
        d='M13.8647 1H2.57998C1.7073 1 1 1.70248 1 2.56891V9.7413C1 10.6075 1.7073 11.3104 2.57998 11.3104H4.24543V14L6.74672 11.3104H13.8647C14.7376 11.3104 15.4447 10.6075 15.4447 9.7413V2.56891C15.4444 1.70248 14.7374 1 13.8647 1Z'
        stroke={color}
        strokeWidth='1.3'
      />
    </svg>
  )
}

export default CommentIcon
