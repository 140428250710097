import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const OneLineIcon: FC<Props> = ({className, width = '24', height = '24', color = '#282828'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M7.75 3L7.75 21C7.75 21.41 7.41 21.75 7 21.75C6.59 21.75 6.25 21.41 6.25 21L6.25 3C6.25 2.59 6.59 2.25 7 2.25C7.41 2.25 7.75 2.59 7.75 3Z'
        fill={color}
      />
      <path
        opacity='0.3'
        d='M12.75 3L12.75 21C12.75 21.41 12.41 21.75 12 21.75C11.59 21.75 11.25 21.41 11.25 21L11.25 3C11.25 2.59 11.59 2.25 12 2.25C12.41 2.25 12.75 2.59 12.75 3Z'
        fill={color}
      />
      <path
        opacity='0.3'
        d='M17.75 3L17.75 21C17.75 21.41 17.41 21.75 17 21.75C16.59 21.75 16.25 21.41 16.25 21L16.25 3C16.25 2.59 16.59 2.25 17 2.25C17.41 2.25 17.75 2.59 17.75 3Z'
        fill={color}
      />
    </svg>
  )
}

export default OneLineIcon
