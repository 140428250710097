import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const PlanetIcon: FC<Props> = ({className, width = '32', height = '32', color = '#707070'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 15 16'
      fill='none'
    >
      <path
        d='M7.5 13.625C10.6066 13.625 13.125 11.1066 13.125 8C13.125 4.8934 10.6066 2.375 7.5 2.375C4.3934 2.375 1.875 4.8934 1.875 8C1.875 11.1066 4.3934 13.625 7.5 13.625Z'
        stroke={color}
        strokeWidth='1.5'
        stroke-miterlimit='10'
      />
      <path
        d='M12.5485 10.4829L9.36769 8.52656C9.31193 8.49227 9.24961 8.47003 9.18474 8.46129L7.84771 8.28106C7.74731 8.26752 7.64523 8.28688 7.55675 8.33623C7.46828 8.38558 7.39817 8.46226 7.35693 8.55479L6.55427 10.3559C6.51694 10.4396 6.505 10.5325 6.51992 10.623C6.53485 10.7134 6.57598 10.7976 6.63823 10.8649L7.73979 12.0564C7.78973 12.1104 7.82625 12.1754 7.84641 12.2461C7.86657 12.3169 7.86979 12.3914 7.85582 12.4636L7.63144 13.6236C8.76588 13.5605 11.3375 12.8441 12.5485 10.4829Z'
        fill={color}
        stroke={color}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.93301 2.5593L9.47991 3.5389C9.53161 3.6315 9.55037 3.7389 9.53314 3.84355C9.51591 3.9482 9.4637 4.04392 9.38504 4.11506L7.80823 5.54108C7.78147 5.56529 7.752 5.58632 7.72041 5.60376L7.00312 5.99987C6.93374 6.03819 6.85578 6.05829 6.77652 6.05829H5.52581C5.43358 6.05829 5.3434 6.0855 5.26656 6.13651C5.18972 6.18752 5.12963 6.26007 5.09383 6.34507L4.6059 7.50337'
        fill={color}
      />
      <path
        d='M3.80572 3.75796L3.28165 4.9972C3.23492 5.10769 3.23234 5.2319 3.27443 5.34424L3.94772 7.14121C3.97529 7.21479 4.02092 7.28027 4.08041 7.33161C4.1399 7.38295 4.21135 7.41851 4.28817 7.43503L5.54383 7.7049C5.61351 7.71988 5.67885 7.75054 5.73489 7.79457C5.79094 7.83859 5.8362 7.89481 5.86725 7.95895L6.09024 8.41962C6.12861 8.49889 6.18855 8.56575 6.26317 8.61254C6.33779 8.65932 6.42408 8.68413 6.51216 8.68413H7.29922'
        fill='#707070'
      />
      <path
        d='M8.93301 2.5593L9.47991 3.5389C9.53161 3.6315 9.55037 3.7389 9.53314 3.84355C9.51591 3.9482 9.4637 4.04392 9.38504 4.11506L7.80823 5.54108C7.78147 5.56529 7.752 5.58632 7.72041 5.60376L7.00312 5.99987C6.93374 6.03819 6.85578 6.05829 6.77652 6.05829H5.52581C5.43358 6.05829 5.3434 6.0855 5.26656 6.13651C5.18972 6.18752 5.12963 6.26007 5.09383 6.34507L4.6059 7.50337L4.28817 7.43503C4.21135 7.41851 4.1399 7.38295 4.08041 7.33161C4.02092 7.28027 3.97529 7.21479 3.94772 7.14121L3.27443 5.34424C3.23234 5.2319 3.23492 5.10769 3.28165 4.9972L3.80572 3.75796C4.43001 3.1074 6.32947 1.95688 8.93301 2.5593Z'
        fill='#707070'
      />
      <path
        d='M4.28817 7.43503C4.21135 7.41851 4.1399 7.38295 4.08041 7.33161C4.02092 7.28027 3.97529 7.21479 3.94772 7.14121L3.27443 5.34424C3.23234 5.2319 3.23492 5.10769 3.28165 4.9972L3.80572 3.75796C4.43001 3.1074 6.32947 1.95688 8.93301 2.5593L9.47991 3.5389C9.53161 3.6315 9.55037 3.7389 9.53314 3.84355C9.51591 3.9482 9.4637 4.04392 9.38504 4.11506L7.80823 5.54108C7.78147 5.56529 7.752 5.58632 7.72041 5.60376L7.00312 5.99987C6.93374 6.03819 6.85578 6.05829 6.77652 6.05829H5.52581C5.43358 6.05829 5.3434 6.0855 5.26656 6.13651C5.18972 6.18752 5.12963 6.26007 5.09383 6.34507L4.6059 7.50337L4.28817 7.43503ZM4.28817 7.43503L5.54383 7.7049C5.61351 7.71988 5.67885 7.75054 5.73489 7.79457C5.79094 7.83859 5.8362 7.89481 5.86725 7.95895L6.09024 8.41962C6.12861 8.49889 6.18855 8.56575 6.26317 8.61254C6.33779 8.65932 6.42408 8.68414 6.51216 8.68414H7.29922'
        stroke={color}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default PlanetIcon
