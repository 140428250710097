import {useState, type FC} from 'react'
import {useAuth} from '../../../app/modules/auth'
import classes from './Referral.module.scss'

import {toast} from 'react-toastify'
import {Tooltip} from 'react-tooltip'
import {useGetMembersStatQuery} from '../../../services/referServiceApi'
import UsersLists from './components/UserLists'
import {useGetAllUsersQuery} from '../../../services/userServiceApi'
import {BsApp, BsCurrencyDollar, BsPeople, BsPerson} from 'react-icons/bs'
import SubscriptionDetailsModal from './components/SubscriptionDetalilsModal'
import {SubscriptionPlansEnum} from '../../../types/types'
import { isSuperAdmin } from '../../../utils/userRoles'

interface StatsProps {}

const Stats: FC<StatsProps> = () => {
  const [page, setPage] = useState(1)
  const [show, setShow] = useState(false)
  const [details, setDetails] = useState<any[]>([])
  const {data: {stats = [], hasMore = false} = {}} = useGetAllUsersQuery({
    page,
  })

  const showDetails = (item: string) => {
    if (item === 'Free') {
      const details = stats.details.find((item: any) => item.subscription_name === 'Free Plan')
      setDetails(details ? [details] : [])
      setShow(true)
    } else if (item === 'Paid') {
      const details = stats.details.filter((item: any) =>
        [
          SubscriptionPlansEnum.PREMIUM_MONTHLY,
          SubscriptionPlansEnum.PREMIUM_YEARLY,
          SubscriptionPlansEnum.STARTER_MONTHLY,
          SubscriptionPlansEnum.STARTER_YEARLY,
        ].includes(item.subscription_name)
      )
      setDetails(details)
      setShow(true)
    } else if (item === 'AppSumo') {
      const details = stats.details.filter((item: any) =>
        [
          SubscriptionPlansEnum.APPSUMOTIER1,
          SubscriptionPlansEnum.APPSUMOTIER2,
          SubscriptionPlansEnum.APPSUMOTIER3,
        ].includes(item.subscription_name)
      )
      setDetails(details)
      setShow(true)
    } else if (item === 'Total') {
      const details = stats.details.filter((item: any) => item.subscription_name)
      setDetails(details)
      setShow(true)
    }
  }

  const template = [
    {
      id: 'free',
      name: 'Free',
      content: stats?.summary?.free,
      tooltip: 'Total number of free registered users',
      icon: <BsPerson size={32} />,
    },
    {
      id: 'paid',
      name: 'Paid',
      content: stats?.summary?.paid,
      tooltip: 'Total number of users with paid subscription',
      icon: <BsCurrencyDollar size={32} />,
    },
    {
      id: 'appsumo',
      name: 'AppSumo',
      content: stats?.summary?.appsumo,
      tooltip: 'Total number of paid users from appsumo',
      icon: <BsApp size={32} />,
    },
    {
      id: 'total',
      name: 'Total',
      content: stats?.summary?.total_users,
      tooltip: 'Total number of registered users',
      icon: <BsPeople size={32} />,
    },
  ]

  const blocks = template.map((item, idx) => {
    const {id, name, content, tooltip, icon} = item
    return (
      <div
        className='col-md-6 col-lg-3 mb-lg-0 mb-4'
        key={id}
        style={{cursor: 'pointer'}}
        onClick={() => {
          showDetails(item.name)
        }}
      >
        <div className={`${classes.quickAccess__row__item}`}>
          <>
            <Tooltip anchorSelect={`.${name}`} place='top'>
              {tooltip}
            </Tooltip>
            <div className='info-container d-flex align-items-center gap-3'>
              <div
                className={classes.quickAccess__row__item__icon_box}
                style={{
                  background:
                    idx === 0
                      ? '#9dc8ee'
                      : idx === 1
                      ? '#8fd7bd'
                      : idx === 2
                      ? '#e4ed96'
                      : '#ffaf95',
                }}
              >
                {icon}
              </div>
              <div>
                <span className={classes.quickAccess__row__item__description}>{name}</span>
                <h2 className={`${name} `}>{content}</h2>
              </div>
            </div>
          </>
        </div>
      </div>
    )
  })

  return (
    <div className={classes.quickAccess}>
      <div className='container-fluid'>
        <div className='row'>
          <h3 className={`${classes.quickAccess__title} mb-4`}>Users Stats</h3>
        </div>
        <div className={`row`}>{blocks}</div>
        <SubscriptionDetailsModal show={show} setShow={setShow} details={details} />
      </div>
    </div>
  )
}

const Users = () => {
  const {currentUser} = useAuth()
  const {data: {purchsaedUsers = 0, totalUsers = 0, points = 0} = {}} = useGetMembersStatQuery(
    currentUser?.id
  )
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '3rem',
      }}
      className={classes.referral}
    >
      {
        isSuperAdmin(currentUser?.email) && <Stats />
      }

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <UsersLists />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Users
