import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const ShareIcon: FC<Props> = ({className, width = '32', height = '32', color = '#707070'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 26 26'
      fill='none'
    >
      <path
        d='M17.875 15.4375L22.75 10.5625L17.875 5.6875'
        stroke={color}
        strokeWidth='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M3.25 20.3125C3.25 17.7266 4.27723 15.2467 6.10571 13.4182C7.93419 11.5897 10.4141 10.5625 13 10.5625H22.75'
        stroke={color}
        strokeWidth='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default ShareIcon
