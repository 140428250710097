const loginFormData = [
  {
    id: 1,
    title: 'Email',
    inputType: 'text',
    name: 'email',
    placeholder: 'Email',
    autocomlete: 'off',
    required: true,
  },

  {
    id: 2,
    title: 'Password',
    inputType: 'password',
    name: 'password',
    placeholder: 'Password',
    autocomlete: 'off',
    required: true,
  },
]

export default loginFormData
