import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const ImportIcon: FC<Props> = ({className, width = '24', height = '24', color = '#282828'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      className={className}
    >
      <path
        d='M10.8334 9.7917C10.675 9.7917 10.5167 9.73337 10.3917 9.60837C10.15 9.3667 10.15 8.9667 10.3917 8.72503L17.225 1.8917C17.4667 1.65003 17.8667 1.65003 18.1084 1.8917C18.35 2.13337 18.35 2.53337 18.1084 2.77503L11.275 9.60837C11.15 9.73337 10.9917 9.7917 10.8334 9.7917Z'
        fill={color}
      />
      <path
        d='M18.3335 6.2915C17.9918 6.2915 17.7085 6.00817 17.7085 5.6665V2.2915H14.3335C13.9918 2.2915 13.7085 2.00817 13.7085 1.6665C13.7085 1.32484 13.9918 1.0415 14.3335 1.0415H18.3335C18.6752 1.0415 18.9585 1.32484 18.9585 1.6665V5.6665C18.9585 6.00817 18.6752 6.2915 18.3335 6.2915Z'
        fill={color}
      />
      <path
        d='M12.4998 18.9582H7.49984C2.97484 18.9582 1.0415 17.0248 1.0415 12.4998V7.49984C1.0415 2.97484 2.97484 1.0415 7.49984 1.0415H9.1665C9.50817 1.0415 9.7915 1.32484 9.7915 1.6665C9.7915 2.00817 9.50817 2.2915 9.1665 2.2915H7.49984C3.65817 2.2915 2.2915 3.65817 2.2915 7.49984V12.4998C2.2915 16.3415 3.65817 17.7082 7.49984 17.7082H12.4998C16.3415 17.7082 17.7082 16.3415 17.7082 12.4998V10.8332C17.7082 10.4915 17.9915 10.2082 18.3332 10.2082C18.6748 10.2082 18.9582 10.4915 18.9582 10.8332V12.4998C18.9582 17.0248 17.0248 18.9582 12.4998 18.9582Z'
        fill={color}
      />
    </svg>
  )
}

export default ImportIcon
