import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const LeftArrowIcon: FC<Props> = ({className, width = '32', height = '32', color = '#FFF'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 28 28'
      fill='none'
    >
      <path
        d='M11.1649 21.9568C10.9433 21.9568 10.7216 21.8751 10.5466 21.7001L3.46493 14.6185C3.1266 14.2801 3.1266 13.7201 3.46493 13.3818L10.5466 6.30014C10.8849 5.9618 11.4449 5.9618 11.7833 6.30014C12.1216 6.63847 12.1216 7.19847 11.7833 7.5368L5.31993 14.0001L11.7833 20.4635C12.1216 20.8018 12.1216 21.3618 11.7833 21.7001C11.6199 21.8751 11.3866 21.9568 11.1649 21.9568Z'
        fill={color}
      />
      <path
        d='M23.9167 14.875H4.28174C3.8034 14.875 3.40674 14.4783 3.40674 14C3.40674 13.5217 3.8034 13.125 4.28174 13.125H23.9167C24.3951 13.125 24.7917 13.5217 24.7917 14C24.7917 14.4783 24.3951 14.875 23.9167 14.875Z'
        fill={color}
      />
    </svg>
  )
}

export default LeftArrowIcon
