const inputsProfileData = [
  {
    id: 1,
    title: 'First name',
    name: 'first_name',
    type: 'text',
    placeholder: 'Enter your first name',
    disabled: false,
  },

  {
    id: 2,
    title: 'Last name',
    name: 'last_name',
    type: 'text',
    placeholder: 'Enter your last name',
    disabled: false,
  },

  {
    id: 3,
    title: 'Company name',
    name: 'company_name',
    type: 'text',
    placeholder: 'Enter your company name',
    disabled: true,
  },

  {
    id: 4,
    title: 'Email',
    name: 'email',
    type: 'email',
    placeholder: 'Enter your email',
    disabled: true,
  },

  {
    id: 5,
    title: 'Password',
    name: 'password',
    type: 'password',
    placeholder: '********',
    disabled: false,
  },
]

export default inputsProfileData
