import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const UserIcon: FC<Props> = ({className, width = '24', height = '24', color = '#616161'}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.0001 1.66666C7.81675 1.66666 6.04175 3.44166 6.04175 5.62499C6.04175 7.76666 7.71675 9.49999 9.90008 9.57499C9.96675 9.56666 10.0334 9.56666 10.0834 9.57499C10.1001 9.57499 10.1084 9.57499 10.1251 9.57499C10.1334 9.57499 10.1334 9.57499 10.1417 9.57499C12.2751 9.49999 13.9501 7.76666 13.9584 5.62499C13.9584 3.44166 12.1834 1.66666 10.0001 1.66666Z'
        fill={color}
      />
      <path
        d='M14.2333 11.7917C11.9083 10.2417 8.11663 10.2417 5.77497 11.7917C4.71663 12.5 4.1333 13.4583 4.1333 14.4833C4.1333 15.5083 4.71663 16.4583 5.76663 17.1583C6.9333 17.9417 8.46663 18.3333 9.99997 18.3333C11.5333 18.3333 13.0666 17.9417 14.2333 17.1583C15.2833 16.45 15.8666 15.5 15.8666 14.4667C15.8583 13.4417 15.2833 12.4917 14.2333 11.7917Z'
        fill={color}
      />
    </svg>
  )
}

export default UserIcon
