enum TypeEnum {
  AUTO = 'AUTO',
  GENERAL = 'GENERAL',
  REALISTIC = 'REALISTIC',
  DESIGN = 'DESIGN',
  RENDER_3D = 'RENDER_3D',
  ANIME = 'ANIME',
}

export const typeOptions: string[] = Object.values(TypeEnum) as string[]

export enum StyleEnum {
  STENCIL_ART = 'Stencil Art',
  PENCIL_DRAWING = 'Pencil Drawing',
  CHARCOAL_SKETCH = 'Charcoal Sketch',
  POLITICAL_CARTOON = 'Political Cartoon',
  ACRYLIC_ON_CANVAS = 'Acrylic on Canvas',
  WATERCOLOR = 'Watercolor',
  OIL_PAINTING = 'Oil Painting',
  ANIME = 'Anime',
  DIGITAL_PAINTING = 'Digital Painting',
  AIRBRUSH = 'Airbrush',
  BLUEPRINT = 'Blueprint',
}

// Convert the enum values to an array
export const styleOptions: string[] = Object.values(StyleEnum);

export enum AestheticEnum {
  STEAMPUNK = 'Steampunk',
  MAGICAL = 'Magical',
  CYBERPUNK = 'Cyberpunk',
  VINTAGE = 'Vintage',
  KAWAII = 'Kawaii',
  FANTASY = 'Fantasy',
  CYBERNETIC = 'Cybernetic',
  GOTHIC = 'Gothic',
  VAPORWAVE = 'Vaporwave',
}

export const aestheticOptions: string[] = Object.values(AestheticEnum);


export enum MoodLightingEnum {
  LIGHT = 'Light',
  CALM = 'Calm',
  ELEGANT = 'Elegant',
  SERENE = 'Serene',
  DARK = 'Dark',
  STORMY = 'Stormy',
  GOLDEN_HOUR = 'Golden Hour',
  DRAMATIC_BACKLIGHTING = 'Dramatic Backlighting',
  COLORFUL_LIGHTING = 'Colorful Lighting',
  CINEMATIC_LIGHTING = 'Cinematic Lighting',
}

export const moodLightingOptions: string[] = Object.values(MoodLightingEnum);
