import {useEffect, useState, type FC} from 'react'
import {
  Button,
  Col,
  Container,
  Dropdown,
  Figure,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
  Image,
} from 'react-bootstrap'
import {BsBoxSeam, BsPeople, BsThreeDotsVertical} from 'react-icons/bs'
import {MdOutlineAdminPanelSettings} from 'react-icons/md'
import {
  useAllocateUserLimitMutation,
  useChangeUserStatusMutation,
  useGetCompanyByIdQuery,
  useGetCompanyMutation,
  useUpdateCompanyAdminMutation,
} from '../../../../../../services/companyServiceApi'
import classes from '../../../MembersArea.module.scss'

import {toast} from 'react-toastify'

const UserDetailsModal = ({
  show,
  setShow,
  details,
}: {
  show: boolean
  setShow: (show: boolean) => void
  details: any
}) => {
  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          style={{
            border: 'none',
          }}
        >
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            {details &&
              details.map((item: any) => (
                <>
                  <img
                    src='/new-design/image/avatar.png'
                    alt='Profile Picture'
                    className='rounded-circle mb-2'
                    style={{width: '80px', height: '80px', objectFit: 'cover'}}
                  />
                  <table className='table table-bordered'>
                    <tbody>
                      <tr>
                        <td className='d-flex align-items-center'>User Name</td>
                        <td>{item.name}</td>
                      </tr>
                      <tr>
                        <td className='d-flex align-items-center'>E-mail</td>
                        <td>{item.email}</td>
                      </tr>
                      <tr>
                        <td className='d-flex align-items-center'>Company Name</td>
                        <td>{item.company_name}</td>
                      </tr>
                      <tr>
                        <td className='d-flex align-items-center'>User Role</td>
                        <td>{item.role}</td>
                      </tr>
                      <tr>
                        <td className='d-flex align-items-center'>Last Payment Date</td>
                        <td>{item.last_payment_date ? item.last_payment_date : 'N/A'}</td>
                      </tr>
                      {/* 
                      <tr>
                        <td className='d-flex align-items-center'>
                          <BsPeople size={16} /> Subscription Status
                        </td>
                        <td>{item.subscription_status ? 'Active' : 'Inactive'}</td>
                      </tr> */}

                      <tr>
                        <td className='d-flex align-items-center'>Is AppSumo</td>
                        <td>{item.is_appsumo ? 'Yes' : 'No'}</td>
                      </tr>
                      <tr>
                        <td className='d-flex align-items-center'>Is Survey Completed</td>
                        <td>{item.is_survey_completed ? 'Yes' : 'No'}</td>
                      </tr>
                      <tr>
                        <td className='d-flex align-items-center'>E-mail Verified At</td>
                        <td>{item.email_verified_at ? item.email_verified_at : 'N/A'}</td>
                      </tr>
                      <tr>
                        <td className='d-flex align-items-center'>Created At</td>
                        <td>{item.created_at}</td>
                      </tr>
                      <tr>
                        <td className='d-flex align-items-center'>Updated At</td>
                        <td>{item.updated_at}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ))}
          </Modal.Body>
          <Modal.Footer
            style={{
              padding: '1rem',
              borderTop: 'none',
            }}
          >
            <div className={classes.maping__modalSubmitOuter}>
              <button
                type='button'
                className={classes.maping__modalCloseButton}
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default UserDetailsModal
