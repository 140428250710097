import {useState, type FC, useEffect} from 'react'
import classes from './ImageGenerated.module.scss'
import ReactLoading from 'react-loading'

interface ImageGeneratedProps {
  image: string
  isLoadingImg: boolean
  onClick?: Function
}

const ImageGenerated: FC<ImageGeneratedProps> = ({image, isLoadingImg, onClick = () => {}}) => {
  const [isDownloadedImg, setIsDownloadedImg] = useState(false)

  const handleDownloadImg = (isLoaded = true) => {
    return setIsDownloadedImg(isLoaded)
  }

  useEffect(() => {
    handleDownloadImg(false)
  }, [image])

  return (
    <div className={classes.generated}>
      {(isLoadingImg || !isDownloadedImg) && (
        <div className={classes.generated__imgLoaderBlock}>
          <ReactLoading type='spinningBubbles' color='#2468ff' height='6rem' width='6rem' />
        </div>
      )}
      <img
        src={image}
        /*src={`data:image/png;base64,${image}`} */
        alt='dalle'
        onLoad={() => handleDownloadImg()}
        onClick={() => onClick()}
      />
    </div>
  )
}

export default ImageGenerated
