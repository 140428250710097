import type {FC} from 'react'
import templatesData from '../../data/hr/hrTemplatesData'
import CombinedWorkflow from '../universal-components/combined-workflow/CombinedWorkflow'

interface HRPageProps {}

const HRPage: FC<HRPageProps> = () => {
  const title = 'HR'
  const buttonColor = 'var(--hr-workflow-btn-color)'

  return <CombinedWorkflow title={title} buttonColor={buttonColor} itemsData={templatesData} />
}

export default HRPage
