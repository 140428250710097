import {useRef, useState, type FC} from 'react'
import InviteColleague from '../../homepage/InviteColleague'
import UniversalHiddenScreen from '../../universal-components/hidden-screen/UniversalHiddenScreen'
import classes from '../ReferralMembersArea.module.scss'

import userClasses from '../Users.module.scss'

import moment from 'moment'
import {Dropdown, Form, InputGroup, Table} from 'react-bootstrap'
import {
  useGetAllUsersQuery,
  useUpdateUserRoleMutation,
  useUpdateUserStatusMutation,
} from '../../../../services/userServiceApi'
import {
  BsBuilding,
  BsCardList,
  BsEnvelopeAt,
  BsFillPlusCircleFill,
  BsFunnel,
  BsPencil,
  BsPersonFillGear,
  BsPersonGear,
  BsPersonLock,
  BsPersonPlus,
  BsPersonVcard,
  BsThreeDotsVertical,
  BsTicketDetailed,
} from 'react-icons/bs'
import axios from 'axios'
import {toast} from 'react-toastify'
import ExportIcon from '../../icons/workflow/ExportIcon'
import UserDetailsModal from '../white-label/resellers/components/UserDetailsModal'
import {FilterTypeEnum, GlobalErrorMessagesEnum, SelectTypeEnum} from '../../../../types/types'
import AddUserModal from './AddUserModal'
import {RoleType} from '../../../../types/types'
import {isSuperAdmin, isWhiteLabelSuperAdmin} from '../../../../utils/userRoles'
import {useAuth} from '../../../../app/modules/auth'
import { lowerCase, startCase } from 'lodash'

interface ReferralMembersAreaProps {}

const UsersLists: FC<ReferralMembersAreaProps> = () => {
  const [visibleBackground, setVisibleBackground] = useState(false)
  const [details, setDetails] = useState<any[]>([])
  const [show, setShow] = useState(false)
  const [filterType, setFilterType] = useState('')
  const [selectType, setSelectType] = useState('')
  const [keywords, setKeywords] = useState('')
  const [triggerSearch, setTriggerSearch] = useState<boolean>(false)
  const [showAddUser, setShowAddUser] = useState(false)
  const [userId, setUserId] = useState(null)

  const [page, setPage] = useState(1)
  const handleVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const {data: {users = [], hasMore = false} = {}, refetch} = useGetAllUsersQuery({
    page,
    filterType,
    selectType,
    keywords,
    triggerSearch,
  })

  const [updateUserRole, {isLoading: isLoadingUserRole}] = useUpdateUserRoleMutation()
  const [updateUserStatus, {isLoading: isLoadingUserStatus}] = useUpdateUserStatusMutation()

  const {currentUser} = useAuth()

  const exportUsers = async () => {
    try {
      const API_URL = process.env.REACT_APP_API_URL
      const endpoint = `${API_URL}/user/export-users/csv`

      const response = await axios.get(endpoint, {
        responseType: 'blob',
        params: {
          filterType: filterType,
          selectType: selectType,
          keywords: keywords,
        },
      })

      if (response.status !== 200) {
        toast.error('Failed to download the file')
      }

      const csvUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')

      const fileName = `Users-${Date.now()}.csv`
      link.href = csvUrl
      link.setAttribute('download', fileName)

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(csvUrl)
    } catch (err) {
      console.log(err)
      return {err}
    }
  }

  const showUserDetails = (item: string) => {
    const details = users.find((user: any) => user.email === item)
    if (details.length === 0) return
    setDetails(details ? [details] : [])
    setShow(true)
  }

  const handleMakeReseller = (publicId: string, role: RoleType) => {
    updateUserRole({
      public_id: publicId,
      role,
    })
      .unwrap()
      .then((res) => {
        toast.success('User role updated successfully')
      })
      .catch((e) => {
        toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
      })
      .finally(() => {
        refetch()
      })
  }

  const handleUserStatus = (publicId: string, status: boolean) => {
    updateUserStatus({
      public_id: publicId,
      status,
    })
      .then((res) => {
        toast.success('User status updated successfully')
      })
      .catch((e) => {
        toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
      })
      .finally(() => {
        refetch()
      })
  }

  const tableRows = users.map((item: any = {}) => {
    const {id, name, company_name, email, role, is_appsumo, is_survey_completed, created_at} = item

    console.log(item)

    return (
      <tr key={id}>
        <td className={'align-middle'}>
          <p className=''>{name}</p>
        </td>
        <td className={'align-middle'}>{email}</td>
        <td className={'align-middle'}>{company_name}</td>
        <td className={'align-middle'}>{startCase(lowerCase(role))}</td>
        {
          isSuperAdmin(currentUser?.email) && <td className={'align-middle'}>{is_appsumo ? 'Yes' : 'No'}</td>
        }
        <td className={'align-middle'}>{is_survey_completed ? 'Yes' : 'No'}</td>
        <td className={'align-middle'}>
          <p>{moment(created_at).format('MM/DD/YYYY')}</p>
        </td>
        <td style={{display: 'flex', justifyContent: 'center'}}>
          <Dropdown>
            <Dropdown.Toggle
              className={classes.dropdownToggle}
              variant='primary'
              id='dropdown-basic'
            >
              <BsThreeDotsVertical />
            </Dropdown.Toggle>
            <Dropdown.Menu className={classes.dropdownMenu}>
              <>
                <Dropdown.Item
                  className={classes.dropdownMenuButtons}
                  onClick={() => showUserDetails(item.email)}
                >
                  <BsTicketDetailed /> View Account
                </Dropdown.Item>

                <Dropdown.Item
                  className={classes.dropdownMenuButtons}
                  onClick={() => handleUserManagement(item.id)}
                >
                  <BsPencil /> Edit Account
                </Dropdown.Item>

                {/* This is only for the super admin */}
                {isSuperAdmin(currentUser?.email) && (
                  <Dropdown.Item
                    className={classes.dropdownMenuButtons}
                    onClick={() =>
                      handleMakeReseller(item.public_id, RoleType.WHITE_LABEL_SUPER_ADMIN)
                    }
                    disabled={item.role === RoleType.WHITE_LABEL_SUPER_ADMIN}
                  >
                    <BsPersonGear /> Make Reseller Account
                  </Dropdown.Item>
                )}

                {/* This is only available for users with role 'White Label Super Admin aka Reseller' & Super Admin */}
                {(isSuperAdmin(currentUser?.email) ||
                  isWhiteLabelSuperAdmin(currentUser)) && (
                  <Dropdown.Item
                    className={classes.dropdownMenuButtons}
                    onClick={() => handleUserStatus(item.public_id, !item.status)}
                  >
                    <BsPersonLock /> {item.status ? 'Disable' : 'Enable'} Account
                  </Dropdown.Item>
                )}

                {/* <Dropdown.Item
                  className={classes.dropdownMenuButtons}
                  onClick={() => handleStatusChange(item)}
                >
                  <FaPowerOff /> {subscription_status === 'active' ? 'Deactivate' : 'Activate'}
                </Dropdown.Item> */}
                {/* <Dropdown.Item
                  className={classes.dropdownMenuButtons}
                  onClick={() => setSelectedUser(item)}
                >
                  <MdDataUsage /> Change Limit
                </Dropdown.Item> */}
                {/* <Dropdown.Item
                  className={classes.dropdownMenuButtons}
                  onClick={() => handleMakeAdmin(item)}
                >
                  <MdOutlineAdminPanelSettings /> Make Admin
                </Dropdown.Item> */}
              </>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    )
  })

  const handlePagination = (action: string) => {
    if (action === 'next') {
      setPage((prev) => prev + 1)
    } else {
      setPage((prev) => prev - 1)
    }
  }

  const handleUserManagement = (userId = null) => {
    setShowAddUser(true)
    setUserId(userId)
  }

  const myRef = useRef<HTMLDivElement | null>(null)
  return (
    <div className={classes.referral_members} ref={myRef}>
      <UniversalHiddenScreen
        visible={visibleBackground}
        handleVisibility={handleVisibility}
        contentBlock={<InviteColleague handleVisibility={handleVisibility} />}
      />

      <UserDetailsModal show={show} setShow={setShow} details={details} />
      <AddUserModal show={showAddUser} setShow={setShowAddUser} userId={userId} refetch={refetch} />

      <div className='w-100'>
        <div className='row'>
          <div className='col-lg-4 mb-lg-0 mb-4 text-lg-start text-center'>
            <h3>Users</h3>
            <p>List of registered users</p>
          </div>
          <div className='col-lg-8'>
            <div
              className={userClasses.members__topBlock__btnsBlock}
              style={{flexDirection: 'row-reverse'}}
            >
              <div>
                <button className={userClasses.export__btn} onClick={() => handleUserManagement()}>
                  <BsPersonPlus size={24} />Add User
                </button>
              </div>

              <div>
                <button className={userClasses.export__btn} onClick={() => exportUsers()}>
                  <ExportIcon color='#000' /> Export CSV
                </button>
              </div>

             {
              isSuperAdmin(currentUser?.email) && (
                <Dropdown>
                <Dropdown.Toggle variant='success' id='dropdown-basic'>
                  <BsFunnel size={24} />{' '}
                  {filterType === ''
                    ? 'Filter'
                    : filterType
                        .toLowerCase()
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType(FilterTypeEnum.FREE)
                    }}
                  >
                    Free Users
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType(FilterTypeEnum.APPSUMO)
                    }}
                  >
                    AppSumo Users
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType(FilterTypeEnum.PAID)
                    }}
                  >
                    Paid Users
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType(FilterTypeEnum.TOTAL)
                    }}
                  >
                    Total Users
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              )
             }

              <InputGroup
                style={{
                  width: 'unset',
                  border: '1px solid #282828',
                  background: 'white',
                  color: '#282828',
                  borderRadius: '4px',
                }}
              >
                <Form.Control
                  placeholder='Enter your keywords'
                  aria-label='Enter your keywords'
                  aria-describedby='search-input'
                  onChange={(e) => {
                    setKeywords(e.target.value)
                  }}
                />
                {/* <Button onClick={() => { setTriggerSearch(Date.now()) }} variant="outline-secondary" id="search-input" style={{ border: 'none',borderLeft: '1px solid #282828',    background: 'white',
    color: '#282828' }}>
        <BsSearch size={24} />
        </Button> */}
              </InputGroup>

              <Dropdown>
                <Dropdown.Toggle variant='success' id='dropdown-basic'>
                  {selectType === SelectTypeEnum.NAME ? (
                    <BsPersonVcard size={24} />
                  ) : selectType === SelectTypeEnum.EMAIL ? (
                    <BsEnvelopeAt size={24} />
                  ) : selectType === SelectTypeEnum.COMPANY ? (
                    <BsBuilding size={24} />
                  ) : (
                    <BsCardList size={24} />
                  )}

                  {selectType === ''
                    ? 'Select'
                    : selectType
                        .toLowerCase()
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectType(SelectTypeEnum.NAME)
                    }}
                  >
                    Name
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectType(SelectTypeEnum.EMAIL)
                    }}
                  >
                    E-mail
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectType(SelectTypeEnum.COMPANY)
                    }}
                  >
                    Company
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <div className='w-100'>
        <Table
          responsive
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0',
            borderBottom: '1px solid #fff',
          }}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>E-mail</th>
              <th>Company Name</th>
              <th>Role</th>
             

            {
              isSuperAdmin(currentUser?.email) && (
                <th>AppSumo</th>
              )
            }

              <th>Survey</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
        <tfoot
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '22px',
            width: '100%',
          }}
          className='mt-3 mt-md-0'
        >
          <tr>
            <td colSpan={7}>
              <nav className='w-100 bg-light  d-flex justify-content-center p-3'>
                <ul className='pagination pagination-lg'>
                  <li className='page-item' onClick={() => handlePagination('prev')}>
                    <button className='page-link' disabled={page === 1}>
                      <span aria-hidden='true'>&laquo;</span>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button className='page-link'>{page}</button>
                  </li>
                  <li className='page-item' onClick={() => handlePagination('next')}>
                    <button className='page-link' disabled={!hasMore}>
                      <span aria-hidden='true'>&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>
      </div>
    </div>
  )
}

export default UsersLists
