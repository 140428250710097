import {useState, type FC} from 'react'
import classes from './FacebookPreview.module.scss'
import {useAuth} from '../../../../app/modules/auth'
import SeparatorIcon from '../../icons/linkedin/SeparatorIcon'
import PlanetIcon from '../../icons/linkedin/PlanetIcon'
import SelectArrowIcon from '../../icons/modalform/SelectArrowIcon'
import ThumbsUpIcon from '../../icons/facebook/ThumbsUpIcon'
import HeartIcon from '../../icons/facebook/HeartIcon'
import LikeIcon from '../../icons/facebook/LikeIcon'
import CommentIcon from '../../icons/facebook/CommentIcon'
import ShareIcon from '../../icons/facebook/ShareIcon'

interface FacebookPreviewProps {
  images?: string[]
  text?: string
}

const FacebookPreview: FC<FacebookPreviewProps> = ({images = [], text = ''}) => {
  const {currentUser} = useAuth()
  const [sliderPosition, setSliderPosition] = useState(0)
  const shortedText = text.length <= 250 ? text : text.split(' ').slice(0, 100).join(' ') + ' [...]'

  const handleSlider = (toLeft = true) => {
    const direction = toLeft ? -100 : 100
    setSliderPosition((prev) => prev + direction)
  }

  const imagesBlock = images.map((item, index) => (
    <div key={index} className={classes.facebook__slider__central__stripe__imgBlock}>
      <img src={item} alt='generated images' />
    </div>
  ))

  return (
    <div className={classes.facebook}>
      <div className={classes.facebook__top}>
        <div className={classes.facebook__top__leftBlock}>
          <div className={classes.facebook__top__leftBlock__imgBlock}>
            <img src={`${currentUser?.avatar || '/new-design/image/avatar.png'}`} alt='user' />
          </div>
          <div className={classes.facebook__top__leftBlock__info}>
            <h3 className={classes.facebook__top__leftBlock__info__title}>
              {currentUser?.first_name} {currentUser?.last_name}
            </h3>
            <span className={classes.facebook__top__leftBlock__info__subtitle}>
              4h{' '}
              <SeparatorIcon
                className={classes.facebook__top__leftBlock__info__subtitle__separatorSvg}
              />{' '}
              <PlanetIcon className={classes.facebook__top__leftBlock__info__subtitle__svg} />
            </span>
          </div>
        </div>
      </div>

      <div className={classes.facebook__text}>
        <p>{shortedText}</p>
      </div>

      <div className={classes.facebook__slider}>
        <div className={classes.facebook__slider__central}>
          <button
            className={classes.facebook__slider__central__leftBtn}
            onClick={() => handleSlider(false)}
            disabled={sliderPosition >= 0}
          >
            <SelectArrowIcon className={classes.facebook__slider__leftBtn__svg} />
          </button>
          <button
            className={classes.facebook__slider__central__rightBtn}
            onClick={() => handleSlider()}
            disabled={sliderPosition <= -100 * (images.length - 1)}
          >
            <SelectArrowIcon className={classes.facebook__slider__rightBtn__svg} />
          </button>
          <div
            className={classes.facebook__slider__central__stripe}
            style={{left: `${sliderPosition}%`, width: 100 * images.length + '%'}}
          >
            {imagesBlock}
          </div>
        </div>
        <div className={classes.facebook__slider__bottom}>
          <div className={classes.facebook__slider__bottom__row}>
            <div className={classes.facebook__slider__bottom__row__leftBlock}>
              <div className={classes.facebook__slider__bottom__row__leftBlock__images}>
                <ThumbsUpIcon
                  className={classes.facebook__slider__bottom__row__leftBlock__images__svg}
                />
                <HeartIcon
                  className={classes.facebook__slider__bottom__row__leftBlock__images__svg}
                />
              </div>
              <p>62</p>
            </div>
            <div className={classes.facebook__slider__bottom__row__rightBlock}>
              <p>12 comments</p>
              <p>2 shares</p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.facebook__bottom}>
        <span className={classes.facebook__bottom__item}>
          <LikeIcon className={classes.facebook__bottom__item__svg} /> Like
        </span>
        <span className={classes.facebook__bottom__item}>
          <CommentIcon className={classes.facebook__bottom__item__svg} /> Comment
        </span>
        <span className={classes.facebook__bottom__item}>
          <ShareIcon className={classes.facebook__bottom__item__svg} /> Share
        </span>
      </div>
    </div>
  )
}

export default FacebookPreview
