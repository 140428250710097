import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const ThumbsUpIcon: FC<Props> = ({className, width = '32', height = '32', color = '#333333'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        d='M9 0.5C6.74566 0.5 4.58365 1.39553 2.98959 2.98959C1.39553 4.58365 0.5 6.74566 0.5 9C0.5 11.2543 1.39553 13.4163 2.98959 15.0104C4.58365 16.6045 6.74566 17.5 9 17.5C11.2543 17.5 13.4163 16.6045 15.0104 15.0104C16.6045 13.4163 17.5 11.2543 17.5 9C17.5 6.74566 16.6045 4.58365 15.0104 2.98959C13.4163 1.39553 11.2543 0.5 9 0.5Z'
        fill='url(#paint0_linear_1366_3731)'
        stroke='white'
      />
      <path
        d='M13.162 8.338C13.338 8.461 13.5 8.583 13.5 9.012C13.5 9.442 13.271 9.616 13.026 9.737C13.1262 9.90028 13.1581 10.0964 13.115 10.283C13.038 10.627 12.723 10.894 12.443 10.973C12.564 11.167 12.602 11.358 12.458 11.593C12.273 11.888 12.112 12 11.4 12H8.5C7.512 12 7 11.454 7 11V8.665C7 7.435 8.467 6.39 8.467 5.535L8.361 4.47C8.356 4.405 8.369 4.246 8.419 4.2C8.499 4.121 8.72 4 9.054 4C9.272 4 9.417 4.041 9.588 4.123C10.169 4.4 10.32 5.101 10.32 5.665C10.32 5.936 9.906 6.748 9.85 7.029C9.85 7.029 10.717 6.837 11.729 6.83C12.79 6.824 13.478 7.02 13.478 7.672C13.478 7.933 13.259 8.195 13.162 8.338ZM4.6 8H5.4C5.55913 8 5.71174 8.06321 5.82426 8.17574C5.93679 8.28826 6 8.44087 6 8.6V12.4C6 12.5591 5.93679 12.7117 5.82426 12.8243C5.71174 12.9368 5.55913 13 5.4 13H4.6C4.44087 13 4.28826 12.9368 4.17574 12.8243C4.06321 12.7117 4 12.5591 4 12.4V8.6C4 8.44087 4.06321 8.28826 4.17574 8.17574C4.28826 8.06321 4.44087 8 4.6 8Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1366_3731'
          x1='9'
          y1='1'
          x2='9'
          y2='17'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#18AFFF' />
          <stop offset='1' stopColor='#0062DF' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ThumbsUpIcon
