import type {FC} from 'react'
import templatesData from '../../data/real-estate/realEastateTemplateData'
import CombinedWorkflow from '../universal-components/combined-workflow/CombinedWorkflow'

interface RealEstatePageProps {}

const RealEstatePage: FC<RealEstatePageProps> = () => {
  const title = 'Real Estate'
  const buttonColor = '#FFAF95'

  return <CombinedWorkflow title={title} buttonColor={buttonColor} itemsData={templatesData} />
}

export default RealEstatePage
