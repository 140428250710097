import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const MenuIcon: FC<Props> = ({className, width = '24', height = '24', color = '#292D32'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      className={className}
    >
      <path
        d='M10.5 12C10.5 11.16 11.175 10.5 12 10.5C12.825 10.5 13.5 11.175 13.5 12C13.5 12.825 12.825 13.5 12 13.5C11.175 13.5 10.5 12.84 10.5 12Z'
        fill={color}
      />
      <path
        d='M10.5 17C10.5 16.16 11.175 15.5 12 15.5C12.825 15.5 13.5 16.175 13.5 17C13.5 17.825 12.825 18.5 12 18.5C11.175 18.5 10.5 17.84 10.5 17Z'
        fill={color}
      />
      <path
        d='M10.5 7C10.5 6.16 11.175 5.5 12 5.5C12.825 5.5 13.5 6.175 13.5 7C13.5 7.825 12.825 8.5 12 8.5C11.175 8.5 10.5 7.84 10.5 7Z'
        fill={color}
      />
    </svg>
  )
}

export default MenuIcon
