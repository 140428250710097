import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const ArrowUpIcon: FC<Props> = ({className, width = '24', height = '24', color = '#616161'}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.0001 1.66666C5.40008 1.66666 1.66675 5.39999 1.66675 9.99999C1.66675 14.6 5.40008 18.3333 10.0001 18.3333C14.6001 18.3333 18.3334 14.6 18.3334 9.99999C18.3334 5.39999 14.6001 1.66666 10.0001 1.66666ZM12.9417 10.025C12.8167 10.15 12.6584 10.2083 12.5001 10.2083C12.3417 10.2083 12.1834 10.15 12.0584 10.025L10.6251 8.59166V12.9167C10.6251 13.2583 10.3417 13.5417 10.0001 13.5417C9.65841 13.5417 9.37508 13.2583 9.37508 12.9167V8.59166L7.94175 10.025C7.70008 10.2667 7.30008 10.2667 7.05841 10.025C6.81675 9.78332 6.81675 9.38332 7.05841 9.14166L9.55841 6.64166C9.80008 6.39999 10.2001 6.39999 10.4417 6.64166L12.9417 9.14166C13.1834 9.38332 13.1834 9.78332 12.9417 10.025Z'
        fill={color}
      />
    </svg>
  )
}

export default ArrowUpIcon
