import ReactLoading from 'react-loading'
import classes from './ChartArea.module.scss'
import ChartContainer from './ChartContainer'
import {useEffect, useRef} from 'react'
import DownloadIcon from '../../icons/images/DownloadIcon'
import html2canvas from 'html2canvas'
import {downloadAsFile} from './utils/downloadAsFile'
import {exportComponentAsJPEG} from 'react-component-export-image'

interface ChartAreaProps {
  reports: any
  isLoading: boolean
  chartType: string
  componentRef: any
}

const ChartArea: React.FC<ChartAreaProps> = ({reports, isLoading, chartType, componentRef}) => {
  return (
    <div className={classes.area} ref={componentRef}>
      {/* <button
        onClick={() => exportComponentAsJPEG(componentRef)}
        style={{
          background: 'rgba(0, 0, 0, 0.4)',
          border: 'none',
          borderRadius: '50%',
          padding: '0.3rem',
          color: 'white',
          cursor: 'pointer',
        }}
      >
        <DownloadIcon />
      </button> */}
      <div className={classes.area__topBlock}>
        <h3 className={classes.area__topBlock__title}>
          {isLoading ? '' : reports.title ? reports.title : 'Monthly Financial Overview'}
        </h3>
      </div>

      {isLoading ? (
        <ReactLoading type='spinningBubbles' color='#2468ff' height='6rem' />
      ) : (
        <ChartContainer reports={reports} chartType={chartType} />
      )}
    </div>
  )
}

export default ChartArea
