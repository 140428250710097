import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const EditIcon: FC<Props> = ({className, width = '24', height = '24', color = '#292D32'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      className={className}
    >
      <path
        d='M10.8334 9.79167C10.675 9.79167 10.5167 9.73334 10.3917 9.60834C10.15 9.36667 10.15 8.96667 10.3917 8.725L17.225 1.89167C17.4667 1.65 17.8667 1.65 18.1084 1.89167C18.35 2.13334 18.35 2.53334 18.1084 2.775L11.275 9.60834C11.15 9.73334 10.9917 9.79167 10.8334 9.79167Z'
        fill={color}
      />
      <path
        d='M18.3334 6.29166C17.9917 6.29166 17.7084 6.00832 17.7084 5.66666V2.29166H14.3334C13.9917 2.29166 13.7084 2.00832 13.7084 1.66666C13.7084 1.32499 13.9917 1.04166 14.3334 1.04166H18.3334C18.675 1.04166 18.9584 1.32499 18.9584 1.66666V5.66666C18.9584 6.00832 18.675 6.29166 18.3334 6.29166Z'
        fill={color}
      />
      <path
        d='M12.5 18.9583H7.49996C2.97496 18.9583 1.04163 17.025 1.04163 12.5V7.49999C1.04163 2.97499 2.97496 1.04166 7.49996 1.04166H9.16663C9.50829 1.04166 9.79163 1.32499 9.79163 1.66666C9.79163 2.00832 9.50829 2.29166 9.16663 2.29166H7.49996C3.65829 2.29166 2.29163 3.65832 2.29163 7.49999V12.5C2.29163 16.3417 3.65829 17.7083 7.49996 17.7083H12.5C16.3416 17.7083 17.7083 16.3417 17.7083 12.5V10.8333C17.7083 10.4917 17.9916 10.2083 18.3333 10.2083C18.675 10.2083 18.9583 10.4917 18.9583 10.8333V12.5C18.9583 17.025 17.025 18.9583 12.5 18.9583Z'
        fill={color}
      />
    </svg>
  )
}

export default EditIcon
