import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const HeartOnHandIcon: FC<Props> = ({
  className,
  width = '32',
  height = '32',
  color = '#333333',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 26 23'
      fill='none'
    >
      <path
        d='M10.9377 22.4379C13.8386 22.4379 16.6206 21.2855 18.6718 19.2343C20.723 17.1831 21.8754 14.4011 21.8754 11.5002C21.8754 8.59934 20.723 5.81729 18.6718 3.76608C16.6206 1.71486 13.8386 0.5625 10.9377 0.5625C8.03684 0.5625 5.25479 1.71486 3.20358 3.76608C1.15236 5.81729 0 8.59934 0 11.5002C0 14.4011 1.15236 17.1831 3.20358 19.2343C5.25479 21.2855 8.03684 22.4379 10.9377 22.4379Z'
        fill='#D8D8D8'
      />
      <path
        d='M10.9377 22.4379C13.8386 22.4379 16.6206 21.2855 18.6718 19.2343C20.723 17.1831 21.8754 14.4011 21.8754 11.5002C21.8754 8.59934 20.723 5.81729 18.6718 3.76608C16.6206 1.71486 13.8386 0.5625 10.9377 0.5625C8.03684 0.5625 5.25479 1.71486 3.20358 3.76608C1.15236 5.81729 0 8.59934 0 11.5002C0 14.4011 1.15236 17.1831 3.20358 19.2343C5.25479 21.2855 8.03684 22.4379 10.9377 22.4379Z'
        fill='#BBA9D1'
      />
      <path
        d='M8.66419 15.5892H8.45637C8.41854 15.5944 8.38015 15.5944 8.34231 15.5892C7.98918 15.4579 6.19071 14.772 4.98444 14.4345C4.91047 14.4135 4.84433 14.3712 4.7943 14.3128C4.74427 14.2545 4.71257 14.1826 4.70318 14.1063C4.65514 13.8331 4.71509 13.5519 4.87037 13.3219C4.93015 13.2404 5.00856 13.1743 5.09907 13.1291C5.18958 13.084 5.28957 13.0612 5.39069 13.0626C5.49382 13.0626 5.59851 13.0782 5.69695 13.1141C5.93602 13.2079 6.15946 13.3391 6.35634 13.5048L6.4454 13.5673L7.00166 13.9579C7.20948 14.1016 7.39229 14.2266 7.52042 14.3251L8.82044 14.8454C8.99701 14.9235 9.72984 15.2563 9.68921 15.4392C9.64702 15.6204 8.86107 15.6048 8.68607 15.6095L8.66419 15.5892Z'
        fill='#FEF2FF'
      />
      <path
        d='M22.6144 20.9845C20.7507 20.9492 18.892 20.7774 17.0534 20.4704H16.9487C15.5587 20.291 14.1814 20.0238 12.8252 19.6704C11.6251 19.3329 10.4408 18.8923 9.29386 18.4704L8.78447 18.286C7.85483 17.9484 6.92973 17.5983 6.00942 17.236L5.70785 17.1172C5.22546 16.9403 4.75473 16.7332 4.29845 16.4969C3.66875 16.1532 3.47656 15.7125 3.75157 15.1906V15.1485C3.83876 14.9872 3.97147 14.8551 4.13317 14.7687C4.29487 14.6823 4.47842 14.6454 4.66096 14.6625H4.73127C4.82346 14.6563 4.91409 14.6563 5.00628 14.6625C6.00473 14.7641 8.89385 15.7907 9.46417 15.9953L11.3283 16.0578H11.372L15.5893 16.1891C15.2612 15.9125 14.433 15.4203 12.4142 14.9344C12.0298 14.8406 11.6798 14.7391 11.597 14.4781C11.5358 14.2775 11.5383 14.0628 11.604 13.8636C11.6698 13.6644 11.7956 13.4904 11.9642 13.3656C12.2298 13.2187 12.5392 13.1515 12.847 13.175C13.407 13.1751 13.9631 13.2686 14.4924 13.4515C14.9315 13.5922 15.3862 13.6859 15.8471 13.7328C16.6002 13.8359 17.3456 13.9906 18.0737 14.2016C20.4253 14.7906 20.88 15.8922 21.1769 16.5594C21.0831 16.2875 21.1769 16.4875 21.2206 16.3844L21.2863 16.2969H21.4456C21.5133 16.3021 21.5812 16.3021 21.6488 16.2969C22.055 16.2969 22.8207 16.261 22.8332 16.261C23.0254 16.261 23.9301 16.0516 23.9301 16.2203C24.0127 17.8255 23.6504 19.4222 22.8832 20.8345C22.8595 20.8748 22.8269 20.9091 22.7879 20.9348C22.7489 20.9605 22.7045 20.977 22.6582 20.9829H22.6144V20.9845Z'
        fill='#EAE2F3'
      />
      <path
        d='M19.0688 14.6099C19.0909 14.6226 19.116 14.6293 19.1415 14.6293C19.167 14.6293 19.1921 14.6226 19.2142 14.6099H19.0688Z'
        fill='#D67676'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.0343 4.5844C7.61798 4.15702 7.04896 3.91248 6.45238 3.90457C5.85579 3.89666 5.28049 4.12602 4.85299 4.54221L4.81705 4.5844C4.38995 5.02217 4.15088 5.60955 4.15088 6.22115C4.15088 6.83275 4.38995 7.42013 4.81705 7.8579L8.28117 11.372L11.7531 7.85321C12.1799 7.41623 12.4189 6.82964 12.4189 6.21881C12.4189 5.60798 12.1799 5.02138 11.7531 4.5844C11.5452 4.36876 11.296 4.19712 11.0205 4.0797C10.7449 3.96228 10.4485 3.90147 10.1489 3.90088C9.84938 3.9003 9.55277 3.95996 9.27673 4.0763C9.0007 4.19265 8.75087 4.36332 8.54212 4.57815L8.28742 4.83909L8.03273 4.5844H8.0343Z'
        fill='#ECAA96'
      />
      <path
        d='M24.8801 16.5753C25.0613 16.7003 25.0691 17.9862 24.6769 19.1175C24.2863 20.2488 22.9894 21.5754 22.78 21.7551C22.5722 21.9348 15.1971 20.9394 13.4674 20.6675C11.7377 20.3957 3.6547 16.6237 3.33125 16.4518C3.00625 16.28 2.97656 15.0487 3.9047 14.794C4.83128 14.5377 8.35791 16.0534 9.84856 16.1737C11.3392 16.2956 13.8127 16.4518 15.5768 16.4518C17.3409 16.4518 13.5393 15.3846 12.9267 15.1706C12.3127 14.9518 11.3095 14.6799 11.2345 14.1674C11.1611 13.6549 11.9173 13.1315 12.4205 12.9987C12.9236 12.8658 13.633 13.133 15.5768 13.5534C17.5206 13.9737 17.8393 13.733 19.4019 14.3362C20.9644 14.9393 21.7019 15.8487 21.8753 16.1737C22.0472 16.5003 24.6988 16.4518 24.8801 16.5753V16.5753Z'
        stroke='#493D57'
      />
      <path
        d='M4.80153 14.7688C4.44684 13.8094 4.97654 13.2 5.6078 13.075C6.2375 12.9516 7.44846 13.7266 8.29066 14.5079C8.9688 15.1344 9.52037 15.4798 11.2579 16.2579'
        stroke='#493D57'
      />
      <path
        d='M8.16395 4.26574C7.69905 3.81577 7.0761 3.56628 6.42912 3.57095C5.78214 3.57563 5.16286 3.8341 4.70451 4.29074C4.24593 4.74775 3.98482 5.36634 3.97722 6.01372C3.96963 6.6611 4.21616 7.28565 4.66389 7.7533L8.43739 11.5002L12.2125 7.74861C12.5541 7.40251 12.786 6.9632 12.8789 6.48581C12.9718 6.00843 12.9216 5.51424 12.7347 5.06526C12.5477 4.61628 12.2324 4.23252 11.8281 3.96213C11.4239 3.69174 10.9488 3.54677 10.4624 3.54541C9.80929 3.54854 9.18428 3.81104 8.7249 4.27667L8.43739 4.54699L8.16395 4.26574V4.26574Z'
        stroke='#77280C'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default HeartOnHandIcon
