import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const GalleryIcon: FC<Props> = ({className, width = '24', height = '24', color = '#292D32'}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z'
        fill={color}
      />
      <path
        d='M9 10.75C7.48 10.75 6.25 9.52 6.25 8C6.25 6.48 7.48 5.25 9 5.25C10.52 5.25 11.75 6.48 11.75 8C11.75 9.52 10.52 10.75 9 10.75ZM9 6.75C8.31 6.75 7.75 7.31 7.75 8C7.75 8.69 8.31 9.25 9 9.25C9.69 9.25 10.25 8.69 10.25 8C10.25 7.31 9.69 6.75 9 6.75Z'
        fill={color}
      />
      <path
        d='M2.67002 19.7001C2.43002 19.7001 2.19002 19.5801 2.05002 19.3701C1.82002 19.0301 1.91002 18.5601 2.26002 18.3301L7.19002 15.0201C8.27002 14.2901 9.76002 14.3801 10.74 15.2101L11.07 15.5001C11.57 15.9301 12.42 15.9301 12.91 15.5001L17.07 11.9301C18.13 11.0201 19.8 11.0201 20.87 11.9301L22.5 13.3301C22.81 13.6001 22.85 14.0701 22.58 14.3901C22.31 14.7001 21.84 14.7401 21.52 14.4701L19.89 13.0701C19.39 12.6401 18.54 12.6401 18.04 13.0701L13.88 16.6401C12.82 17.5501 11.15 17.5501 10.08 16.6401L9.75002 16.3501C9.29002 15.9601 8.53002 15.9201 8.02002 16.2701L3.09002 19.5801C2.96002 19.6601 2.81002 19.7001 2.67002 19.7001Z'
        fill={color}
      />
    </svg>
  )
}

export default GalleryIcon
