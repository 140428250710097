import {toast} from 'react-toastify'
import {GlobalErrorMessagesEnum} from '../types/types'

const handleError = (error: any, setErrors?: (message: string) => void | null) => {
  // Updated type for setErrors
  const message = error.data?.message || GlobalErrorMessagesEnum.SERVER_ERROR

  if (setErrors) {
    setErrors(message)
  }

  switch (error.status) {
    case 400:
      toast.error(message[0])
      break
    case 409:
      toast.error(message)
      break
    default:
      toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
      break
  }
}

export default handleError
