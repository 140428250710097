import React, {useEffect, useState} from 'react'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import './MyEditor.scss'
import {AnswerToEdit} from '../../../../types/types'

type Props = {
  answerToEdit?: AnswerToEdit
  triggerCopy?: string | number
  triggerSave?: string | number
  setCurrentAnswer?: Function
  isReplacePrevAnswers?: boolean
}

const MyEditor: React.FC<Props> = ({
  answerToEdit,
  triggerCopy = '',
  triggerSave = '',
  setCurrentAnswer = () => {},
  isReplacePrevAnswers = false,
}) => {
  const [editorData, setEditorData] = useState<string>('')

  // Handle changes in the CKEditor content
  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData()
    setEditorData(data)
  }

  const handleCopyClick = async () => {
    const divArea = document.createElement('div')
    divArea.innerHTML = editorData.split('\n').join('<br>')
    divArea.style.opacity = '0'

    // Wrap content of each <strong> tag with a <span> tag
    divArea.querySelectorAll('strong').forEach((strongElement) => {
      const spanElement = document.createElement('span')
      const bElement = document.createElement('b')
      bElement.innerHTML = strongElement.innerHTML
      spanElement.appendChild(bElement)
      strongElement.replaceWith(spanElement)
    })
    console.log(divArea)
    document.body.appendChild(divArea)

    if (window.getSelection) {
      let range = document.createRange()
      range.selectNode(divArea)
      window.getSelection()?.removeAllRanges() // clear current selection
      window.getSelection()?.addRange(range) // to select text
      try {
        document.execCommand('copy') //UNSAFE COPYING!! Better to find another solution
      } catch (err) {
        console.error('Unable to copy to clipboard', err)
      }
      window.getSelection()?.removeAllRanges() // to deselect
    }
    document.body.removeChild(divArea)
  }

  const saveEditing = async () => {
    handleCopyClick()
    const text = await navigator.clipboard.readText()
    console.log(text)

    setCurrentAnswer(text)
  }

  useEffect(() => {
    if (answerToEdit?.text !== '' && !isReplacePrevAnswers) {
      return setEditorData((prev) => `${answerToEdit?.text || ''} \n ${prev}`)
    }
    setEditorData(answerToEdit?.text)
  }, [answerToEdit])

  useEffect(() => {
    triggerCopy && handleCopyClick()
  }, [triggerCopy])

  useEffect(() => {
    triggerSave && saveEditing()
  }, [triggerSave])

  return (
    <div className='myEditor'>
      <CKEditor
        editor={ClassicEditor}
        data={editorData.split('\n').join('<br>')}
        onReady={(editor) => {}}
        onChange={handleEditorChange}
        config={{
          toolbar: {
            items: ['bold', 'italic', 'bulletedList', 'numberedList'],
          },
        }}
      />
    </div>
  )
}

export default MyEditor
