import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const SalesIcon: FC<Props> = ({className, width = '24', height = '24', color = '#616161'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      className={className}
    >
      <path
        d='M6.88 18.9028C6.47 18.9028 6.13 18.5628 6.13 18.1528V16.0828C6.13 15.6728 6.47 15.3328 6.88 15.3328C7.29 15.3328 7.63 15.6728 7.63 16.0828V18.1528C7.63 18.5728 7.29 18.9028 6.88 18.9028Z'
        fill={color}
      />
      <path
        d='M12 18.9028C11.59 18.9028 11.25 18.5628 11.25 18.1528V14.0028C11.25 13.5928 11.59 13.2528 12 13.2528C12.41 13.2528 12.75 13.5928 12.75 14.0028V18.1528C12.75 18.5728 12.41 18.9028 12 18.9028Z'
        fill={color}
      />
      <path
        d='M17.12 18.9028C16.71 18.9028 16.37 18.5628 16.37 18.1528V11.9328C16.37 11.5228 16.71 11.1828 17.12 11.1828C17.53 11.1828 17.87 11.5228 17.87 11.9328V18.1528C17.87 18.5728 17.54 18.9028 17.12 18.9028Z'
        fill={color}
      />
      <path
        d='M6.88 13.1828C6.54 13.1828 6.24 12.9528 6.15 12.6128C6.05 12.2128 6.29 11.8028 6.7 11.7028C10.38 10.7828 13.62 8.77281 16.09 5.90281L16.55 5.36281C16.82 5.05281 17.29 5.01281 17.61 5.28281C17.92 5.55281 17.96 6.02281 17.69 6.34281L17.23 6.88281C14.56 10.0028 11.04 12.1728 7.06 13.1628C7 13.1828 6.94 13.1828 6.88 13.1828Z'
        fill={color}
      />
      <path
        d='M17.12 9.52281C16.71 9.52281 16.37 9.18281 16.37 8.77281V6.60281H14.19C13.78 6.60281 13.44 6.26281 13.44 5.85281C13.44 5.44281 13.78 5.10281 14.19 5.10281H17.12C17.53 5.10281 17.87 5.44281 17.87 5.85281V8.78281C17.87 9.19281 17.54 9.52281 17.12 9.52281Z'
        fill={color}
      />
      <path
        d='M15 22.7528H9C3.57 22.7528 1.25 20.4328 1.25 15.0028V9.00281C1.25 3.57281 3.57 1.25281 9 1.25281H15C20.43 1.25281 22.75 3.57281 22.75 9.00281V15.0028C22.75 20.4328 20.43 22.7528 15 22.7528ZM9 2.75281C4.39 2.75281 2.75 4.39281 2.75 9.00281V15.0028C2.75 19.6128 4.39 21.2528 9 21.2528H15C19.61 21.2528 21.25 19.6128 21.25 15.0028V9.00281C21.25 4.39281 19.61 2.75281 15 2.75281H9Z'
        fill={color}
      />
    </svg>
  )
}

export default SalesIcon
