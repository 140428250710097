import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const LampIcon: FC<Props> = ({className, width = '32', height = '32', color = '#333333'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        d='M12.5 23.4375C18.5406 23.4375 23.4375 18.5406 23.4375 12.5C23.4375 6.45939 18.5406 1.5625 12.5 1.5625C6.45939 1.5625 1.5625 6.45939 1.5625 12.5C1.5625 18.5406 6.45939 23.4375 12.5 23.4375Z'
        fill='#F5BB5C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.7813 20.9375H11.2813C11.0575 20.9375 10.8429 20.8486 10.6846 20.6904C10.5264 20.5321 10.4375 20.3175 10.4375 20.0938V18.0156H14.625V20.0938C14.625 20.3175 14.5361 20.5321 14.3779 20.6904C14.2196 20.8486 14.005 20.9375 13.7813 20.9375Z'
        fill='#FFE1B2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4531 18.4219V18.0156C10.4531 17.4844 10.3672 16.9578 10.2031 16.4531C9.98417 16.0109 9.70552 15.6008 9.375 15.2344C8.7994 14.7613 8.33457 14.1677 8.01331 13.4955C7.69204 12.8233 7.52216 12.0888 7.51562 11.3437C7.51563 10.0135 8.04406 8.73778 8.98467 7.79717C9.92528 6.85655 11.201 6.32812 12.5312 6.32812C13.8615 6.32812 15.1372 6.85655 16.0778 7.79717C17.0184 8.73778 17.5469 10.0135 17.5469 11.3437C17.5335 12.1051 17.3547 12.8544 17.0228 13.5397C16.6909 14.225 16.214 14.8299 15.625 15.3125C15.7344 15.2344 15.5 15.4062 15.3437 15.625C15.19 15.8516 15.069 16.0989 14.9844 16.3594C14.8252 16.8648 14.7461 17.392 14.75 17.9219V18.3281'
        fill='#FCF0DE'
      />
      <path
        d='M11.6562 7.46875C10.9275 7.59153 10.2584 7.948 9.75 8.48437C9.21692 9.00212 8.84749 9.66492 8.6875 10.3906'
        stroke='white'
        strokeWidth='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.7813 20.9375H11.2813C11.0575 20.9375 10.8429 20.8486 10.6846 20.6904C10.5264 20.5321 10.4375 20.3175 10.4375 20.0938V18.0156H14.625V20.0938C14.625 20.3175 14.5361 20.5321 14.3779 20.6904C14.2196 20.8486 14.005 20.9375 13.7813 20.9375V20.9375Z'
        stroke='#5D3B01'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.5 2.34375V4.3125M10.4375 18.4219V18.0156C10.4416 17.4857 10.3625 16.9585 10.2031 16.4531C10.1013 16.1912 9.9647 15.9442 9.79688 15.7188C9.62986 15.4739 9.43594 15.2485 9.21875 15.0469C9.01031 14.8593 8.817 14.6555 8.64063 14.4375C7.91715 13.5564 7.51979 12.4526 7.51563 11.3125C7.51563 9.98227 8.04406 8.70653 8.98467 7.76592C9.92528 6.82531 11.201 6.29688 12.5313 6.29688C13.8615 6.29688 15.1372 6.82531 16.0778 7.76592C17.0184 8.70653 17.5469 9.98227 17.5469 11.3125C17.5283 12.4527 17.1265 13.5534 16.4063 14.4375C16.1406 14.7656 15.8438 15.0625 15.5156 15.3281C15.4037 15.4298 15.2992 15.5395 15.2031 15.6563C15.0541 15.8834 14.9383 16.1307 14.8594 16.3906C14.7002 16.896 14.6211 17.4233 14.625 17.9531V18.3594L10.4375 18.4219ZM7.1875 4.11875L8.14063 5.35469L7.1875 4.11875ZM17.8359 4.1125L16.8906 5.35469L17.8359 4.1125Z'
        stroke='#5D3B01'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default LampIcon
