import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const API_URL = process.env.REACT_APP_API_URL

export const balanceServiceApi = createApi({
  reducerPath: 'balanceServiceApi',
  baseQuery: fetchBaseQuery({baseUrl: `${API_URL}/balance`}),
  tagTypes: ['Balance'],
  endpoints: (builder) => ({
    getBalance: builder.query({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ['Balance'],
    }),
  }),
})
export const {useGetBalanceQuery} = balanceServiceApi
