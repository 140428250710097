import type {FC} from 'react'
import classes from './QuickAccessArea.module.scss'
import quickAccessData from '../../data/homepage/quickAccessData'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../app/modules/auth'
import {isBetaUser, isSuperAdmin} from '../../../utils/userRoles'

interface QuickAccessAreaProps {}

const QuickAccessArea: FC<QuickAccessAreaProps> = () => {
  const {currentUser} = useAuth()

  const quickAccessBlock = quickAccessData.map((item, idx) => {
    const {id, name, url, disabled, description} = item
    return (
      <div key={id} className='col-12 col-md-6 col-lg-3 mb-lg-0 mb-4'>
        <div className={`${classes.quickAccess__row__item}  `}>
          <h4 className={`${classes.quickAccess__row__item__title} mb-3`}>
            {isSuperAdmin(currentUser?.email) || isBetaUser(currentUser?.email) ? (
              <>
                {name} {name === 'Finance' && <sup className='universalComingSoon'>Beta</sup>}
              </>
            ) : (
              <>
                {name} {disabled && <sup className='universalComingSoon'>Coming Soon</sup>}
              </>
            )}
          </h4>
          <span className={`${classes.quickAccess__row__item__description}`}>{description}</span>
          <div className={`${classes.quickAccess__row__item__bottomBlock}  mt-3`}>
            {!disabled || isSuperAdmin(currentUser?.email) || isBetaUser(currentUser?.email) ? (
              <Link
                to={url}
                className={`${classes.quickAccess__row__item__bottomBlock__btn}`}
                style={{
                  background:
                    idx === 0
                      ? '#9dc8ee'
                      : idx === 1
                      ? '#8fd7bd'
                      : idx === 2
                      ? '#e4ed96'
                      : '#ffaf95',
                }}
              >
                Explore
              </Link>
            ) : (
              <Link
                to={'#'}
                className={`${classes.quickAccess__row__item__bottomBlock__btn}`}
                style={{
                  background:
                    idx === 0
                      ? '#9dc8ee'
                      : idx === 1
                      ? '#8fd7bd'
                      : idx === 2
                      ? '#e4ed96'
                      : '#ffaf95',
                  cursor: 'default',
                }}
              >
                Explore
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className={classes.quickAccess}>
      <h3 className={classes.quickAccess__title}>Quick Access</h3>
      <div className='container-fluid w-100 p-0'>
        <div className='row gx-5'>{quickAccessBlock}</div>
      </div>
    </div>
  )
}

export default QuickAccessArea
