import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const ReceiptItem: FC<Props> = ({className, width = '32', height = '32', color = '#333333'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      className={className}
    >
      <path
        d='M25.8935 15.666H21.3335C20.7868 15.666 20.3335 15.2127 20.3335 14.666V5.34601C20.3335 4.35935 20.7202 3.43935 21.4135 2.74602C22.1068 2.05268 23.0268 1.66602 24.0135 1.66602H24.0268C25.6935 1.67935 27.2668 2.33268 28.4668 3.51935C29.6668 4.73268 30.3202 6.33268 30.3202 7.99935V11.226C30.3335 13.8793 28.5468 15.666 25.8935 15.666ZM22.3335 13.666H25.8935C27.4402 13.666 28.3335 12.7727 28.3335 11.226V7.99935C28.3335 6.85268 27.8802 5.75935 27.0668 4.93268C26.2535 4.13268 25.1602 3.67935 24.0268 3.66602C24.0268 3.66602 24.0268 3.66602 24.0135 3.66602C23.5735 3.66602 23.1468 3.83935 22.8268 4.15935C22.5068 4.47935 22.3335 4.89268 22.3335 5.34601V13.666Z'
        fill='#282828'
      />
      <path
        d='M11.9998 31.106C11.3732 31.106 10.7865 30.866 10.3465 30.4127L8.13317 28.186C8.01317 28.066 7.8265 28.0527 7.69317 28.1594L5.39984 29.866C4.69317 30.3994 3.75984 30.4927 2.95984 30.0927C2.15984 29.6927 1.6665 28.8927 1.6665 27.9993V7.99935C1.6665 3.97268 3.97317 1.66602 7.99984 1.66602H23.9998C24.5465 1.66602 24.9998 2.11935 24.9998 2.66602C24.9998 3.21268 24.5465 3.66602 23.9998 3.66602C23.0798 3.66602 22.3332 4.41268 22.3332 5.33268V27.9993C22.3332 28.8927 21.8398 29.6927 21.0398 30.0927C20.2398 30.4927 19.3065 30.4127 18.5998 29.8794L16.3198 28.1727C16.1865 28.066 15.9998 28.0927 15.8932 28.1994L13.6532 30.4394C13.2132 30.866 12.6265 31.106 11.9998 31.106ZM7.87984 26.0927C8.49317 26.0927 9.09317 26.3193 9.5465 26.786L11.7598 29.0127C11.8398 29.0927 11.9465 29.106 11.9998 29.106C12.0532 29.106 12.1598 29.0927 12.2398 29.0127L14.4798 26.7727C15.3065 25.946 16.6132 25.866 17.5332 26.5727L19.7998 28.266C19.9465 28.3727 20.0798 28.3327 20.1465 28.2927C20.2132 28.2527 20.3332 28.1727 20.3332 27.9993V5.33268C20.3332 4.73268 20.4798 4.15935 20.7332 3.66602H7.99984C5.03984 3.66602 3.6665 5.03935 3.6665 7.99935V27.9993C3.6665 28.186 3.7865 28.266 3.85317 28.306C3.93317 28.346 4.0665 28.3727 4.19984 28.266L6.47984 26.5593C6.89317 26.2527 7.3865 26.0927 7.87984 26.0927Z'
        fill='#282828'
      />
      <path
        d='M16 18.3477H12C11.4533 18.3477 11 17.8943 11 17.3477C11 16.801 11.4533 16.3477 12 16.3477H16C16.5467 16.3477 17 16.801 17 17.3477C17 17.8943 16.5467 18.3477 16 18.3477Z'
        fill='#282828'
      />
      <path
        d='M16 13.0137H12C11.4533 13.0137 11 12.5603 11 12.0137C11 11.467 11.4533 11.0137 12 11.0137H16C16.5467 11.0137 17 11.467 17 12.0137C17 12.5603 16.5467 13.0137 16 13.0137Z'
        fill='#282828'
      />
      <path
        d='M7.96029 13.3464C7.22695 13.3464 6.62695 12.7464 6.62695 12.013C6.62695 11.2797 7.22695 10.6797 7.96029 10.6797C8.69362 10.6797 9.29362 11.2797 9.29362 12.013C9.29362 12.7464 8.69362 13.3464 7.96029 13.3464Z'
        fill='#282828'
      />
      <path
        d='M7.96029 18.6803C7.22695 18.6803 6.62695 18.0803 6.62695 17.347C6.62695 16.6137 7.22695 16.0137 7.96029 16.0137C8.69362 16.0137 9.29362 16.6137 9.29362 17.347C9.29362 18.0803 8.69362 18.6803 7.96029 18.6803Z'
        fill='#282828'
      />
    </svg>
  )
}

export default ReceiptItem
