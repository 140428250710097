import {useState, type FC} from 'react'
import classes from './LinkedinPreview.module.scss'
import {useAuth} from '../../../../app/modules/auth'
import MenuIcon from '../../icons/navbar/MenuIcon'
import PlanetIcon from '../../icons/linkedin/PlanetIcon'
import ThumbsUpIcon from '../../icons/linkedin/ThumbsUp'
import ApplauseIcon from '../../icons/linkedin/ApplauseIcon'
import HeartOnHandIcon from '../../icons/linkedin/HeartOnHandIcon'
import HeartIcon from '../../icons/linkedin/HeartIcon'
import LampIcon from '../../icons/linkedin/LampIcon'
import FaceIcon from '../../icons/linkedin/FaceIcon'
import SeparatorIcon from '../../icons/linkedin/SeparatorIcon'
import SelectArrowIcon from '../../icons/modalform/SelectArrowIcon'
import LikeIcon from '../../icons/linkedin/LikeIcon'
import CommentIcon from '../../icons/linkedin/CommentIcon'
import ShareIcon from '../../icons/linkedin/ShareIcon'
import SendIcon from '../../icons/linkedin/SendIcon'

interface LinkedinPreviewProps {
  images?: string[]
  text?: string
}

const LinkedinPreview: FC<LinkedinPreviewProps> = ({images = [], text = ''}) => {
  const {currentUser} = useAuth()
  const [sliderPosition, setSliderPosition] = useState(0)
  const shortedText = text.length <= 250 ? text : text.split(' ').slice(0, 100).join(' ') + ' [...]'

  const handleSlider = (toLeft = true) => {
    const direction = toLeft ? -100 : 100
    setSliderPosition((prev) => prev + direction)
  }

  const imagesBlock = images.map((item, index) => (
    <div key={index} className={classes.linkedin__slider__central__stripe__imgBlock}>
      <img src={item} alt='generated images' />
    </div>
  ))

  return (
    <div className={classes.linkedin}>
      <div className={classes.linkedin__top}>
        <div className={classes.linkedin__top__leftBlock}>
          <div className={classes.linkedin__top__leftBlock__imgBlock}>
            <img src={`${currentUser?.avatar || '/new-design/image/avatar.png'}`} alt='user' />
          </div>
          <div className={classes.linkedin__top__leftBlock__info}>
            <h3 className={classes.linkedin__top__leftBlock__info__title}>
              {currentUser?.first_name} {currentUser?.last_name}
            </h3>
            <span className={classes.linkedin__top__leftBlock__info__subtitle}>
              20 h{' '}
              <SeparatorIcon
                className={classes.linkedin__top__leftBlock__info__subtitle__separatorSvg}
              />{' '}
              <PlanetIcon className={classes.linkedin__top__leftBlock__info__subtitle__svg} />
            </span>
          </div>
        </div>
        <div className={classes.linkedin__top__rightBlock}>
          <MenuIcon className={classes.linkedin__top__rightBlock__svg} />
        </div>
      </div>

      <div className={classes.linkedin__text}>
        <p>{shortedText}</p>
        <span className={classes.linkedin__text__hashtags}>#hashtag #hashtag #hashtag</span>
      </div>

      <div className={classes.linkedin__slider}>
        <div className={classes.linkedin__slider__central}>
          <button
            className={classes.linkedin__slider__central__leftBtn}
            onClick={() => handleSlider(false)}
            disabled={sliderPosition >= 0}
          >
            <SelectArrowIcon className={classes.linkedin__slider__leftBtn__svg} />
          </button>
          <button
            className={classes.linkedin__slider__central__rightBtn}
            onClick={() => handleSlider()}
            disabled={sliderPosition <= -100 * (images.length - 1)}
          >
            <SelectArrowIcon className={classes.linkedin__slider__rightBtn__svg} />
          </button>
          <div
            className={classes.linkedin__slider__central__stripe}
            style={{left: `${sliderPosition}%`, width: 100 * images.length + '%'}}
          >
            {imagesBlock}
          </div>
        </div>
        <div className={classes.linkedin__slider__bottom}>
          <div className={classes.linkedin__slider__bottom__row}>
            <ThumbsUpIcon className={classes.linkedin__slider__bottom__row__svg} />
            <ApplauseIcon className={classes.linkedin__slider__bottom__row__svg} />
            <HeartOnHandIcon className={classes.linkedin__slider__bottom__row__svg} />
            <HeartIcon className={classes.linkedin__slider__bottom__row__svg} />
            <LampIcon className={classes.linkedin__slider__bottom__row__svg} />
            <FaceIcon className={classes.linkedin__slider__bottom__row__svg} />
            <span className={classes.linkedin__slider__bottom__row__count}>
              88 <SeparatorIcon className={classes.linkedin__slider__bottom__row__count__svg} /> 4
              comments
            </span>
          </div>
        </div>
      </div>
      <div className={classes.linkedin__bottom}>
        <span className={classes.linkedin__bottom__item}>
          <LikeIcon className={classes.linkedin__bottom__item__svg} /> Like
        </span>
        <span className={classes.linkedin__bottom__item}>
          <CommentIcon className={classes.linkedin__bottom__item__svg} /> Comment
        </span>
        <span className={classes.linkedin__bottom__item}>
          <ShareIcon className={classes.linkedin__bottom__item__svg} /> Share
        </span>
        <span className={classes.linkedin__bottom__item}>
          <SendIcon className={classes.linkedin__bottom__item__svg} /> Send
        </span>
      </div>
    </div>
  )
}

export default LinkedinPreview
