import {FC} from 'react'
import {Modal, Table} from 'react-bootstrap'
import classes from '../../profile-page/MembersArea.module.scss'

interface ProjectDetailsModalProps {
  show: boolean
  setShow: (show: boolean) => void
  project: any | null
}

const ProjectDetailsModal: FC<ProjectDetailsModalProps> = ({show, setShow, project}) => {
  const handleClose = () => setShow(false)

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size='lg'>
        <Modal.Header style={{border: 'none'}}>
          <Modal.Title>
            {project?.name
              ? project.name.length > 30
                ? `${project.name.slice(0, 30)}...`
                : project.name
              : 'No Project Name'}{' '}
            Details
          </Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th style={{width: '10%'}}>#</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {project?.values?.length ? (
                  project.values.map((item: any, index: number) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2} style={{textAlign: 'center'}}>
                      No values found for this project!
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer style={{padding: '1rem', borderTop: 'none'}}>
            <div className={classes.maping__modalSubmitOuter}>
              <button
                type='button'
                className={classes.maping__modalCloseButton}
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default ProjectDetailsModal
