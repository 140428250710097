import {useState, type FC} from 'react'
import {useAuth} from '../../../app/modules/auth'
import InviteColleague from '../homepage/InviteColleague'
import UniversalHiddenScreen from '../universal-components/hidden-screen/UniversalHiddenScreen'
import classes from './Onboard.module.scss'

import axios from 'axios'
import moment from 'moment'
import {toast} from 'react-toastify'
import {useGetSurveyQuery} from '../../../services/onboardSurveyServiceApi'
import ExportIcon from '../icons/workflow/ExportIcon'
import {Table} from 'react-bootstrap'

interface OnboardSurveyProps {}

const OnboardSurvey: FC<OnboardSurveyProps> = () => {
  const {currentUser} = useAuth()

  const [visibleBackground, setVisibleBackground] = useState(false)
  const [page, setPage] = useState(1)

  const {data: {surveys = [], hasMore = false} = {}} = useGetSurveyQuery({
    page,
  })

  const handlePagination = (action: string) => {
    if (action === 'next') {
      setPage((prev) => prev + 1)
    } else {
      setPage((prev) => prev - 1)
    }
  }

  const exportSurveys = async () => {
    try {
      const API_URL = process.env.REACT_APP_API_URL

      const response = await axios.get(`${API_URL}/onboard-survey/export_surveys`, {
        responseType: 'blob',
      })

      if (response.status !== 200) {
        toast.error('Failed to download file')
      }

      const csvUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')

      const fileName = `Survey-${Date.now()}.csv`
      link.href = csvUrl
      link.setAttribute('download', fileName)

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(csvUrl)
    } catch (err) {
      console.log(err)
      return {err}
    }
  }

  const handleVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const tableRows = surveys.map((item: any = {}) => {
    const {
      id,
      name,
      company_name,
      preferred_workplace,
      company_size,
      location = '',
      profession,
      how_did_you_hear_about_us,
      email,
      created_at,
    } = item

    return (
      <tr
        key={id}
        style={{
          borderBottom: '1px solid #fff',
          height: '50px',
        }}
      >
        <td>
          <p className=''>{name}</p>
        </td>
        <td>{email}</td>
        <td>{company_name}</td>
        <td>{preferred_workplace}</td>
        <td>{company_size}</td>
        <td>{profession}</td>
        <td>{how_did_you_hear_about_us}</td>
        <td>
          <p>{moment(created_at).format('MM/DD/YYYY')}</p>
        </td>
      </tr>
    )
  })

  return (
    <div className={classes.members}>
      <UniversalHiddenScreen
        visible={visibleBackground}
        handleVisibility={handleVisibility}
        contentBlock={<InviteColleague handleVisibility={handleVisibility} />}
      />
      <div className={classes.members__topBlock}>
        <div className={classes.members__topBlock__headers}>
          <h2 className={classes.members__topBlock__headers__title}>Onboard Survey</h2>
          <p>Preview Onboard Survey Responses {/* <span className='text-secondary'> </span> */}</p>
        </div>
        <div className={classes.members__topBlock__btnsBlock}>
          <button className={classes.export__btn} onClick={() => exportSurveys()}>
            <ExportIcon /> Export CSV
          </button>
        </div>
      </div>

      <div className='w-100'>
        <Table
          responsive
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0',
          }}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>E-mail</th>
              <th>Company Name</th>
              <th>Preferred Workplace</th>
              <th>Company Size</th>
              <th>Profession</th>
              <th>Source</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
        <tfoot
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '22px',
            width: '100%',
          }}
          className='mt-3 mt-md-0'
        >
          <tr>
            <td colSpan={8}>
              <nav className='w-100 bg-light  d-flex justify-content-center p-3'>
                <ul className='pagination pagination-lg'>
                  <li className='page-item' onClick={() => handlePagination('prev')}>
                    <button className='page-link' disabled={page === 1}>
                      <span aria-hidden='true'>&laquo;</span>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button className='page-link'>{page}</button>
                  </li>
                  <li className='page-item' onClick={() => handlePagination('next')}>
                    <button className='page-link' disabled={!hasMore}>
                      <span aria-hidden='true'>&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>
      </div>
    </div>
  )
}

export default OnboardSurvey
