import {useEffect, useState, type FC} from 'react'
import {InputGroup, Modal} from 'react-bootstrap'
import {BsBoxSeam, BsPeople} from 'react-icons/bs'
import classes from '../MembersArea.module.scss'

const SubscriptionDetailsModal = ({
  show,
  setShow,
  details,
}: {
  show: boolean
  setShow: (show: boolean) => void
  details: any
}) => {
  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          style={{
            border: 'none',
          }}
        >
          <Modal.Title>Users Plan Stats</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            {details &&
              details.map((item: any) => (
                <>
                  <div className='border p-3 mt-3'>
                    <InputGroup className='my-4 mt-2 d-flex justify-content-between align-items-center'>
                      <label htmlFor='words'>
                        <BsBoxSeam size={16} /> Package Name
                      </label>
                      <label htmlFor='words'>{item.subscription_name}</label>
                    </InputGroup>

                    <InputGroup className='my-4 mt-2 d-flex justify-content-between align-items-center'>
                      <label htmlFor='words'>
                        <BsPeople size={16} /> Total Users
                      </label>
                      <label htmlFor='words'>{item.user_count}</label>
                    </InputGroup>
                  </div>
                </>
              ))}
          </Modal.Body>
          <Modal.Footer
            style={{
              padding: '1rem',
              borderTop: 'none',
            }}
          >
            <div className={classes.maping__modalSubmitOuter}>
              <button
                type='button'
                className={classes.maping__modalCloseButton}
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default SubscriptionDetailsModal
