import {useState, type FC} from 'react'
import classes from './ImagesPage.module.scss'
import ImagesForm from './ImagesForm'
import ImagesArea from './ImagesArea'
import {LimitNotification} from '../universal-components/limit-notification/LimitNotification'
import {Button} from 'react-bootstrap'
import {GiHamburgerMenu} from 'react-icons/gi'
import {useAuth} from '../../../app/modules/auth'

interface ImagesPageProps {}

const ImagesPage: FC<ImagesPageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [triggerRegenerate, setTriggerRegenerate] = useState<number | string>('')
  const [message, setMessage] = useState<string>('')
  const [isVisibleMessage, setIsVisibleMessage] = useState(false)
  const [images, setImages] = useState<string[]>([
    '/new-design/image/1.jpg',
    '/new-design/image/2.jpg',
  ])
  const [error, setError] = useState('')
  const {toggleSidebar} = useAuth()
  const handleMessageVisiability = () => {
    setIsVisibleMessage(true)

    setTimeout(() => {
      setIsVisibleMessage(false)
    }, 2000)
  }

  return (
    <div className={`universalPage  container-fluid`}>
      {error.trim() !== '' ? <LimitNotification errorMessage={error} /> : null}
      <div className='row'>
        <div className='col-12 col-md-5'>
          <div className={classes.images__leftBlock}>
            <div className={`universalTopBlock ${classes.images__leftBlock__universalTopBlock}`}>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <h1 className='universalTopTitle'>Image Generation</h1>
                <Button
                  variant='dark'
                  className={'navbar-toggler-btn '}
                  onClick={() => toggleSidebar()}
                >
                  <GiHamburgerMenu />
                </Button>
              </div>
              <div className='universalBreadcrumbs'>
                <span>Home</span>
                <span>/</span>
                <span className='universalBreadcrumbs__lastChild'>Image Generation</span>
              </div>
            </div>
            <ImagesForm
              setImages={setImages}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              triggerRegenerate={triggerRegenerate}
              setMessage={setMessage}
              handleMessageVisiability={handleMessageVisiability}
              setError={setError}
            />
          </div>
        </div>
        <div className='col-12 col-md-7 mt-5 mt-md-0'>
          <ImagesArea
            images={images}
            setTriggerRegenerate={setTriggerRegenerate}
            isLoading={isLoading}
            message={message}
            setMessage={setMessage}
            isVisibleMessage={isVisibleMessage}
            handleMessageVisiability={handleMessageVisiability}
          />
        </div>
      </div>
    </div>
  )
}

export default ImagesPage
