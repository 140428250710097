import type {FC} from 'react'
import classes from './BillingArea.module.scss'
import CurrentPlanBlock from './CurrentPlanBlock'

interface BillingAreaProps {}

const BillingArea: FC<BillingAreaProps> = () => {
  return (
    <div className={classes.billing}>
      <div className={classes.billing__topBlock}>
        <h2 className={classes.billing__topBlock__title}>Billing</h2>
        <p>View or change your Plan</p>
      </div>
      <CurrentPlanBlock />
    </div>
  )
}

export default BillingArea
