const planData = [
  {
    id: 1,
    title: 'Starter',
    subtitle: 'Small business',
    monthlyPay: 0,
    annualPay: 0,
    urlMonth: '',
    urlYear: '',
    usersCount: 1,
    button: 'Get started',
    limits: {
      monthly: {words: '100,000 Words', credits: '1000 Workflow Credits', images: '100 AI Images'},
      yearly: {words: '40000', credits: '100 Workflow Credits', images: '10 AI Images'},
    },
    description: [
      '35+ AI Workflows',
      '15+ Languages',
      'AI Strategy Chat',
      'Base AI Model',
      'Email support',
    ],
  },

  {
    id: 2,
    title: 'Premium',
    subtitle: 'Scaling business',
    monthlyPay: 29,
    annualPay: 279,
    urlMonth: 'https://buy.stripe.com/3cs7sY0O1awSfXq148',
    urlYear: 'https://buy.stripe.com/fZe4gM1S548u3aEfZ0',
    usersCount: 5,
    button: 'Get started',
    limits: {
      monthly: {
        words: '1,000,000 Words',
        credits: '1,0000 Workflow Credits',
        images: '1000 AI Images',
      },
      yearly: {words: '600,000', credits: '12,000 Workflow Credits', images: '600 AI Images'},
    },
    description: [
      '35+ AI Workflows',
      'Bulk Run',
      'Brand Voice',
      'AI Strategy Chat',
      '15+ Languages',
      'Latest AI Model',
      'Email + chat support',
      'Integrations',
      'API Access',
    ],
  },

  {
    id: 3,
    title: 'Enterprise',
    subtitle: 'Large business',
    monthlyPay: 99,
    annualPay: 940,
    urlMonth: 'https://buy.stripe.com/aEUcNi8gt20mbHa003',
    urlYear: 'https://buy.stripe.com/5kAeVq8gt34qbHa3ch',
    usersCount: 10,
    button: 'Get started',
    limits: {
      monthly: {
        words: 'Custom Words',
        credits: 'Custom Workflow Credits',
        images: 'Custom AI Images',
      },
      yearly: {words: '3,000,000', credits: '60,000 Workflow Credits', images: '2400 AI Images'},
    },
    description: [
      '35+ AI Workflows',
      'Bulk Run',
      'Brand Voice',
      'AI strategy chat',
      '15+ Languages',
      'Latest AI Model',
      'Admin and Role Management',
      'Email + chat support',
      'Integrations',
      'API Access',
    ],
  },
]

export default planData
