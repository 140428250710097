import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const FinanceIcon: FC<Props> = ({className, width = '32', height = '32', color = '#282828'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      className={className}
    >
      <path
        d='M17.8666 23.2267H14.52C12.3333 23.2267 10.56 21.3867 10.56 19.12C10.56 18.5733 11.0133 18.12 11.56 18.12C12.1066 18.12 12.56 18.5733 12.56 19.12C12.56 20.28 13.44 21.2267 14.52 21.2267H17.8666C18.7333 21.2267 19.4533 20.4533 19.4533 19.52C19.4533 18.36 19.04 18.1333 18.36 17.8933L12.9866 16C11.9466 15.64 10.5466 14.8667 10.5466 12.48C10.5466 10.4267 12.16 8.77333 14.1333 8.77333H17.48C19.6666 8.77333 21.44 10.6133 21.44 12.88C21.44 13.4267 20.9866 13.88 20.44 13.88C19.8933 13.88 19.44 13.4267 19.44 12.88C19.44 11.72 18.56 10.7733 17.48 10.7733H14.1333C13.2666 10.7733 12.5466 11.5467 12.5466 12.48C12.5466 13.64 12.96 13.8667 13.64 14.1067L19.0133 16C20.0533 16.36 21.4533 17.1333 21.4533 19.52C21.44 21.56 19.84 23.2267 17.8666 23.2267Z'
        fill={color}
      />
      <path
        d='M16 25C15.4533 25 15 24.5467 15 24V8C15 7.45333 15.4533 7 16 7C16.5467 7 17 7.45333 17 8V24C17 24.5467 16.5467 25 16 25Z'
        fill={color}
      />
      <path
        d='M20 30.3333H12C4.75996 30.3333 1.66663 27.24 1.66663 20V12C1.66663 4.76001 4.75996 1.66667 12 1.66667H20C27.24 1.66667 30.3333 4.76001 30.3333 12V20C30.3333 27.24 27.24 30.3333 20 30.3333ZM12 3.66667C5.85329 3.66667 3.66663 5.85334 3.66663 12V20C3.66663 26.1467 5.85329 28.3333 12 28.3333H20C26.1466 28.3333 28.3333 26.1467 28.3333 20V12C28.3333 5.85334 26.1466 3.66667 20 3.66667H12Z'
        fill={color}
      />
    </svg>
  )
}

export default FinanceIcon
