import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const SendIcon: FC<Props> = ({className, width = '28', height = '28', color = '#2468FF'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      className={className}
    >
      <path
        d='M18.83 3.45338L8.29501 6.95338C1.21335 9.32172 1.21335 13.1834 8.29501 15.54L11.4217 16.5784L12.46 19.705C14.8167 26.7867 18.69 26.7867 21.0467 19.705L24.5583 9.18172C26.1217 4.45672 23.555 1.87838 18.83 3.45338ZM19.2033 9.73005L14.77 14.1867C14.595 14.3617 14.3733 14.4434 14.1517 14.4434C13.93 14.4434 13.7083 14.3617 13.5333 14.1867C13.195 13.8484 13.195 13.2884 13.5333 12.9501L17.9667 8.49338C18.305 8.15505 18.865 8.15505 19.2033 8.49338C19.5417 8.83172 19.5417 9.39172 19.2033 9.73005Z'
        fill={color}
      />
    </svg>
  )
}

export default SendIcon
