import {queryParameters} from '../../app/modules/auth/core/_requests'
import {BrowserExtensionEnum} from '../../types/types'

const authorizedBrowserExtension = (user: any, redirectUri: string) => {
  const chromeExtensionPrefix = 'chrome-extension://'
  const path = redirectUri.substring(chromeExtensionPrefix.length)
  const [chromeRuntimeId] = path.split('/')
  const state = queryParameters.get('state')

  console.log(redirectUri)

  console.log(path.split('/'))
  console.log(chromeRuntimeId, state)

  if (!chromeRuntimeId || !state) {
    console.error('Failed to extract runtime ID or state')
    return
  }

  const data = {
    type: BrowserExtensionEnum.BROWSER_EXTENSION__SET_API_CREDENTIALS,
    state: state,
    user: {
      publicId: user.public_id,
      first_name: user.first_name,
      Last_name: user.last_name,
      full_name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      apiKey: user.api_key,
      avatar: user.avatar,
      company_name: user.company_name,
      subscription_name: user.subscription.name,
    },
  }

  if (chrome?.runtime) {
    chrome.runtime.sendMessage(chromeRuntimeId, data, (response) => {
      if (chrome.runtime.lastError) {
        console.error('Message sending failed:', chrome.runtime.lastError)
        return
      }

      if (
        response.type === BrowserExtensionEnum.BROWSER_EXTENSION__DATA_RECEIVED &&
        response.state === state
      ) {
        //window.close()
      } else {
        console.log('Message not sent successfully')
      }
    })
  } else {
    console.error('Chrome runtime API is not available')
  }
}

export default authorizedBrowserExtension
