import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const RunIcon: FC<Props> = ({className, width = '24', height = '24', color = '#BBBBBB'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      className={className}
    >
      <path
        d='M5.30005 7.22006V16.7901C5.30005 18.7501 7.43005 19.9801 9.13005 19.0001L13.28 16.6101L17.43 14.2101C19.13 13.2301 19.13 10.7801 17.43 9.80006L13.28 7.40006L9.13005 5.01006C7.43005 4.03006 5.30005 5.25006 5.30005 7.22006Z'
        fill={color}
      />
    </svg>
  )
}

export default RunIcon
