import {createApi} from '@reduxjs/toolkit/query/react'
import {refreshBaseQuery} from './refreshBaseQuery'

export const authServiceApi = createApi({
  reducerPath: 'authServiceApi',
  // baseQuery: fetchBaseQuery({baseUrl: `${API_URL}/auth`}), // Change the baseUrl to your API endpoint
  baseQuery: refreshBaseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
    }),
    register: builder.mutation({
      query: (body) => ({
        url: 'auth/signup',
        method: 'POST',
        body,
      }),
    }),
    confirmEmail: builder.mutation({
      query: (token) => ({
        url: 'auth/confirm_email',
        method: 'POST',
        body: {token},
      }),
    }),
    firstLogin: builder.mutation({
      query: (id) => ({
        url: `/user/update/first-login/${id}`,
        method: 'PATCH',
      }),
    }),
    serverLogout: builder.query({
      query: (token) => ({
        url: 'auth/logout',
        headers: {Authorization: `Bearer ${token}`},
      }),
    }),
    googleAuthentication: builder.mutation({
      query: (googleResponse) => ({
        url: 'auth/google-authentication',
        method: 'POST',
        body: {
          email: googleResponse.data.email,
          first_name: googleResponse.data.given_name,
          last_name: googleResponse.data.family_name,
          avatar: googleResponse.data.picture,
        },
        headers: {Authorization: `Bearer ${googleResponse.access_token}`},
      }),
    }),
    requestPassword: builder.mutation({
      query: (email) => ({
        url: 'auth/request-password',
        method: 'POST',
        body: {email},
      }),
    }),
    getUserByToken: builder.query({
      query: (token) => ({
        url: 'auth/verify_token',
        headers: {Authorization: `Bearer ${token}`},
      }),
    }),
    refresh: builder.query({
      query: (body) => ({
        url: 'auth/refresh',
        headers: {Authorization: `Bearer ${body.token}`},
      }),
    }),
  }),
})

export const {
  useRegisterMutation,
  useConfirmEmailMutation,
  useLazyServerLogoutQuery,
  useGoogleAuthenticationMutation,
  useRequestPasswordMutation,
  useLazyGetUserByTokenQuery,
  useLazyRefreshQuery,
  useLoginMutation,
  useFirstLoginMutation,
} = authServiceApi
