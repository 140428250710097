import {SubscriptionPlansEnum, SubscriptionWordsLimitEnum} from '../types/types'

export const getWordsLimitByPlanName = (name: string) => {
  switch (name) {
    case SubscriptionPlansEnum.STARTER_MONTHLY:
      return SubscriptionWordsLimitEnum.STARTER
    case SubscriptionPlansEnum.PREMIUM_MONTHLY:
      return SubscriptionWordsLimitEnum.PREMIUM
    case SubscriptionPlansEnum.STARTER_YEARLY:
      return SubscriptionWordsLimitEnum.STARTER
    case SubscriptionPlansEnum.PREMIUM_YEARLY:
      return SubscriptionWordsLimitEnum.PREMIUM
    case SubscriptionPlansEnum.FREE:
      return SubscriptionWordsLimitEnum.FREE
    case SubscriptionPlansEnum.APPSUMOTIER1:
      return SubscriptionWordsLimitEnum.APPSUMOTIER1
    case SubscriptionPlansEnum.APPSUMOTIER2:
      return SubscriptionWordsLimitEnum.APPSUMOTIER2
    case SubscriptionPlansEnum.APPSUMOTIER3:
      return SubscriptionWordsLimitEnum.APPSUMOTIER3
    default:
      return SubscriptionWordsLimitEnum.FREE
  }
}
