import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const InfoIcon: FC<Props> = ({className, width = '12', height = '12', color = '#27272A'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill={color}
      viewBox='0 0 12 12'
      className={className}
    >
      <path
        fill={color}
        d='M5.5 6.5a.5.5 0 011 0V8a.5.5 0 01-1 0V6.5zM6 3.75a.75.75 0 100 1.5.75.75 0 000-1.5zM1 6a5 5 0 1110 0A5 5 0 011 6zm5-4a4 4 0 100 8 4 4 0 000-8z'
      ></path>
    </svg>
  )
}

export default InfoIcon
