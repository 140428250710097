import {FC} from 'react'

type Props = {className?: string; width?: string; height?: string; color?: string}

const HeartIcon: FC<Props> = ({className, width = '32', height = '32', color = '#333333'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      className={className}
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        d='M12.5 23.4375C18.5406 23.4375 23.4375 18.5406 23.4375 12.5C23.4375 6.45939 18.5406 1.5625 12.5 1.5625C6.45939 1.5625 1.5625 6.45939 1.5625 12.5C1.5625 18.5406 6.45939 23.4375 12.5 23.4375Z'
        fill='#DF704D'
      />
      <path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M12.0471 7.81257C11.6668 7.43132 11.2151 7.12883 10.7177 6.92244C10.2203 6.71606 9.68715 6.60982 9.14867 6.60982C8.61018 6.60982 8.07699 6.71606 7.57963 6.92244C7.08226 7.12883 6.63051 7.43132 6.25023 7.81257C5.86519 8.19713 5.55973 8.65382 5.35132 9.15652C5.14292 9.65922 5.03564 10.1981 5.03564 10.7423C5.03564 11.2865 5.14292 11.8253 5.35132 12.328C5.55973 12.8307 5.86519 13.2874 6.25023 13.6719L12.5002 19.9219L18.7502 13.6719C19.1353 13.2874 19.4407 12.8307 19.6491 12.328C19.8575 11.8253 19.9648 11.2865 19.9648 10.7423C19.9648 10.1981 19.8575 9.65922 19.6491 9.15652C19.4407 8.65382 19.1353 8.19713 18.7502 7.81257C18.3725 7.43009 17.9223 7.12671 17.426 6.92013C16.9297 6.71356 16.3972 6.60794 15.8596 6.60945C15.3218 6.60619 14.7888 6.711 14.2922 6.91768C13.7956 7.12435 13.3457 7.42867 12.969 7.81257L12.5002 8.28132L12.0471 7.81257Z'
        fill='#FFF3F0'
        stroke='#77280C'
      />
      <path
        fillRule='evenodd'
        clip-rule='evenodd'
        d='M12.0471 7.8125C11.6689 7.42747 11.2179 7.12164 10.7202 6.91287C10.2226 6.7041 9.68831 6.59657 9.14865 6.59657C8.60898 6.59657 8.07472 6.7041 7.57707 6.91287C7.07942 7.12164 6.62835 7.42747 6.25021 7.8125C5.49622 8.58579 5.07422 9.62309 5.07422 10.7031C5.07422 11.7832 5.49622 12.8205 6.25021 13.5937L12.5002 19.8437L18.7502 13.5937C19.5042 12.8205 19.9262 11.7832 19.9262 10.7031C19.9262 9.62309 19.5042 8.58579 18.7502 7.8125C18.3732 7.4279 17.9235 7.12208 17.4273 6.91286C16.931 6.70363 16.3981 6.59516 15.8596 6.59375C15.3208 6.59341 14.7873 6.70108 14.2909 6.91041C13.7944 7.11975 13.3449 7.42648 12.969 7.8125L12.5002 8.20313L12.0471 7.8125Z'
        stroke='#77280C'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default HeartIcon
