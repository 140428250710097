import ImageGenerationForm from './image-generation-form/ImageGenerationForm'
import ImagesArea from './image-area/ImagesArea'
import {Modal} from 'react-bootstrap'
import {useCallback, useState} from 'react'
import ImagePreview from './image-area/ImagePreview'
import {ImageModel} from './models/ImageModel'

const ImagesPage = () => {
  const [openImagePreviewModal, setOpenImagePreviewModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState<ImageModel>()
  const [isGeneratedAt, setIsGeneratedAt] = useState(0)
  const [isExtendedVisible, setIsExtendedVisible] = useState(false)

  const handleOnclickImage = useCallback((image: ImageModel) => {
    setSelectedImage(image);
    setOpenImagePreviewModal(true);
  }, []); 
  
  return (
    <div className='universalPage container-fluid'>
      <div className='universalTopBlock pb-5' onClick={() => isExtendedVisible && setIsExtendedVisible(false)}>
        <h1 className='universalTopTitle'>Image Generation</h1>
        <div className='universalBreadcrumbs'>
          <span>Home</span>
          <span>/</span>
          <span className='universalBreadcrumbs__lastChild'>Image Generation</span>
        </div>
      </div>
      
      <ImageGenerationForm setIsGeneratedAt={setIsGeneratedAt} isExtendedVisible={isExtendedVisible} setIsExtendedVisible={setIsExtendedVisible}/>
      
      <div className='mt-4 w-100'>
        <ImagesArea handleOnclickImage={handleOnclickImage} isGeneratedAt={isGeneratedAt} />
      </div>
      
      <Modal size='xl' show={openImagePreviewModal} onHide={() => setOpenImagePreviewModal(false)}>
        <Modal.Header closeButton className='border-bottom-0'>
          <Modal.Title>Image Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>{selectedImage && <ImagePreview image={selectedImage} />}</Modal.Body>
      </Modal>
    </div>
  )
}

export default ImagesPage
