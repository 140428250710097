import React, {FC, useState} from 'react'
import {useAuth} from '../../../app/modules/auth'
import {sendInvitationByEmail, getInvitationLink} from '../../../app/modules/auth/core/_requests'
import classes from './InviteColleague.module.scss'
import GoogleIcon from '../icons/inviteform/GoogleIcon'
import LinkIcon from '../icons/inviteform/LinkIcon'
import {toast} from 'react-toastify'
import {BsXLg} from 'react-icons/bs'
import copy from 'copy-to-clipboard'
import * as yup from 'yup'

type InviteColleagueProps = {
  handleVisibility?: () => void
}

const InviteColleague: React.FC<InviteColleagueProps> = ({handleVisibility}) => {
  const [email, setEmail] = useState('')
  const [emailStatus, setEmailStatus] = useState({
    status: '',
    message: '',
  })
  const [popStatus, setPopStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const companyName = useAuth().currentUser?.company_name

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setEmail(value)
    validateEmail(value) // Validate on input change
  }

  const validateEmail = async (email: string) => {
    const emailSchema = yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please enter a valid email address')

    try {
      await emailSchema.validate(email)
      setEmailStatus({status: 'success', message: ''})
      return true
    } catch (error: any) {
      setEmailStatus({status: 'error', message: error.message})
      return false
    }
  }

  const handleEmailBlur = async () => {
    await validateEmail(email) // Validate when input loses focus
  }

  const sendEmail = async () => {
    const isValidEmail = await validateEmail(email)
    if (!isValidEmail) return // Return early if validation fails

    setIsLoading(true)
    await sendInvitationByEmail(email, companyName)
      .then(() => {
        toast.success('Link successfully sent to email')
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          toast.warning(error.response.data.message)
        } else {
          toast.warning('Something went wrong, please contact customer support')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getLink = async () => {
    if (!companyName) {
      return
    }
    await getInvitationLink(companyName).then((res: any) => {
      copy(res.data, {debug: false, message: 'Press #{key} to copy'})
      toast.success('Link successfully copied to clipboard', {toastId: 'getInvitationLink'})
    })
    setPopStatus(true)
    setTimeout(() => {
      setPopStatus(false)
    }, 2500)
  }

  return (
    <div className={classes.invite}>
      <div onClick={handleVisibility} className='invite-x-icon'>
        <BsXLg />
      </div>
      <h1 className={classes.invite__title}>Invite Colleague</h1>

      <h3 className={classes.invite__subtitle}>Invite a user to your organization</h3>
      <p>Where do you want to send an invite?</p>
      <label className={classes.invite__label}>
        <h4
          className={`${classes.invite__label__message} ${
            emailStatus.status === 'error'
              ? classes.invite__label__messageError
              : emailStatus.status === 'success'
              ? classes.invite__label__messageSuccess
              : ''
          }`}
        >
          {emailStatus.message}
        </h4>
        <input
          type='email'
          placeholder="User's email"
          value={email}
          onChange={handleEmail}
          onBlur={handleEmailBlur}
        />
      </label>
      <button
        className={classes.invite__btn}
        onClick={sendEmail}
        disabled={isLoading || emailStatus.status === 'error'}
      >
        {isLoading ? (
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        ) : (
          <>
            <GoogleIcon className={classes.invite__btn__svg} />
            Invite by Email
          </>
        )}
      </button>
      <div className={classes.invite__separator}>
        <span>or</span>
      </div>
      <button className={classes.invite__btn} onClick={getLink}>
        <LinkIcon className={classes.invite__btn__svg} />
        Invite by Link
      </button>
      {/* <span
        className={`${classes.invite__confirmedCopy} ${
          popStatus ? classes.invite__confirmedCopyVisible : undefined
        }`}
      >
        Copied to clipboard
      </span> */}
    </div>
  )
}

export default InviteColleague
