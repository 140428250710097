import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {refreshBaseQuery} from './refreshBaseQuery'
const API_URL = process.env.REACT_APP_API_URL

export const brandVoiceServiceApi = createApi({
  reducerPath: 'brandVoiceServiceApi',
  baseQuery: refreshBaseQuery,
  tagTypes: ['BrandVoice'],
  endpoints: (builder) => ({
    createBrandVoice: builder.mutation({
      query: (data) => ({
        url: `${API_URL}/brand-voice`,
        method: 'POST',
        body: data,
      }),
    }),
    getBrandVoice: builder.query({
      query: ({page, keywords}) => ({
        url: `${API_URL}/brand-voice?page=${page}&&keywords=${keywords}`,
        method: 'GET',
      }),
      providesTags: ['BrandVoice'],
    }),
    changeStatus: builder.mutation({
      query: ({id, status}) => ({
        url: `${API_URL}/brand-voice/${id}`,
        method: 'PATCH',
        body: {status},
      }),
      invalidatesTags: ['BrandVoice'],
    }),
    deleteBrandVoice: builder.mutation({
      query: ({id}) => ({
        url: `${API_URL}/brand-voice/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useCreateBrandVoiceMutation,
  useGetBrandVoiceQuery,
  useChangeStatusMutation,
  useDeleteBrandVoiceMutation,
} = brandVoiceServiceApi
