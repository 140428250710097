import {type FC} from 'react'
import {ImageModel} from '../models/ImageModel'
import classes from './ImagePreview.module.scss'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

interface ImagePreviewProps {
  image: ImageModel
}

const ImagePreview: FC<ImagePreviewProps> = ({image}) => {
  return (
    <div className='d-flex gap-3 flex-column flex-md-row'>
      <div className='flex-grow-1 d-flex justify-content-center' >
        {/* <img src={image.url} className={`rounded-4 ${classes.image}`} /> */}

        <LazyLoadImage
          alt={''}
          effect='blur'
          className={`rounded-4 ${classes.image}`}
          wrapperProps={{
            style: {transitionDelay: '0.5s'},
          }}
          src={image.original_url}
          style={{ maxHeight:'800px' }}
        />

      </div>
      <div className={`p-3 rounded-4 ${classes.imageProperties}`}>
        <p className={`mb-4 ${classes.title}`}>Your Prompt</p>
        <div className={classes.valueWrapper}>
          <p>{image.user_prompt ? image.user_prompt : 'None'}</p>
        </div>
        <p className={`mb-4 mt-5 ${classes.title}`}>Magic Prompt</p>
        <div className={classes.valueWrapper}>
          <p>{image.magic_prompt ? image.magic_prompt : 'None'}</p>
        </div>
        <p className={`mb-4 mt-5 ${classes.title}`}>Image Properties</p>
        <p className='mt-4 mb-3'>Style</p>
        <div className={classes.valueWrapper}>
          <p>{image?.type || 'None'}</p>
        </div>
        <p className='mt-4 mb-3'>Resolution</p>
        <div className={classes.valueWrapper}>
          <p>{image?.resolution || 'None'}</p>
        </div>

        <p className='mt-4 mb-3'>Aesthetic</p>
        <div className={classes.valueWrapper}>
          <p>{image?.aesthetic || 'None'}</p>
        </div>

        {/* <p className='mt-4 mb-3'>Style</p>
        <div className={classes.valueWrapper}>
          <p>{image?.style || 'None'}</p>
        </div> */}
      </div>
    </div>
  )
}

export default ImagePreview
