import {createApi} from '@reduxjs/toolkit/query/react'
import {refreshBaseQuery} from './refreshBaseQuery'

const API_URL = process.env.REACT_APP_API_URL

export const onboardServiceApi = createApi({
  reducerPath: 'onboardServiceApi',
  baseQuery: refreshBaseQuery,
  tagTypes: ['Surveys'],
  endpoints: (builder) => ({
    getSurvey: builder.query({
      query: ({page}) => ({
        url: `${API_URL}/onboard-survey?page=${page}`,
        method: 'GET',
      }),
      providesTags: ['Surveys'],
    }),
  }),
})

export const {useGetSurveyQuery} = onboardServiceApi
